import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Card, Button, Modal, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GridFilters from "./GridFilters";
import GridProperty from "./gridProperty";
import BreadCrumb from "Common/BreadCrumb";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getRealEstateGridList as onGetRealEstateGridList, addRealEstateGridList as onAddRealEstateGridList } from "slices/realestate/thunk";
import { ToastContainer } from "react-toastify";
import { getProperties } from '../../../slices/thunk'
import { createSelector } from "reselect";
import InformationProperties from "../AddProperty/InformationProperties";

const ListingGrid = () => {
    document.title = "Listing Grid";

    const selectRealEstateGridList = createSelector(
        (state: any) => state.RealEstateRelibuild,
        (realEstate) => ({
            properties: realEstate.properties
        })
    );

    const { properties } = useSelector(selectRealEstateGridList);

    const dispatch = useDispatch<any>();
    const [listGrid, setListGrid] = useState<any>([]);
    const [showfilter, setShowfilter] = useState<boolean>(true);

    const [addProperty, setAddProperty] = useState<boolean>(false);
    const handlefileter = () => setShowfilter(!showfilter);
    const [selectfeils, setSelectfeils] = useState<any>([]);

    //Add Property modal
    const handleShowProperty = () => setAddProperty(true);
    const handleCloseProperty = () => setAddProperty(false);

    useEffect(() => {
        dispatch(getProperties())
    }, [dispatch])

    useEffect(() => {
        setListGrid(properties)
    }, [properties]);
    

    const handleAcceptfiles = (files: any) => {
        const newImages = files?.map((file: any) => {
            return Object.assign(file, {
                priview: URL.createObjectURL(file),
            })
        })
        setSelectfeils([...selectfeils, ...newImages]);
    }

    const formik: any = useFormik({
        initialValues: {
            title: '',
            type: '',
            bedroom: '',
            bathroom: '',
            area: '',
            price: '',
            location: '',
            // addressline: '',
            rating: '',
            img: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Your  Title"),
            type: Yup.string().required("Please Enter Your  Type"),
            bedroom: Yup.string().required("Please Enter Your bedroom List"),
            bathroom: Yup.string().required("Please Enter Your bathroom List"),
            area: Yup.string().required("Please Enter Your area List"),
            price: Yup.number().required("Please Enter Your Price"),
            location: Yup.string().required("Please Enter Your location"),
            // addressline: Yup.string().required("Please Enter Your Address"),
            rating: Yup.string().required("Please Enter Your rat"),
            img: Yup.mixed().required("Please Enter Your Image"),
        }),
        onSubmit: (values: any) => {
            const newListGrid = {
                id: (Math.floor(Math.random() * (30 - 20)) + 20),
                title: values["title"],
                img: values["img"],
                type: values["type"],
                bedroom: values["bedroom"],
                bathroom: values["bathroom"],
                area: values["area"],
                price: values["price"],
                location: values["location"],
                // addressline: values["addressline"],
                rating: values["rating"]
            }
            dispatch(onAddRealEstateGridList(newListGrid))
            formik.resetForm();
            if (values === null) {
                handleShowProperty();
            } else {
                handleCloseProperty();
            }
        }
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Listing Grid" pageTitle="Real Estate" />
                    <Row>
                        <GridFilters showfilter={showfilter} setListGrid={setListGrid} handlefileter={handlefileter} />
                        <div className="col-lg">
                            <Card>
                                <Card.Body>
                                    <Row className="align-items-center gy-3">
                                        <Col lg={3}>
                                            <Card.Title as="h6" className="mb-0">Property{" "}
                                                <Badge bg="secondary-subtle" text="secondary" className="align-baseline ms-1">241</Badge>
                                            </Card.Title>
                                        </Col>
                                        <div className="col-sm-auto ms-auto d-flex gap-1">
                                            <Button variant="secondary" onClick={handleShowProperty}><i className="bi bi-house align-baseline me-1"></i> Add Property</Button>
                                            <Button variant="primary" className="myButton" onClick={handlefileter}><i className="bi bi-funnel align-baseline me-1"></i> Filter</Button>
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <GridProperty data={listGrid} />
                        </div>
                    </Row>
                </Container>
            </div>

            <Modal show={addProperty} onHide={handleCloseProperty} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as="h5" id="addPropertyModalLabel">Add Property list</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                      <InformationProperties editData={null} isEdit={false }/>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="hstack gap-2 justify-content-end">
                            <Button className="btn btn-ghost-danger" onClick={handleCloseProperty}> <i className="bi bi-x-lg align-baseline me-1"></i> Close </Button>
                        </div>
                    </Modal.Footer>
            </Modal >
            <ToastContainer />
        </React.Fragment>
    );
};

export default ListingGrid;