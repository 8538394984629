import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner, Alert } from "react-bootstrap";
import Dropzone from "react-dropzone";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createProperty, getPropertyOwners } from "../../../slices/thunk";

interface InformationPropertiesProps {
  editData: any;
  isEdit: Boolean;
}
const InformationProperties: React.FC<InformationPropertiesProps> = ({
  editData,
  isEdit,
}) => {
  const dispatch: any = useDispatch();
  const [selectFiles, setSelectFiles] = useState<any>(
    isEdit ? JSON.parse(editData.rep_images) : []
  );
  const [selectVideos, setSelectVideos] = useState<any>(
    isEdit ? JSON.parse(editData.rep_video) : []
  );
  const [loading, setLoading] = useState<any>(false);
  const [propertyOwner, setPropertyOwner] = useState<any>([]);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
  const handleAcceptFiles = (files: any) => {
    const newImages = files?.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    setSelectFiles([...selectFiles, ...newImages]);
  };

  const handleAcceptVideos = (files: any) => {
    const newVideos = files?.map((file: any) => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    setSelectVideos([...selectVideos, ...newVideos]);
  };

  useEffect(() => {
    dispatch(getPropertyOwners()).then((resp: any) => {
      if (resp.result.length > 0) {
        setPropertyOwner(resp.result);
      }
    });
  }, [dispatch]);

  const trimmedString = isEdit && editData.rep_additional_features.slice(1, -1); // Remove outer quotes
  const initialAdditionalFeatures = JSON.parse(trimmedString);

  const initialValues = {
    propertyTitle: isEdit ? editData.rep_title : "",
    propertyType: isEdit ? editData.rep_type : "",
    propertyDescription: isEdit ? editData.rep_description : "",
    bedroom: isEdit ? editData.rep_bedroom : "",
    bathroom: isEdit ? editData.rep_bathroom : "",
    sqft: isEdit ? editData.rep_sqft : "",
    price: isEdit ? editData.rep_price : "",
    streetAddress: isEdit ? editData.rep_street_address : "",
    state: isEdit ? editData.rep_state : "",
    country: isEdit ? editData.rep_country : "",
    zipcode: isEdit ? editData.rep_zip : "",
    additionalFeatures: [],
    propertyOwnerId: "",
    saleOrRent: isEdit ? editData.rep_sale_type : "",
    isActive: isEdit ? editData.rep_is_active : false,
    yearBuilt: isEdit ? editData.rep_year_built : "",
    hasGarage: isEdit ? editData.rep_is_garage : false,
    garageSize: isEdit ? editData.rep_garage_size : "",
  };

  const validationSchema = Yup.object().shape({
    propertyTitle: Yup.string().required("Property title is required"),
    propertyType: Yup.string().required("Property type is required"),
    propertyDescription: Yup.string().required(
      "Property Description is required"
    ),
    bedroom: Yup.number().required("Number of bedrooms is required"),
    bathroom: Yup.number().required("Number of bathrooms is required"),
    sqft: Yup.number().required("SQFT is required"),
    streetAddress: Yup.string().required("Street address is required"),
    price: Yup.string().required("Property Price or Monthly Rent is Required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipcode: Yup.string().required("Zipcode is required"),
    saleOrRent: Yup.string().required("Please select sale or rent"),
    additionalFeatures: Yup.array(Yup.string()), // Adjust validation as per actual values
    isActive: Yup.boolean().required("Property activation status is required"),
    propertyOwnerId: Yup.string().required(
      "Property activation status is required"
    ),
  });

  const handleSubmit = (values: any, { resetForm }: any) => {
    setLoading(true);
    const formData = {
      ...values,
      propId: isEdit ? editData.rep_id : "",
      images: selectFiles,
      videos: selectVideos,
    };
    dispatch(createProperty(formData)).finally(() => {
      setSelectFiles([]);
      setLoading(false);
      resetForm();
      setNotification({
        status: true,
        message: isEdit
          ? "Property Updated Successfully"
          : "Property Created Successfully",
        variant: "success",
      });
    });
  };
  const handleAlertClick = () => {
    setNotification(null);
  };
  return (
    <Col lg={12}>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Card>
          <Card.Header>
            <h5 className="card-title mb-0">Information Properties</h5>
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <FormikForm>
                  {/* Dropzone for images */}
                  <div className="mb-3">
                    <label className="form-label">
                      Property Images<span className="text-danger">*</span>
                    </label>
                    <Dropzone
                      onDrop={(acceptedFiles: any) => {
                        handleAcceptFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps }: any) => (
                        <div
                          className="dropzone course-dropzone border border-1 border-dashed text-center"
                          style={{ minHeight: "80px" }}
                        >
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className="list-unstyled mb-0" id="dropzone-preview">
                      {selectFiles.map((file: any, index: number) => (
                        <li className="mt-2 dz-image-preview" key={index}>
                          <div className="border rounded">
                            <div className="d-flex flex-wrap gap-2 p-2">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar-sm bg-light rounded p-2">
                                  <img
                                    data-dz-thumbnail=""
                                    className="img-fluid rounded d-block"
                                    src={file.preview}
                                  />
                                </div>
                              </div>

                              <div className="flex-shrink-0 ms-3">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    const newImages = [...selectFiles];
                                    newImages.splice(index, 1);
                                    setSelectFiles(newImages);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Dropzone for videos */}
                  <div className="mb-3">
                    <label className="form-label">Property Videos</label>
                    <Dropzone
                      onDrop={(acceptedFiles: any) => {
                        handleAcceptVideos(acceptedFiles);
                      }}
                    >
                      {({ getRootProps }: any) => (
                        <div
                          className="dropzone course-dropzone border border-1 border-dashed text-center"
                          style={{ minHeight: "80px" }}
                        >
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className="list-unstyled mb-0" id="dropzone-preview">
                      {selectVideos.map((file: any, index: number) => (
                        <li className="mt-2 dz-video-preview" key={index}>
                          <div className="border rounded">
                            <div className="d-flex flex-wrap gap-2 p-2">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar-sm bg-light rounded p-2">
                                  <video
                                    data-dz-thumbnail=""
                                    className="img-fluid rounded d-block"
                                    src={file.preview}
                                    controls
                                  />
                                </div>
                              </div>
                              <div className="flex-shrink-0 ms-3">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    const newVideos = [...selectVideos];
                                    newVideos.splice(index, 1);
                                    setSelectVideos(newVideos);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Property details */}
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Label htmlFor="propertyTitle">
                          Property Title<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          id="propertyTitle"
                          name="propertyTitle"
                          placeholder="Enter property title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="propertyTitle"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Label htmlFor="propertyType">
                          Property Type<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          as="select"
                          id="propertyType"
                          name="propertyType"
                          className="form-select"
                        >
                          <option value="">Select Property Type</option>
                          <option value="Villa">Villa</option>
                          <option value="Residency">Residency</option>
                          <option value="Apartment">Apartment</option>
                          <option value="Others">Other</option>
                        </Field>
                        <ErrorMessage
                          name="propertyType"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label htmlFor="propertyOwnerId">
                          Select Property Owner
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          as="select"
                          id="propertyOwnerId"
                          name="propertyOwnerId"
                          className="form-select"
                        >
                          <option value="">Select Property Owner</option>
                          {propertyOwner.length > 0 &&
                            propertyOwner.map((item: any) => (
                              <option key={item.po_id} value={item.po_id}>
                                {item.po_name}
                              </option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name="propertyOwnerId"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label htmlFor="propertyDescription">
                          Property Description
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          as="textarea"
                          id="propertyDescription"
                          name="propertyDescription"
                          placeholder="Enter property Description"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="propertyDescription"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Label htmlFor="bedroom">
                          Bedroom<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="number"
                          id="bedroom"
                          name="bedroom"
                          placeholder="Enter bedroom count"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="bedroom"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Label htmlFor="bathroom">
                          Bathroom<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="number"
                          id="bathroom"
                          name="bathroom"
                          placeholder="Enter bathroom count"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="bathroom"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label>
                          Sale or Rent<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          as="select"
                          id="saleOrRent"
                          name="saleOrRent"
                          className="form-select"
                        >
                          <option value="">Select Sale Type</option>
                          <option value="sale">Sale</option>
                          <option value="rent">Rent</option>
                        </Field>
                        <ErrorMessage
                          name="saleOrRent"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Label htmlFor="sqft">
                          SQFT<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="number"
                          id="sqft"
                          name="sqft"
                          placeholder="Enter sqft area"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="sqft"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Label htmlFor="priceOrRent">
                          Price / Monthly Rent
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">$</span>
                          <Field
                            type="number"
                            id="priceOrRent"
                            name="price"
                            placeholder="Enter price"
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label htmlFor="streetAddress">
                          Street Address<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          id="streetAddress"
                          name="streetAddress"
                          placeholder="Enter street address"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="streetAddress"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="state">
                          State<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          id="state"
                          name="state"
                          placeholder="Enter state"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="country">
                          Country<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          id="country"
                          name="country"
                          placeholder="Enter country"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Form.Label htmlFor="zipcode">
                          Zipcode<span className="text-danger">*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          id="zipcode"
                          name="zipcode"
                          placeholder="Enter zipcode"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="zipcode"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label>Additional Features</Form.Label>
                        <FieldArray name="additionalFeatures">
                          {({ push, remove }) => (
                            <div>
                              {values.additionalFeatures.map(
                                (_: any, index: any) => (
                                  <div key={index} className="input-group mb-3">
                                    <Field
                                      name={`additionalFeatures.${index}`}
                                      placeholder="Enter additional feature"
                                      className="form-control"
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )
                              )}
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => push("")}
                              >
                                Add Additional Feature
                              </button>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Label htmlFor="yearBuilt">Year Built</Form.Label>
                        <Field
                          type="text"
                          id="yearBuilt"
                          name="yearBuilt"
                          placeholder="Enter year built"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="yearBuilt"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3 form-check">
                        <Field
                          type="checkbox"
                          id="hasGarage"
                          name="hasGarage"
                          className="form-check-input"
                        />
                        <Form.Label
                          htmlFor="hasGarage"
                          className="form-check-label"
                        >
                          Has Garage
                        </Form.Label>
                        <ErrorMessage
                          name="hasGarage"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      {values.hasGarage && (
                        <div className="mb-3">
                          <Form.Label htmlFor="garageSize">
                            Garage Size<span className="text-danger">*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            id="garageSize"
                            name="garageSize"
                            placeholder="Enter garage size"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="garageSize"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      )}
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3 form-check">
                        <Field
                          type="checkbox"
                          id="isActive"
                          name="isActive"
                          className="form-check-input"
                        />
                        <Form.Label
                          htmlFor="isActive"
                          className="form-check-label"
                        >
                          Property is active
                        </Form.Label>
                        <ErrorMessage
                          name="isActive"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <Button variant="secondary" type="reset">
                          <i className="bi bi-repeat align-baseline me-1"></i>{" "}
                          Reset
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          <i className="bi bi-clipboard2-check align-baseline me-1"></i>{" "}
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </FormikForm>
              )}
            </Formik>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
};

export default InformationProperties;
