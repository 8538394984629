import React, { useEffect } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
    userType: string;
}


const CourseSchedules = () => {
    const appliedLeads = useSelector((state: any) => state.Leads.appliedLeads);
    
    const token = localStorage.getItem('authUser');
  let decoded: any = "";

  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error('Token is null');
  }
  return (
    <React.Fragment>
                        <div className="mb-3">
              <h6 className="card-title">{ decoded.userType === 'contractor' ? 'Applied Leads' : 'Ongoing Leads'}</h6>
                            </div>
          {appliedLeads.length && appliedLeads.map((item: any) => {
              const createdAtDate = new Date(item.lead_created_at);

              // Format date and time
              const formattedDate = createdAtDate.toLocaleDateString();
              const formattedTime = createdAtDate.toLocaleTimeString();
              return (
                  <>
                      <Card>
                                            <Card.Body>
                                                <div className="d-flex mb-3 align-items-center">
                                                   <div className="flex-grow-1">
  <span className={
        item.lead_job_status === "OPEN" ? `badge bg-success-subtle text-success ml-5` :
        item.lead_job_status === "COMPLETED" ? `badge bg-success-subtle text-success ml-5` :
        item.lead_job_status === "DISPUTE" ? `badge bg-danger-subtle text-danger ml-5` :
        `badge bg-warning-subtle text-warning ml-5`
    }>
                                          {item.lead_status !== null ? item.lead_job_status : "Pending Request"}
    </span>
</div>
                                                    <div className="flex-shrink-0 fs-lg fw-medium">
                                                        ${item.lead_budget}
                                                    </div>
                                                </div>
                                                <h5 className="text-truncate text-capitalize"><Link to={`/project-overview?id=${item.id}`} className="text-reset"> {item.lead_cat_id}</Link></h5>
                                                <p className="text-muted">{item.user_email}</p>
                                            </Card.Body>
                                            <Card.Body className="border-top border-dashed hstack align-items-center gap-2">
                                                <div className="d-flex align-items-center gap-2 flex-grow-1 position-relative">
                                                    <div className="flex-shrink-0">
                                                        <img src={'https://themes.themesbrand.com/steex/react/material/static/media/avatar-1.8f473ed0f0cfa8c1df8a.jpg'} alt="" className="avatar-xxs rounded-circle" />
                                                    </div>
                                                    <h6 className="flex-grow-1 mb-0"><Link to="/pages-profile" className="text-reset stretched-link">{item.user_first_name} { item.user_last_name} </Link></h6>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <span className="badge bg-warning-subtle text-warning"><i className="bi bi-star-fill align-baseline me-1"></i> 4.5</span>
                                                </div>
                                            </Card.Body>
                                            <div >
                                                <ProgressBar now={74} variant="primary" className="progress-sm rounded-0" />
                          </div>
                          </Card>
                      </>
              )
          })}
    </React.Fragment>
  )
}

export default CourseSchedules
