import React, { useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

interface DelawareProps {
  onExtraData: (data: object) => void;
}

const Delaware: React.FC<DelawareProps> = ({ onExtraData }) => {
  const [extraData, setExtraData] = useState({});

  const onchangeExtraData = (label: string, value: string) => {
    const newData = { ...extraData, [label]: value };
    setExtraData(newData);
    onExtraData(newData); // Call the parent function with the updated data
  };
  return (
    <Container>
      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Does this contract include work on the principal residence of the
            owner? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            value="yes"
            name="principal-residence"
            className="mb-2"
            onChange={(e) => onchangeExtraData("principal-residence", e.target.value)}
          />
          <Form.Check type="radio" aria-label="radio 1" label="No" value="no" name="principal-residence" onChange={(e) => onchangeExtraData("principal-residence", e.target.value)}/>
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Does the Owner give up the 3-day right to rescind because there is a
            true emergency? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes. This contract will include a form to be completed in handwriting by Owner."
            name="completed-in-handwriting-by-owner"
            value="yes"
            className="mb-2"
            onChange={(e) => onchangeExtraData("completed-in-handwriting-by-owner", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No. Contractor will delay the start of work until three business days have passed."
            name="completed-in-handwriting-by-owner"
            value="yes"
            onChange={(e) => onchangeExtraData("completed-in-handwriting-by-owner", e.target.value)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Delaware;
