import React from 'react';
import Breadcrumb from 'Common/BreadCrumb';
import { Col, Container, Row } from 'react-bootstrap';
import Leadsdetails from './LeadsDetails';
import StudentDetails from './UserDetails';
import CourseSchedules from './LeadsSchedules';
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
    userType: string;
}

const MyLeads = () => {
  let decoded: any = "";
  const token = localStorage.getItem('authUser');
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error('Token is null');
  }
 const leads = useSelector((state: any) => {
    if (decoded.userType === 'contractor') {
      return state.Leads.leads;
    } else {
      return state.Leads.appliedLeads;
    }
  });
  document.title = "Leads Pro";

  return (
    <React.Fragment>
          <Row>
            <Leadsdetails data={leads} />
            <Col xl={3} lg={4}>
          <StudentDetails />
          {decoded.userType === 'contractor' ? 
          <CourseSchedules />
          : ""}
              
            </Col>
          </Row>
    </React.Fragment>
  )
}

export default MyLeads
