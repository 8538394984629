import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Button,
  Table,
  Modal,
  Alert
} from "react-bootstrap";

interface FeaturesProps {
    featureTitle: any,
    featuresList:any
}

interface JwtPayload {
  userType: string;
}

const Features: React.FC<FeaturesProps> = ({ featureTitle, featuresList }) => {
  

  return (
      <React.Fragment>
          <div className="row">
        <div className="container d-flex justify-content-center">
          <div className="col-10">
                  <h2 className='text-center mb-4'>{ featureTitle}</h2>
                  <Card style={ {borderRadius:'12px'}}>
                      <Card.Body>
                          <div className="row">
                              {featuresList.length && featuresList.map((item: any) => (
                                  <div className="col-md-4 mb-4 d-flex flex-direction-row align-items-center">
                                      <img src={item.icon} />
                                      <p className=" mb-0" style={{marginLeft:'10px'}}><b>{item.feature}</b></p>
                                  </div>
                                ))}
                          </div>
                      </Card.Body>    
                  </Card>
                  </div>
              </div>
          </div>
       
    </React.Fragment>
  );
};

export default Features;
