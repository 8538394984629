import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Alert,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { useSelector, useDispatch } from "react-redux";
import { getSubscribedUser } from "../../../slices/thunk";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
interface PersonalDataProps {
  data: any;
  userD: any;
  companyD: any;
  licenceImage: any;
  contractorLicenceImage: any;
  insuranceImage: any;
  profileVideo: any;
  compLogo: any;
  isLoading: any;
  onRemoveImage: (type: string) => void;
  createUserData: (values: any) => void;
  createCompanyData: (values: any) => void;
  onFileChange: (type: string, filePath: any) => void;
  handleActivetab: (type: string) => void;
  isPaymentVerified: Boolean;
  companyLicLoading: Boolean;
  insuranceLoading: Boolean;
  contLicLoading: Boolean;
  compLogoLoading: Boolean;
  profileVidLoading: Boolean;
  activeT: string;
}
const HomeOwnerPersonal: React.FC<PersonalDataProps> = ({
  data,
  userD,
  createUserData,
  activeT,
}) => {
  //console.log(licenceImage, "licenceImagelicenceImage");
  const dispatch: any = useDispatch();
  const [userFormattedPhoneNumber, setUserFormattedPhoneNumber] = useState("");
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscribedUser()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    setUserFormattedPhoneNumber(formatPhoneNumber(input));
    validation.setFieldValue("phoneNumber", input);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: (userD && userD.user_first_name) || "",
      lastName: (userD && userD.user_last_name) || "",
      phoneNumber: (userD && userD.user_phone) || "",
      email: (userD && userD.user_email) || "",
      birthDate: (data != null && data.ua_birth_date) || "",
      cityInput: (data != null && data.ua_city) || "",
      zipcodeInput: (data != null && data.ua_zip_code) || "",
      countryInput: (data != null && data.ua_country) || "United States",
      descpription: (data != null && data.ua_description) || "",
      address: (data != null && data.ua_address) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter your email"),
      lastName: Yup.string().required("Please enter your email"),
      phoneNumber: Yup.string().required("Please enter your email"),
      email: Yup.string().required("Please enter your email"),
      cityInput: Yup.string().required("Please enter your email"),
      zipcodeInput: Yup.string().required("Please enter your email"),
      //countryInput: Yup.string().required("Please enter your email"),
      descpription: Yup.string().required("Please enter your description"),
      address: Yup.string().required("Please enter your address"),
    }),
    onSubmit: (values) => {
      createUserData(values);
    },
  });

  useEffect(() => {
    if (validation.values.phoneNumber) {
      const userFormattedPhoneNumber = formatPhoneNumber(
        validation.values.phoneNumber
      );
      setUserFormattedPhoneNumber(userFormattedPhoneNumber);
    }
  }, [validation.values.phoneNumber]);

  const handleAlertClick = () => {
    setNotification(null);
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust opacity here
            zIndex: 99999999, // Ensure spinner is on top
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading</span>
            </Spinner>
          </div>
        </div>
      )}
      <Col xl={9}>
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeT}
          defaultActiveKey={activeT}
        >
          <Row className="d-flex align-items-center flex-wrap gap-2 mb-4">
            <div className="col-md order-1">
              <Nav
                variant="pills"
                className="arrow-navtabs nav-secondary gap-2 flex-grow-1"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first" href="#personalDetails">
                    Personal Details
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Row>
          <Tab.Content>
            <Tab.Pane eventKey="first" id="personalDetails">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">Personal Details</h6>
                </Card.Header>
                <Card.Body>
                  <Form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your firstname"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            isInvalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <Form.Control.Feedback type="invalid">
                            {/* {validation.errors.firstName} */}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="lastName">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Enter your last name"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            isInvalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.lastName === "string"
                              ? validation.errors.lastName
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="phoneNumber">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            required
                            onChange={handlePhoneNumberChange}
                            onBlur={validation.handleBlur}
                            value={userFormattedPhoneNumber}
                            isInvalid={
                              validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.phoneNumber === "string"
                              ? validation.errors.phoneNumber
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="email">Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            isInvalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.email && validation.errors.email ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.email === "string"
                              ? validation.errors.email
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="birthDate">
                            Date of Birth
                          </Form.Label>
                          <Flatpickr
                            id="birthDate"
                            name="birthDate"
                            className="form-control"
                            options={{ dateFormat: "d M, Y" }}
                            placeholder="Select date"
                            value={validation.values.birthDate}
                            onChange={(date: any) =>
                              validation.setFieldValue("birthDate", date[0])
                            }
                          />
                          {validation.errors.birthDate &&
                            validation.touched.birthDate && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.birthDate === "string"
                                  ? validation.errors.birthDate
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="cityInput">City</Form.Label>
                          <Form.Control
                            type="text"
                            id="cityInput"
                            name="cityInput"
                            placeholder="City"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cityInput || ""}
                            isInvalid={
                              validation.touched.cityInput &&
                              validation.errors.cityInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.cityInput &&
                        validation.errors.cityInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.cityInput === "string"
                              ? validation.errors.cityInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="countryInput">
                            Country
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="countryInput"
                            name="countryInput"
                            placeholder="Country"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.countryInput || "United States"
                            }
                            disabled
                            isInvalid={
                              validation.touched.countryInput &&
                              validation.errors.countryInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.countryInput &&
                        validation.errors.countryInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.countryInput === "string"
                              ? validation.errors.countryInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="zipcodeInput">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            minLength={5}
                            maxLength={6}
                            id="zipcodeInput"
                            name="zipcodeInput"
                            placeholder="Enter zipcode"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zipcodeInput || ""}
                            isInvalid={
                              validation.touched.zipcodeInput &&
                              validation.errors.zipcodeInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.zipcodeInput &&
                        validation.errors.zipcodeInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.zipcodeInput === "string"
                              ? validation.errors.zipcodeInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 pb-2">
                          <Form.Label htmlFor="address">Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            id="address"
                            name="address"
                            placeholder="Enter your address"
                            value={validation.values.address || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            isInvalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.errors.address &&
                            validation.touched.address && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.address === "string"
                                  ? validation.errors.address
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 pb-2">
                          <Form.Label htmlFor="descpription">
                            Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            id="descpription"
                            name="descpription"
                            placeholder="Enter your description"
                            value={validation.values.descpription || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            isInvalid={
                              validation.touched.descpription &&
                              validation.errors.descpription
                                ? true
                                : false
                            }
                          />
                          {validation.errors.descpription &&
                            validation.touched.descpription && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.descpription ===
                                "string"
                                  ? validation.errors.descpription
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                          <Button type="submit" variant="primary">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </React.Fragment>
  );
};

export default HomeOwnerPersonal;
