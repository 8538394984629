import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Spinner,
  Alert,
  Col,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions } from "../../slices/thunk";
import { Link } from "react-router-dom";

const Transactions = () => {
  document.title = "transactions";
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({
    show: false,
    variant: "",
    message: "",
  }); // Notification state

  const subscirbersData = useSelector(
    (state: any) => state.Payment.transactions
  );

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getTransactions(279));
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        {notification.show && (
          <Alert
            variant={notification.variant}
            onClose={() =>
              setNotification({ show: false, variant: "", message: "" })
            }
            dismissible
          >
            {notification.message}
          </Alert>
        )}
        <Container fluid>
          <BreadCrumb
            title={`Transactions - ${subscirbersData?.subscription?.cpp_package}`}
            pageTitle="Pages"
          />
          <Row>
            <Col xl={12}>
              <div className="table-responsive mt-4 mt-xl-0">
                <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Payment ID</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Invoices</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscirbersData &&
                      subscirbersData.invoices &&
                      subscirbersData.invoices.length &&
                      subscirbersData.invoices.map((item: any) => (
                        <tr key={item.id}>
                          <td className="fw-medium">{item.id}</td>
                          <td>${item.amount_paid}</td>
                          <td>{item.status}</td>
                          <td>{item.date}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() =>
                                window.open(item.invoice_pdf, "_blank")
                              }
                            >
                              Download Invoice
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
