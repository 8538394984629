//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import axios from "axios";
import { postFakeLogin, postJwtLogin } from "../../helpers/fakebackend_helper";
import { jwtDecode } from "jwt-decode";
import {
  apiError,
  propertiesSuccess,
  propertyOwnerSuccess
} from "./reducer";

import apiUrl from "slices/apiEndpoint";

export const createProperty = (property: any) => async (dispatch: any) => {
    const token = localStorage.getItem("authUser");
    if (!token) {
        console.error("Error: Authentication token not found");
        return;
    }
    const newStr = token.replace(/^"|"$/g, "");
    const formattedToken = "Bearer " + newStr;

    try {
        const recentWorkEndPoint = `${apiUrl}/real-estate/create`;

        const formData = new FormData();
        property.propId !== "" && formData.append("propId", property.propId);
        formData.append("userId", getDecodeId());
        formData.append("propTitle", property.propertyTitle);
        formData.append("propDescription", property.propertyDescription);
        formData.append("propType", property.propertyType);
        formData.append("propBedroom", property.bedroom);
        formData.append("propBathroom", property.bathroom);
        formData.append("propSqft", property.sqft);
        formData.append("propPrice", property.price);
        formData.append("propStreetAddress", property.streetAddress);
        formData.append("propState", property.state);
        formData.append("propCountry", property.country);
        formData.append("propZip", property.zipcode);
        formData.append("saleOrRent", property.saleOrRent);
        formData.append("additionalFeatures", JSON.stringify(property.additionalFeatures));
        formData.append("yearBuilt", property.yearBuilt);
        formData.append("isGarage", property.hasGarage);
      formData.append("garageSize", property.garageSize);
      formData.append("propertyowner", property.propertyOwnerId);
      formData.append("isActive", property.isActive);
        // Append multiple images
        property.images.forEach((image: File) => {
            formData.append("images", image);
        });

       // Append multiple videos or a placeholder if no videos provided
        if (property.videos && property.videos.length > 0) {
            property.videos.forEach((video: File) => {
                formData.append("videos", video);
            });
        } else {
            formData.append("videos", "no-video");
        }

        const resp = await axios.post(recentWorkEndPoint, formData, {
            headers: {
                Authorization: formattedToken,
                "Content-Type": "multipart/form-data",
            },
        });

        if (resp) {
            console.log("");
        } else {
            dispatch(apiError("Error creating recent work: Invalid response format"));
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};
  
export const getProperties = () => async (dispatch: any) => {
  try {
    const propertiesEndPoint = `${apiUrl}/real-estate/get-properties/${getDecodeId()}`;

    const resp = await axios.get(propertiesEndPoint);
    if (resp) {
      dispatch(propertiesSuccess(resp))
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const getSingleProperties = (propId:any) => async (dispatch: any) => {
  try {
    const propertiesEndPoint = `${apiUrl}/real-estate/get-single-properties/${propId}`;

    const resp = await axios.get(propertiesEndPoint);
    if (resp) {
      //dispatch(propertiesSuccess(resp))
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const deleteProperty = (id: any) => async (dispatch: any) => {
  try {
    const deletePropertyEndPoint = `${apiUrl}/real-estate/delete-properties/${id}`;

    const resp = await axios.delete(deletePropertyEndPoint);
    if (resp) {
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};


//create property owners


export const createPropertyOwner = (property: any) => async (dispatch: any) => {
    const token = localStorage.getItem("authUser");
    if (!token) {
        console.error("Error: Authentication token not found");
        return;
    }
    const newStr = token.replace(/^"|"$/g, "");
    const formattedToken = "Bearer " + newStr;

    try {
        const propertyOwnerEndpoint = `${apiUrl}/property-owner/create`;

        const formData = new FormData();
        property.poId !== "" && formData.append("propId", property.id);
        formData.append("poName", property.name);
        formData.append("poAgentId", getDecodeId());
        formData.append("poPhone", property.contact);
        formData.append("poEmail", property.email);
        formData.append("poAddress", property.location);
        formData.append("poState", property.state);
      formData.append("poZip", property.zip);
        formData.append("poCountry", property.country)
        formData.append("poAltPhone", property.altContact);
        formData.append("poIsActive", property.status);
        property.img.forEach((image: File) => {
            formData.append("image", image);
        });
      
    
        const resp = await axios.post(propertyOwnerEndpoint, formData, {
            headers: {
                Authorization: formattedToken,
                "Content-Type": "multipart/form-data",
            },
        });

        if (resp) {
            console.log(resp);
        } else {
            dispatch(apiError("Error creating recent work: Invalid response format"));
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const getPropertyOwners = () => async (dispatch: any) => {
  try {
    const propertyOwnerEndPoint = `${apiUrl}/property-owner/get-properties-owners/${getDecodeId()}`;

    const resp = await axios.get(propertyOwnerEndPoint);
    if (resp) {
      dispatch(propertyOwnerSuccess(resp))
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};


function getDecodeId() {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode(token);
  } else {
    console.error("Token is null");
  }
  return decoded.userId;
}
