import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  properties:[],
  propertyOwner:[],
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const  realEstateSlice = createSlice({
  name: "realEstateReducer",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    propertiesSuccess(state, action) {
      state.properties = action.payload.result
      state.loading = false;
      state.errorMsg = false;
    }, 
    propertyOwnerSuccess(state, action) { 
       state.propertyOwner = action.payload.result
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  apiError,
  propertiesSuccess,
  propertyOwnerSuccess
} = realEstateSlice.actions

export default realEstateSlice.reducer;