import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Tab,
  Table,
  Spinner,
  Modal,
  Alert,
} from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Dropzone from "react-dropzone";
import { useLocation } from "react-router-dom";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../../helpers/stripe/cardUtils";
import { useSelector, useDispatch } from "react-redux";
import { Document, Page } from "react-pdf";
import {
  createUserProfile,
  deleteDocument,
  geCompanyData,
  handleStripePackage,
  getSubscribedUser,
  getCards,
  getAllCategories,
  getSubCategories,
  checkVideoPaymentStatus,
  handleVideoPackage,
  getRecentWork,
} from "../../../slices/thunk";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Stripe from "./stripe";

import { createSelector } from "reselect";
import { use } from "i18next";
import { userPackage } from "slices/payment/reducer";
import AddRecentWork from "./recent-work/AddRecentWork";
import RecentWorkList from "./recent-work/RecentWork";
interface PersonalDataProps {
  data: any;
  userD: any;
  companyD: any;
  licenceImage: any;
  contractorLicenceImage: any;
  insuranceImage: any;
  profileVideo: any;
  compLogo: any;
  isLoading: any;
  onRemoveImage: (type: string) => void;
  createUserData: (values: any) => void;
  createCompanyData: (values: any) => void;
  onFileChange: (type: string, filePath: any) => void;
  handleActivetab: (type: string) => void;
  isPaymentVerified: Boolean;
  companyLicLoading: Boolean;
  insuranceLoading: Boolean;
  contLicLoading: Boolean;
  compLogoLoading: Boolean;
  profileVidLoading: Boolean;
  activeT: string;
  userType: string;
}
const Personal: React.FC<PersonalDataProps> = ({
  data,
  userD,
  companyD,
  isLoading,
  createUserData,
  createCompanyData,
  onFileChange,
  handleActivetab,
  licenceImage,
  contractorLicenceImage,
  insuranceImage,
  compLogo,
  profileVideo,
  onRemoveImage,
  isPaymentVerified,
  companyLicLoading,
  insuranceLoading,
  contLicLoading,
  compLogoLoading,
  profileVidLoading,
  activeT,
  userType,
}) => {
  //console.log(licenceImage, "licenceImagelicenceImage");
  const dispatch: any = useDispatch();
  const [text, setText] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLicenceUploading, setIsLicenceUploading] = useState<boolean>(false);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [userFormattedPhoneNumber, setUserFormattedPhoneNumber] = useState("");
  const [cardData, setCardData] = useState([]);
  const [profileOptions, setProfileOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [userSkills, setUserSkills] = useState<any>([]);
  const [showCompany, setShowCompany] = useState<any>(true);
  const [modal_video_payment, setModalVideoPayment] = useState(false);
  const [insuranceImagePath, setInsuranceImagePath] = useState<string>("");
  const [companyLicenceImagePath, setCompanyLicenceImagePath] =
    useState<string>("");
  const [contractorLicenceImagePath, setContractorLicenceImagePath] =
    useState<string>("");
  const acceptedFileTypes = [".png", ".jpg", ".jpeg"];
  const acceptedMimeTypes = ["image/png", "image/jpeg", "image/jpg"];
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  const pricingdata = [
    {
      id: 1,
      price: "$4500",
      plan: "Annually",
      start: "A Saving of $200",
      variant: "success",
      button: "success",
      ribbon: true,

      actext: [
        {
          id: 1,
          act1: "Unlimited Contract Creation",
          act2: "Add Milestones",
          act3: "Set Up Payment Plans ",
          act4: "Make your own team",
          act5: "Add Sub-Contractors",
          act6: "E-Sign the Contract Document",
          act7: "Free Download",
          act8: "Anytime Anywhere access to your Unlimited Contracts",
        },
      ],
    },
    {
      id: 2,
      price: "$364",
      plan: "Monthly",
      start: "First Payment of $696",
      variant: "success",
      button: "success",
      ribbon: false,
      actext: [
        {
          id: 1,
          act1: "Unlimited Contract Creation",
          act2: "Add Milestones",
          act3: "Set Up Payment Plans ",
          act4: "Make your own team",
          act5: "Add Sub-Contractors",
          act6: "E-Sign the Contract Document",
          act7: "Free Download",
          act8: "Anytime Anywhere access to your Unlimited Contracts",
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getAllCategories()).then((resp: any) => {
      const data = resp.result;
      const transformedData = data.map((item: any) => ({
        value: item.cat_id,
        label: item.cat_name,
      }));
      setProfileOptions(transformedData);
    });

    if (data && data.ua_skills) {
      try {
        const parsedArray = JSON.parse(data.ua_skills);
        const formattedSkills = parsedArray.map((skill: any) => ({
          value: skill.value,
          label: skill.label,
        }));
        //console.log(formattedSkills, "formattedSkillsformattedSkills")
        setSelectedOptions(formattedSkills);
      } catch (error) {
        console.error("Error parsing skills:", error);
      }
    }
  }, [dispatch, data]);

  const handleProfileOnchange = (selectedProfile: any) => {
    dispatch(getSubCategories(selectedProfile.value)).then((resp: any) => {
      const data = resp.result;
      const transformedData: any = data.map((item: any) => ({
        value: item.sc_category,
        label: item.sc_category,
      }));
      setUserSkills(transformedData); // Replace previous skills with new ones
    });
    validation.setFieldValue("profile", selectedProfile);
  };

  const handleSelectedOptionsChange = (selectedSkills: any) => {
    setSelectedOptions(selectedSkills);
    validation.setFieldValue("skills", selectedSkills);
  };

  const workAvailable = [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
  ];

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(activeT);
  const [loading, setLoading] = useState(true);

  const userSubscribed = useSelector(
    (state: any) => state.UserProfile.subscribedData
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscribedUser()).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  // Validation function to check for special characters in the file name
  function validateFileName(fileName: string) {
    const invalidChars = /[!@#\$%\^&\*\(\)\+=\{\}\[\]\|\\;:'",<>\/?]/;
    return !invalidChars.test(fileName); // Returns true if valid, false if invalid
  }

  function handleInsuranceImageChange(files: any) {
    setIsUploading(true);
    // Check if the file name is valid
    if (!validateFileName(files[0].name)) {
      alert(
        "Invalid file name. Please avoid special characters such as !@#$%^&*()+={}[]|\\;:'\",<>/?"
      );
      setIsUploading(false);
      return; // Stop further execution
    }

    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onFileChange("insurance", files[0]);
    setselectedFiles(files);
    setIsUploading(false);
  }

  function handleCompanyLogoChange(files: any) {
    setIsUploading(true);

    if (!validateFileName(files[0].name)) {
      alert(
        "Invalid file name. Please avoid special characters such as !@#$%^&*()+={}[]|\\;:'\",<>/?"
      );
      setIsUploading(false);
      return;
    }

    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onFileChange("companyLogo", files[0]);
    setselectedFiles(files);
    setIsUploading(false);
  }

  function handleCompanyLicenceImageChange(files: any) {
    if (!validateFileName(files[0].name)) {
      alert(
        "Invalid file name. Please avoid special characters such as !@#$%^&*()+={}[]|\\;:'\",<>/?"
      );
      return;
    }
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onFileChange("companyLicence", files[0]);
    setselectedFiles(files);
  }

  function handleContractorLicenceImageChange(files: any) {
    if (!validateFileName(files[0].name)) {
      alert(
        "Invalid file name. Please avoid special characters such as !@#$%^&*()+={}[]|\\;:'\",<>/?"
      );
      return;
    }

    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onFileChange("contractorLicence", files[0]);

    setselectedFiles(files);
  }

  function handleProfileVideoChange(files: any) {
    setIsUploading(true);

    const videoFile = files[0];
    const videoElement = document.createElement("video");

    videoElement.src = URL.createObjectURL(videoFile);

    videoElement.onloadedmetadata = () => {
      if (videoElement.duration > 30) {
        dispatch(checkVideoPaymentStatus()).then((resp: any) => {
          if (resp.status === false) {
            setModalVideoPayment(true);
            setIsUploading(false);
          } else if (videoElement.duration > 60) {
            alert("Sorry, the limit to upload a video is only 60 seconds.");
            setIsUploading(false);
          } else {
            setIsUploading(true);
            // Proceed with the rest of the code if the video duration is within the limit
            finalizeVideoUpload(files);
          }
        });
      } else {
        setIsUploading(true);
        // Proceed with the rest of the code if the video duration is within the limit
        finalizeVideoUpload(files);
      }
    };

    videoElement.onerror = () => {
      alert(
        "There was an error loading the video. Please try a different file."
      );
      setIsUploading(false);
    };
  }

  function finalizeVideoUpload(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onFileChange("profileVideo", files[0]);
    setselectedFiles(files);
    setIsUploading(false);
  }

  function tog_modal_video_payment() {
    setModalVideoPayment(!modal_video_payment);
  }
  useEffect(() => {
    // Function to handle the click event
    const handleClickOutside = (event: MouseEvent) => {
      // Get the modal elements
      const backdropElement = document.getElementById("staticBackdrop");

      // Check if the click is outside the modal
      if (backdropElement && !backdropElement.contains(event.target as Node)) {
        // Close the modals if they are open
        if (modal_video_payment) {
          setModalVideoPayment(false);
        }
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal_video_payment]);

  function handleRemoveInsuranceImage(type: string) {
    onRemoveImage(type);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const customStyles = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: "#3762ea",
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: "#3762ea",
      color: "white",
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: "white",
      backgroundColor: "#3762ea",
      ":hover": {
        backgroundColor: "#3762ea",
        color: "white",
      },
    }),
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  // Function to handle input change for company phone number
  const handleCompanyPhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    setFormattedPhoneNumber(formatPhoneNumber(input));
    companyValidation.setFieldValue("companyPhoneNumber", input);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    setUserFormattedPhoneNumber(formatPhoneNumber(input));
    validation.setFieldValue("phoneNumber", input);
  };

  let parsedArray = [];
  if (data) {
    const stringArray = data && data.ua_skills ? data.ua_skills : null;
    parsedArray = JSON.parse(stringArray);
  }
  let parsedProflie = [];
  if (data) {
    const stringArray = data && data.ua_profile ? data.ua_profile : null;
    parsedProflie = JSON.parse(stringArray);
  }

  let parsedAvailArray = [];
  if (data) {
    const stringArray =
      data && data.ua_work_availbility ? data.ua_work_availbility : null;
    parsedAvailArray = JSON.parse(stringArray);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: (userD && userD.user_first_name) || "",
      lastName: (userD && userD.user_last_name) || "",
      phoneNumber: (userD && userD.user_phone) || "",
      email: (userD && userD.user_email) || "",
      birthDate: (data != null && data.ua_birth_date) || "",
      joiningDate: (data != null && data.ua_joining_date) || "",
      profile: parsedProflie || [],
      skills: parsedArray || [],
      workAvailability: parsedAvailArray || [],
      experience: (data != null && data.ua_exp) || "",
      workHours: (data != null && data.ua_work_hours) || "",
      cityInput: (data != null && data.ua_city) || "",
      zipcodeInput: (data != null && data.ua_zip_code) || "",
      countryInput: (data != null && data.ua_country) || "United States",
      descpription: (data != null && data.ua_description) || "",
      address: (data != null && data.ua_address) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter your email"),
      lastName: Yup.string().required("Please enter your email"),
      phoneNumber: Yup.string().required("Please enter your email"),
      email: Yup.string().required("Please enter your email"),
      //birthDate: Yup.string().required("Please enter your email"),
      //joiningDate: Yup.string().required("Please enter your email"),
      //skills: Yup.string().required("Please enter your email"),
      //workAvailability: Yup.string().required("Please enter your email"),
      experience: Yup.string().required("Please enter your email"),
      workHours: Yup.string().required("Please enter your email"),
      cityInput: Yup.string().required("Please enter your email"),
      zipcodeInput: Yup.string().required("Please enter your email"),
      //countryInput: Yup.string().required("Please enter your email"),
      descpription: Yup.string().required("Please enter your description"),
      address: Yup.string().required("Please enter your address"),
    }),
    onSubmit: (values) => {
      createUserData(values);
    },
  });

  const companyValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: (companyD && companyD.cc_company_name) || "",
      companyRegisterdNumber:
        (companyD && companyD.cc_company_registered_number) || "",
      companyPhoneNumber: (companyD && companyD.cc_phone_number) || "",
      companyEmail: (companyD && companyD.cc_company_email) || "",
      companyRegisterdDate: (companyD && companyD.cc_registration_date) || "",
      companyCity: (companyD && companyD.cc_city) || "",
      companyAddress: (companyD && companyD.cc_address) || "",
      companyState: (companyD && companyD.cc_state) || "",
      companyZip: (companyD && companyD.cc_zip_code) || "",
      companyCountry: (companyD && companyD.cc_country) || "",
      companyInsuranceDocument:
        (companyD && companyD.cc_insurance_documentation_url) || "",
      companyLicenceDocument:
        (companyD && companyD.cc_licence_documentation_url) || "",
      companyContractorDocument:
        (companyD && companyD.cc_contractor_documentation_url) || "",
      //companyLogo: (companyD && userD.user_last_name) || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      createCompanyData(values);
    },
  });
  function handleActive(type: any) {
    handleActivetab(type);
  }

  function deletDoc(type: any) {
    dispatch(deleteDocument(type));
    onRemoveImage(type);
    dispatch(geCompanyData());
  }

  useEffect(() => {
    if (companyValidation.values.companyPhoneNumber) {
      const formattedNumber = formatPhoneNumber(
        companyValidation.values.companyPhoneNumber
      );
      setFormattedPhoneNumber(formattedNumber);
    }
  }, [companyValidation.values.companyPhoneNumber]);

  useEffect(() => {
    if (validation.values.phoneNumber) {
      const userFormattedPhoneNumber = formatPhoneNumber(
        validation.values.phoneNumber
      );
      setUserFormattedPhoneNumber(userFormattedPhoneNumber);
    }
  }, [validation.values.phoneNumber]);

  const handleStripePc = (type: any) => {
    setLoading(true);
    let values = {
      package: type,
      email: userD && userD.user_email,
    };
    dispatch(handleStripePackage(values)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (userD) {
      dispatch(getCards(userD.id)).then((resp: any) => {
        setCardData(resp);
      });
    }
  }, [userD]);

  const isRegistered = (e: any) => {
    if (e.target.checked) {
      setShowCompany(false);
    } else {
      setShowCompany(true);
    }
  };
  const handleVideoPackageFunc = (type: any) => {
    setLoading(true);
    let values = {
      package: type,
      email: userD && userD.user_email,
    };
    dispatch(handleVideoPackage(values)).finally(() => {
      setLoading(false);
    });
  };
  const handleAlertClick = () => {
    setNotification(null);
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust opacity here
            zIndex: 99999999, // Ensure spinner is on top
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading</span>
            </Spinner>
          </div>
        </div>
      )}
      <Col xl={9}>
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeT}
          defaultActiveKey={activeT}
        >
          <Row className="d-flex align-items-center flex-wrap gap-2 mb-4">
            <div className="col-md order-1">
              <Nav
                variant="pills"
                className="arrow-navtabs nav-secondary gap-2 flex-grow-1"
              >
                {userType !== "real_estate_agent" &&
                userType !== "subcontractor" ? (
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fourth"
                      onClick={() => handleActive("fourth")}
                      href="#packages"
                    >
                      Packages
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  ""
                )}

                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    onClick={() => handleActive("first")}
                    href="#personalDetails"
                  >
                    Personal Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    onClick={() => handleActive("second")}
                    href="#companyDetails"
                  >
                    Company Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="third"
                    onClick={() => handleActive("third")}
                    href="#financial"
                  >
                    Financials
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="fifth"
                    onClick={() => handleActive("fifth")}
                    href="#recentWork"
                  >
                    Recent Work
                  </Nav.Link>
                </Nav.Item>

                {/* <Nav.Item>
                  <Nav.Link eventKey="fourth" href="#changePassword">
                    Change Password
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </div>
          </Row>

          {/*Pricing */}
          {userType !== "real_estate_agent" && userType !== "subcontractor" ? (
            <Tab.Content>
              <Tab.Pane eventKey="fourth" id="packages">
                <Card>
                  <div className="tab-pane" id="packages" role="tabpanel">
                    <Card.Body>
                      <Row>
                        <Row className=" justify-content-center mt-4">
                          <Col lg={5}>
                            <div className="text-center mb-3">
                              <h4 className="fw-semibold fs-4xl">
                                Plans & Pricing
                              </h4>
                              <p className="text-muted mb-4 fs-base">
                                Explore our high quality designed Contract
                                Automation Services.
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" justify-content-center">
                          <Col xxl={12}>
                            <Row className="mb-4">
                              <img
                                className="img-fluid"
                                src="https://res.cloudinary.com/dr6rh7hon/image/upload/v1719416705/banner_gydtbh.png"
                              />
                            </Row>
                          </Col>
                          <Col xxl={9}>
                            <Row>
                              {(pricingdata || []).map((elem: any) => (
                                <Col
                                  xxl={6}
                                  lg={6}
                                  className="g-3"
                                  key={elem.id}
                                >
                                  <Card
                                    className="pricing-box ribbon-box right"
                                    style={{ background: "#f7f7f7" }}
                                  >
                                    {elem.ribbon === true ? (
                                      <div className="ribbon ribbon-danger vertical-shape">
                                        <span>Saving Plan</span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <Card.Body className="p-4">
                                      <div className="d-flex gap-3 align-items-center mb-4 pb-2">
                                        <div className="avatar-sm flex-shrink-0">
                                          <div className="avatar-title bg-success-subtle text-success fs-3 rounded">
                                            <i className="ph ph-telegram-logo"></i>
                                          </div>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="mb-2">{elem.plan}</h5>
                                          <p className="text-muted mb-0">
                                            {elem.start}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="mb-4">
                                        <h3 className="mb-0 me-2">
                                          {elem.price}{" "}
                                          <small className="fw-normal text-muted fs-xs">
                                            /{elem.plan}
                                          </small>
                                        </h3>
                                      </div>

                                      <div className="mt-4">
                                        <ul className="ps-3 vstack gap-3">
                                          {(elem.actext || []).map(
                                            (subitem: any) => (
                                              <React.Fragment key={subitem.id}>
                                                <li className="active">
                                                  {subitem.act1}
                                                </li>
                                                <li className="active">
                                                  {subitem.act2}
                                                </li>
                                                <li className="active">
                                                  {subitem.act3}
                                                </li>
                                                <li className="active">
                                                  {subitem.act4}
                                                </li>
                                                <li className="active">
                                                  {subitem.act5}
                                                </li>
                                                <li className="active">
                                                  {subitem.act6}
                                                </li>
                                                <li className="active">
                                                  {subitem.act7}
                                                </li>
                                                <li className="active">
                                                  {subitem.act8}
                                                </li>
                                              </React.Fragment>
                                            )
                                          )}
                                        </ul>
                                      </div>

                                      <div className="mt-4">
                                        {userSubscribed !== null &&
                                        userSubscribed.subscription
                                          .cpp_is_active === "ACTIVE" &&
                                        userSubscribed.subscription
                                          .cpp_package === elem.plan ? (
                                          <Button
                                            className={`btn-primary w-100`}
                                            onClick={() =>
                                              handleStripePc(elem.plan)
                                            }
                                          >
                                            Cancel Subscription
                                          </Button>
                                        ) : (
                                          <Button
                                            className={`btn-${elem.button} w-100`}
                                            onClick={() =>
                                              handleStripePc(elem.plan)
                                            }
                                          >
                                            Start Your 30 Days Free Trial
                                          </Button>
                                        )}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                        <Col lg={12}>
                          <div className="hstack gap-2 justify-content-end">
                            <Button
                              type="button"
                              onClick={() => handleActive("first")}
                              variant="primary"
                            >
                              Start Building Your Profile
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </div>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          ) : (
            ""
          )}
          {/*First */}
          <Tab.Content>
            <Tab.Pane eventKey="first" id="personalDetails">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">Personal Details</h6>
                </Card.Header>
                <Card.Body>
                  <Form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your firstname"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            isInvalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <Form.Control.Feedback type="invalid">
                            {/* {validation.errors.firstName} */}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="lastName">Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Enter your last name"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            isInvalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.lastName === "string"
                              ? validation.errors.lastName
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="phoneNumber">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            required
                            onChange={handlePhoneNumberChange}
                            onBlur={validation.handleBlur}
                            value={userFormattedPhoneNumber}
                            isInvalid={
                              validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.phoneNumber === "string"
                              ? validation.errors.phoneNumber
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="email">Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            isInvalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.email && validation.errors.email ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.email === "string"
                              ? validation.errors.email
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="birthDate">
                            Date of Birth
                          </Form.Label>
                          <Flatpickr
                            id="birthDate"
                            name="birthDate"
                            className="form-control"
                            options={{ dateFormat: "d M, Y" }}
                            placeholder="Select date"
                            value={validation.values.birthDate}
                            onChange={(date: any) =>
                              validation.setFieldValue("birthDate", date[0])
                            }
                          />
                          {validation.errors.birthDate &&
                            validation.touched.birthDate && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.birthDate === "string"
                                  ? validation.errors.birthDate
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Form.Label htmlFor="joiningDate">
                            Joining Date
                          </Form.Label>
                          <Flatpickr
                            id="joiningDate"
                            name="joiningDate"
                            className="form-control"
                            options={{ dateFormat: "d M, Y" }}
                            placeholder="Select date"
                            value={validation.values.joiningDate}
                            onChange={(date: any) =>
                              validation.setFieldValue("joiningDate", date[0])
                            }
                          />
                          {validation.errors.joiningDate &&
                            validation.touched.joiningDate && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.joiningDate ===
                                "string"
                                  ? validation.errors.joiningDate
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="profile" className="text-muted">
                            Profession
                          </Form.Label>
                          <Select
                            id="profile"
                            name="profile"
                            options={profileOptions}
                            value={validation.values.profile}
                            onChange={(selectedProfile: any) => {
                              handleProfileOnchange(selectedProfile);
                            }}
                          />
                          {validation.errors.profile &&
                            validation.touched.profile && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.profile === "string"
                                  ? validation.errors.profile
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="skills" className="text-muted">
                            Skills(Type the skills and press enter)
                          </Form.Label>
                          <CreatableSelect
                            id="skills"
                            name="skills"
                            options={userSkills}
                            isMulti
                            value={selectedOptions}
                            onChange={(selectedSkills: any) => {
                              handleSelectedOptionsChange(selectedSkills);
                            }}
                          />
                          {validation.errors.skills &&
                            validation.touched.skills && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.skills === "string"
                                  ? validation.errors.skills
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="workAvailability">
                              Work Availability
                            </Form.Label>
                            <Select
                              id="workAvailability"
                              name="workAvailability"
                              options={workAvailable}
                              value={validation.values.workAvailability}
                              onChange={(selectedWorkAvail: any) => {
                                // Update the field value with the selected profile
                                validation.setFieldValue(
                                  "workAvailability",
                                  selectedWorkAvail
                                );
                              }}
                            />
                            {validation.errors.workAvailability &&
                              validation.touched.workAvailability && (
                                <Form.Control.Feedback type="invalid">
                                  {typeof validation.errors.workAvailability ===
                                  "string"
                                    ? validation.errors.workAvailability // Changed from 'profile' to 'workAvailability'
                                    : ""}
                                </Form.Control.Feedback>
                              )}
                          </Form.Group>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="experience">
                            Experience(In Years)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="experience"
                            name="experience"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.experience || ""}
                            isInvalid={
                              validation.touched.experience &&
                              validation.errors.experience
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.experience &&
                        validation.errors.experience ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.experience === "string"
                              ? validation.errors.experience
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="workHours">
                            Work Hours
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="workHours"
                            name="workHours"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.workHours || ""}
                            isInvalid={
                              validation.touched.workHours &&
                              validation.errors.workHours
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.workHours &&
                        validation.errors.workHours ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.workHours === "string"
                              ? validation.errors.workHours
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="cityInput">City</Form.Label>
                          <Form.Control
                            type="text"
                            id="cityInput"
                            name="cityInput"
                            placeholder="City"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cityInput || ""}
                            isInvalid={
                              validation.touched.cityInput &&
                              validation.errors.cityInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.cityInput &&
                        validation.errors.cityInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.cityInput === "string"
                              ? validation.errors.cityInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="countryInput">
                            Country
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="countryInput"
                            name="countryInput"
                            placeholder="Country"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              validation.values.countryInput || "United States"
                            }
                            disabled
                            isInvalid={
                              validation.touched.countryInput &&
                              validation.errors.countryInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.countryInput &&
                        validation.errors.countryInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.countryInput === "string"
                              ? validation.errors.countryInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="zipcodeInput">
                            Zip Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            minLength={5}
                            maxLength={6}
                            id="zipcodeInput"
                            name="zipcodeInput"
                            placeholder="Enter zipcode"
                            required
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zipcodeInput || ""}
                            isInvalid={
                              validation.touched.zipcodeInput &&
                              validation.errors.zipcodeInput
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.zipcodeInput &&
                        validation.errors.zipcodeInput ? (
                          <Form.Control.Feedback type="invalid">
                            {typeof validation.errors.zipcodeInput === "string"
                              ? validation.errors.zipcodeInput
                              : ""}
                          </Form.Control.Feedback>
                        ) : null}
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 pb-2">
                          <Form.Label htmlFor="address">Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            id="address"
                            name="address"
                            placeholder="Enter your address"
                            value={validation.values.address || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            isInvalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.errors.address &&
                            validation.touched.address && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.address === "string"
                                  ? validation.errors.address
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3 pb-2">
                          <Form.Label htmlFor="descpription">
                            Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            id="descpription"
                            name="descpription"
                            placeholder="Enter your description"
                            value={validation.values.descpription || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            isInvalid={
                              validation.touched.descpription &&
                              validation.errors.descpription
                                ? true
                                : false
                            }
                          />
                          {validation.errors.descpription &&
                            validation.touched.descpription && (
                              <Form.Control.Feedback type="invalid">
                                {typeof validation.errors.descpription ===
                                "string"
                                  ? validation.errors.descpription
                                  : ""}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end">
                          <Button type="submit" variant="primary">
                            Next
                          </Button>
                          <Button className="btn btn-subtle-danger">
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          {/*Second */}

          <Tab.Content>
            <Tab.Pane eventKey="second" id="companyDetails">
              {/* <Card>
                <Card.Body>
                    <Row>
                      <Col lg={12}>
                       <div className="form-check form-switch form-check-right">
                <Form.Check type="switch" role="switch" id="isRegistered" onChange={(e)=>isRegistered(e)} style={{fontSize:'16px', marginBottom:0}} />
                <Form.Label htmlFor="isRegistered" style={{fontSize:'16px', marginBottom:0}}>If you don't have a registered company, please check the box.</Form.Label>
            </div>
                      </Col>
                    </Row>
                </Card.Body>
              </Card> */}
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">
                    Contractor Company Details
                  </h6>
                </Card.Header>
                <Card.Body>
                  <Form
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      companyValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      {showCompany ? (
                        <>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="companyName">
                                Company Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="companyName"
                                name="companyName"
                                placeholder="Enter your company name"
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                value={
                                  companyValidation.values.companyName || ""
                                }
                                isInvalid={
                                  companyValidation.touched.companyName &&
                                  companyValidation.errors.companyName
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {companyValidation.touched.companyName &&
                            companyValidation.errors.companyName ? (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors.companyName ===
                                "string"
                                  ? companyValidation.errors.companyName
                                  : ""}
                              </Form.Control.Feedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="companyRegisterdNumber">
                                Company Registered Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="companyRegisterdNumber"
                                name="companyRegisterdNumber"
                                placeholder="Enter your company registerd number"
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                value={
                                  companyValidation.values
                                    .companyRegisterdNumber || ""
                                }
                                isInvalid={
                                  companyValidation.touched
                                    .companyRegisterdNumber &&
                                  companyValidation.errors
                                    .companyRegisterdNumber
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {companyValidation.touched.companyRegisterdNumber &&
                            companyValidation.errors.companyRegisterdNumber ? (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors
                                  .companyRegisterdNumber === "string"
                                  ? companyValidation.errors
                                      .companyRegisterdNumber
                                  : ""}
                              </Form.Control.Feedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="companyPhoneNumber">
                                Company Phone Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="companyPhoneNumber"
                                name="companyPhoneNumber"
                                placeholder="Enter your company phone number"
                                onChange={handleCompanyPhoneNumberChange}
                                onBlur={companyValidation.handleBlur}
                                value={formattedPhoneNumber}
                                isInvalid={
                                  companyValidation.touched
                                    .companyPhoneNumber &&
                                  companyValidation.errors.companyPhoneNumber
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {companyValidation.touched.companyPhoneNumber &&
                            companyValidation.errors.companyPhoneNumber ? (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors
                                  .companyPhoneNumber === "string"
                                  ? companyValidation.errors.companyPhoneNumber
                                  : ""}
                              </Form.Control.Feedback>
                            ) : null}
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="companyEmail">
                                Company Email Address
                              </Form.Label>
                              <Form.Control
                                type="email"
                                id="companyEmail"
                                name="companyEmail"
                                placeholder="Enter your company email"
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                value={
                                  companyValidation.values.companyEmail || ""
                                }
                                isInvalid={
                                  companyValidation.touched.companyEmail &&
                                  companyValidation.errors.companyEmail
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {companyValidation.touched.companyEmail &&
                            companyValidation.errors.companyEmail ? (
                              <Form.Control.Feedback type="invalid">
                                {typeof companyValidation.errors
                                  .companyEmail === "string"
                                  ? companyValidation.errors.companyEmail
                                  : ""}
                              </Form.Control.Feedback>
                            ) : null}
                          </Col>

                          <Col lg={12}>
                            <div className="mb-3">
                              <Form.Label htmlFor="JoiningdatInput">
                                Company Registered Date
                              </Form.Label>
                              <Flatpickr
                                className="form-control"
                                id="companyRegisterdDate"
                                name="companyRegisterdDate"
                                options={{ dateFormat: "d M, Y" }}
                                placeholder="Select date"
                                value={
                                  companyValidation.values.companyRegisterdDate
                                }
                                onChange={(date: any) =>
                                  companyValidation.setFieldValue(
                                    "companyRegisterdDate",
                                    date[0]
                                  )
                                }
                              />
                              {companyValidation.errors.companyRegisterdDate &&
                                companyValidation.touched
                                  .companyRegisterdDate && (
                                  <Form.Control.Feedback type="invalid">
                                    {typeof companyValidation.errors
                                      .companyRegisterdDate === "string"
                                      ? companyValidation.errors
                                          .companyRegisterdDate
                                      : ""}
                                  </Form.Control.Feedback>
                                )}
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="mb-6">
                              <Form.Label htmlFor="companyAddress">
                                Company Address
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                id="companyAddress"
                                name="companyAddress"
                                placeholder="Enter your address"
                                value={
                                  companyValidation.values.companyAddress || ""
                                }
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                isInvalid={
                                  companyValidation.touched.companyAddress &&
                                  companyValidation.errors.companyAddress
                                    ? true
                                    : false
                                }
                              />
                              {companyValidation.errors.companyAddress &&
                                companyValidation.touched.companyAddress && (
                                  <Form.Control.Feedback type="invalid">
                                    {typeof companyValidation.errors
                                      .companyAddress === "string"
                                      ? companyValidation.errors.companyAddress
                                      : ""}
                                  </Form.Control.Feedback>
                                )}
                            </div>
                          </Col>

                          <Col lg={4} className="mt-4">
                            <div className="mb-6">
                              <Form.Label htmlFor="companyCity">
                                City
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="companyCity"
                                name="companyCity"
                                placeholder="City"
                                value={
                                  companyValidation.values.companyCity || ""
                                }
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                isInvalid={
                                  companyValidation.touched.companyCity &&
                                  companyValidation.errors.companyCity
                                    ? true
                                    : false
                                }
                              />
                              {companyValidation.errors.companyCity &&
                                companyValidation.touched.companyCity && (
                                  <Form.Control.Feedback type="invalid">
                                    {typeof companyValidation.errors
                                      .companyCity === "string"
                                      ? companyValidation.errors.companyCity
                                      : ""}
                                  </Form.Control.Feedback>
                                )}
                            </div>
                          </Col>

                          <Col lg={4} className="mt-4">
                            <div className="mb-6">
                              <Form.Label htmlFor="companyCountry">
                                Country
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="companyCountry"
                                name="companyCountry"
                                placeholder="Country"
                                value={
                                  companyValidation.values.companyCountry ||
                                  "United States"
                                }
                                disabled
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                isInvalid={
                                  companyValidation.touched.companyCountry &&
                                  companyValidation.errors.companyCountry
                                    ? true
                                    : false
                                }
                              />
                              {companyValidation.errors.companyCountry &&
                                companyValidation.touched.companyCountry && (
                                  <Form.Control.Feedback type="invalid">
                                    {typeof companyValidation.errors
                                      .companyCountry === "string"
                                      ? companyValidation.errors.companyCountry
                                      : ""}
                                  </Form.Control.Feedback>
                                )}
                            </div>
                          </Col>

                          <Col lg={4} className="mt-4">
                            <div className="mb-6">
                              <Form.Label htmlFor="companyZip">
                                Zip Code
                              </Form.Label>
                              <Form.Control
                                type="text"
                                minLength={5}
                                maxLength={6}
                                id="companyZip"
                                name="companyZip"
                                placeholder="Enter zipcode"
                                value={
                                  companyValidation.values.companyZip || ""
                                }
                                onChange={companyValidation.handleChange}
                                onBlur={companyValidation.handleBlur}
                                isInvalid={
                                  companyValidation.touched.companyZip &&
                                  companyValidation.errors.companyZip
                                    ? true
                                    : false
                                }
                              />
                              {companyValidation.errors.companyZip &&
                                companyValidation.touched.companyZip && (
                                  <Form.Control.Feedback type="invalid">
                                    {typeof companyValidation.errors
                                      .companyZip === "string"
                                      ? companyValidation.errors.companyZip
                                      : ""}
                                  </Form.Control.Feedback>
                                )}
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      <Col lg={12} className="mt-4 mb-10">
                        <Form.Label style={{ width: "100%" }}>
                          Showing a Corporate Video to your customers can always
                          earn you Integrity and trust
                        </Form.Label>
                        <p className="text-muted">
                          Now you can do it by simply uploading your Official
                          Corporate Video and our system will present it to the
                          Homeowners who are always searching for best certified
                          contractors for their Home Improvement Projects.
                        </p>
                        {profileVideo ? (
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <video
                              src={profileVideo}
                              height={"100%"}
                              width={"100%"}
                              style={{ padding: "10px", maxHeight: "300px" }}
                              controls
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                borderRadius: "50%",
                                background: "white",
                                padding: "5px",
                              }}
                            >
                              {companyD && companyD.cc_video_url != null ? (
                                <button
                                  type="button"
                                  onClick={() => deletDoc("profileVideo")}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <Dropzone
                              onDrop={(acceptedFiles: any) => {
                                handleProfileVideoChange(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }: any) => (
                                <div
                                  className="dropzone course-dropzone border border-1 border-dashed text-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>
                                      Upload your free video up to 30 seconds
                                      now and win more projects{" "}
                                    </h4>
                                  </div>
                                  {profileVidLoading && (
                                    <div className="text-center mt-2">
                                      <Spinner
                                        animation="border"
                                        role="status"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </>
                        )}
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label style={{ width: "100%" }}>
                          Upload your Insurance Document
                        </Form.Label>
                        {insuranceImage ? (
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {insuranceImage.endsWith(".pdf") ||
                            insuranceImage.endsWith(".doc") ||
                            insuranceImage.endsWith(".docx") ? (
                              <div
                                style={{
                                  padding: "10px",
                                  maxHeight: "300px",
                                  overflow: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflow: "auto",
                                    maxWidth: "400px",
                                  }}
                                >
                                  <Document file={insuranceImage}>
                                    <Page pageNumber={1} />
                                  </Document>
                                </div>
                              </div>
                            ) : (
                              <>
                                <img
                                  src={insuranceImage}
                                  height={"100%"}
                                  width={"100%"}
                                  style={{
                                    padding: "10px",
                                    maxHeight: "300px",
                                  }}
                                />
                              </>
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                borderRadius: "50%",
                                background: "white",
                                padding: "5px",
                              }}
                            >
                              {companyD &&
                              companyD.cc_insurance_documentation_url !=
                                null ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    deletDoc("insuranceDocumentation")
                                  }
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    deletDoc("insuranceDocumentation")
                                  }
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <Dropzone
                            accept=".png, .jpg, .jpeg"
                            onDrop={(acceptedFiles: any) => {
                              handleInsuranceImageChange(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }: any) => (
                              <div
                                className="dropzone course-dropzone border border-1 border-dashed text-center"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                                {insuranceLoading && (
                                  <div className="text-center mt-2">
                                    <Spinner animation="border" role="status" />
                                  </div>
                                )}
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label style={{ width: "100%" }}>
                          Upload your Company Licence Document
                        </Form.Label>
                        {licenceImage ? (
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {licenceImage.endsWith(".pdf") ||
                            licenceImage.endsWith(".doc") ||
                            licenceImage.endsWith(".docx") ? (
                              <div
                                style={{
                                  padding: "10px",
                                  maxHeight: "300px",
                                  overflow: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflow: "auto",
                                    maxWidth: "400px",
                                  }}
                                >
                                  <Document file={licenceImage}>
                                    <Page pageNumber={1} />
                                  </Document>
                                </div>
                              </div>
                            ) : (
                              <>
                                <img
                                  src={licenceImage}
                                  height={"100%"}
                                  width={"100%"}
                                  style={{
                                    padding: "10px",
                                    maxHeight: "300px",
                                  }}
                                />
                              </>
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                borderRadius: "50%",
                                background: "white",
                                padding: "5px",
                              }}
                            >
                              {companyD &&
                              companyD.cc_licence_documentation_url != null ? (
                                <button
                                  type="button"
                                  onClick={() =>
                                    deletDoc("licenceDocumentation")
                                  }
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    deletDoc("licenceDocumentation")
                                  }
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <Dropzone
                              accept=".png,.jpg,.jpeg"
                              onDrop={(acceptedFiles: any) => {
                                setIsLicenceUploading(true);
                                handleCompanyLicenceImageChange(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }: any) => (
                                <div
                                  className="dropzone course-dropzone border border-1 border-dashed text-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                  {companyLicLoading && (
                                    <div className="text-center mt-2">
                                      <Spinner
                                        animation="border"
                                        role="status"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </>
                        )}
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label style={{ width: "100%" }}>
                          Upload your Contractor Licence Document
                        </Form.Label>
                        {contractorLicenceImage ? (
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {contractorLicenceImage.endsWith(".pdf") ||
                            contractorLicenceImage.endsWith(".doc") ||
                            contractorLicenceImage.endsWith(".docx") ? (
                              <div
                                style={{
                                  padding: "10px",
                                  maxHeight: "300px",
                                  overflow: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    maxHeight: "300px",
                                    overflow: "auto",
                                    maxWidth: "400px",
                                  }}
                                >
                                  <Document file={contractorLicenceImage}>
                                    <Page pageNumber={1} />
                                  </Document>
                                </div>
                              </div>
                            ) : (
                              <>
                                <img
                                  src={contractorLicenceImage}
                                  height={"100%"}
                                  width={"100%"}
                                  style={{
                                    padding: "10px",
                                    maxHeight: "300px",
                                  }}
                                />
                              </>
                            )}
                            <div
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                borderRadius: "50%",
                                background: "white",
                                padding: "5px",
                              }}
                            >
                              {companyD &&
                              companyD.cc_contractor_documentation_url !=
                                null ? (
                                <button
                                  type="button"
                                  onClick={() => deletDoc("contractorLicence")}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => deletDoc("contractorLicence")}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <Dropzone
                              accept={["image/png", "image/jpeg", "image/jpg"]}
                              onDrop={(acceptedFiles: any) => {
                                handleContractorLicenceImageChange(
                                  acceptedFiles
                                );
                              }}
                              disablePreview={true}
                            >
                              {({ getRootProps, getInputProps }: any) => (
                                <div
                                  className="dropzone course-dropzone border border-1 border-dashed text-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                  {contLicLoading && (
                                    <div className="text-center mt-2">
                                      <Spinner
                                        animation="border"
                                        role="status"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </>
                        )}
                      </Col>

                      <Col lg={6} className="mt-4">
                        <Form.Label style={{ width: "100%" }}>
                          Upload your Company Logo
                        </Form.Label>
                        {compLogo ? (
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <img
                              src={compLogo}
                              height={"100%"}
                              width={"100%"}
                              style={{ padding: "10px", maxHeight: "300px" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                borderRadius: "50%",
                                background: "white",
                                padding: "5px",
                              }}
                            >
                              {companyD && companyD.cc_logo_url != null ? (
                                <button
                                  type="button"
                                  onClick={() => deletDoc("companyLogo")}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => deletDoc("companyLogo")}
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  <i className="ri-delete-bin-line" />
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            <Dropzone
                              onDrop={(acceptedFiles: any) => {
                                handleCompanyLogoChange(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }: any) => (
                                <div
                                  className="dropzone course-dropzone border border-1 border-dashed text-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                  {compLogoLoading && (
                                    <div className="text-center mt-2">
                                      <Spinner
                                        animation="border"
                                        role="status"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </>
                        )}
                      </Col>

                      <Col lg={12}>
                        <div className="hstack gap-2 mt-4 justify-content-end">
                          <Button type="submit" variant="primary">
                            Next
                          </Button>
                          <Button className="btn btn-subtle-danger">
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          {/*Third */}
          <Tab.Content>
            <Tab.Pane eventKey="third" id="financial">
              <Card>
                <Card.Header>
                  <h6 className="card-title mb-0">Financials</h6>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {isPaymentVerified ? (
                      <Table className="table-striped table-nowrap align-middle mb-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Card Type</th>
                            <th>Last 4 Digits</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cardData &&
                            cardData.length &&
                            cardData.map((item: any, index: any) => (
                              <tr key={index}>
                                <td>{item.billing_details.name}</td>
                                <td>{item.card.brand}</td>
                                <td>{item.card.last4}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ) : (
                      <Stripe userEmail={userD && userD.user_email} />
                    )}
                  </Row>
                  {/* <form>
                    <div id="newlink">
                      <div id="1">
                        <Row>
                          
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="degreeName">
                                Account Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="degreeName"
                                placeholder="Account name"
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="degreeName">
                                Account Type
                              </Form.Label>
                              <Form.Select
                                data-choices
                                name="passedYear"
                                id="passedyear"
                                defaultValue={"Choice 17"}
                              >
                                <option value="">Select Account Type</option>
                                <option value="Choice 1">Saving</option>
                                <option value="Choice 2">Current</option>
                              </Form.Select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="degreeName">
                                Bank Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="degreeName"
                                placeholder="Bank name"
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="universityName">
                                Account Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="universityName"
                                placeholder="Account Number"
                              />
                            </div>
                          </Col>

                          
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="universityName">
                                Swift Code
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="universityName"
                                placeholder="Swift Code"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <h4 style={{ textAlign: "center" }}>OR</h4>

                    <div id="newlink">
                      <div id="1">
                      
                      </div>
                    </div>
                    <Col lg={12}>
                      <div className="hstack gap-2">
                        <Button type="submit" className="btn btn-secondary">
                          Update
                        </Button>
                      </div>
                    </Col>
                  </form> */}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>

          {/*Fifth Recent Work */}
          <Tab.Content>
            <Tab.Pane eventKey="fifth" id="recentWork">
              <RecentWorkList contractorId={userD && userD.id} />
            </Tab.Pane>
          </Tab.Content>

          {/* 
          <Tab.Content>
            <Tab.Pane eventKey="fourth" id="changePassword">
              <Card>
                <div className="tab-pane" id="changePassword" role="tabpanel">
                  <Card.Header>
                    <h6 className="card-title mb-0">Changes Password</h6>
                  </Card.Header>
                  <Card.Body>
                    <Form action="/pages-profile-settings">
                      <Row className=" g-2 justify-content-lg-between align-items-center">
                        <Col lg={4}>
                          <div>
                            <Form.Label
                              htmlFor="oldpasswordInput"
                              className="form-label"
                            >
                              Old Password*
                            </Form.Label>
                            <div className="position-relative">
                              <Form.Control
                                type="password"
                                id="oldpasswordInput"
                                placeholder="Enter current password"
                                autoComplete="off"
                              />
                              <Button
                                variant="link"
                                className="position-absolute top-0 end-0 text-decoration-none text-muted password-addon"
                                id="passwordAddon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </Button>
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="auth-pass-inputgroup">
                            <Form.Label
                              htmlFor="password-input"
                              className="form-label"
                            >
                              New Password*
                            </Form.Label>
                            <div className="position-relative">
                              <Form.Control
                                type="password"
                                className="password-input"
                                id="password-input"
                                onPaste={(e) => e.preventDefault()}
                                placeholder="Enter new password"
                                aria-describedby="passwordInput"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required
                                autoComplete="off"
                              />
                              <Button
                                variant="link"
                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </Button>
                            </div>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="auth-pass-inputgroup">
                            <Form.Label htmlFor="confirm-password-input">
                              Confirm Password*
                            </Form.Label>
                            <div className="position-relative">
                              <Form.Control
                                type="password"
                                className="password-input"
                                onPaste={(e) => e.preventDefault()}
                                id="confirmPasswordInput"
                                placeholder="Confirm password"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required
                                autoComplete="off"
                              />
                              <Button
                                variant="link"
                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                id="#confirm-password-input"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <div className="d-flex align-items-center justify-content-between">
                          <Link
                            to="#"
                            className="link-primary text-decoration-underline"
                          >
                            Forgot Password ?
                          </Link>
                          <div className="">
                            <Button type="submit" variant="success">
                              Change Password
                            </Button>
                          </div>
                        </div>

                        <Col lg={12}>
                          <Card
                            className=" bg-light shadow-none passwd-bg"
                            id="password-contain"
                          >
                            <Card.Body>
                              <div className="mb-4">
                                <h5 className="fs-sm">
                                  Password must contain:
                                </h5>
                              </div>
                              <div className="">
                                <p
                                  id="pass-length"
                                  className="invalid fs-xs mb-2"
                                >
                                  Minimum <b>8 characters</b>
                                </p>
                                <p
                                  id="pass-lower"
                                  className="invalid fs-xs mb-2"
                                >
                                  At <b>lowercase</b> letter (a-z)
                                </p>
                                <p
                                  id="pass-upper"
                                  className="invalid fs-xs mb-2"
                                >
                                  At least <b>uppercase</b> letter (A-Z)
                                </p>
                                <p
                                  id="pass-number"
                                  className="invalid fs-xs mb-0"
                                >
                                  A least <b>number</b> (0-9)
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Form>
                    <div className="mt-4 mb-4 pb-3 border-bottom d-flex justify-content-between align-items-center">
                      <h5 className="card-title  mb-0">Login History</h5>
                      <div className="flex-shrink-0">
                        <Button variant="secondary">All Logout</Button>
                      </div>
                    </div>

                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive">
                          <Table className="table table-borderless align-middle mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">Mobile</th>
                                <th scope="col">IP Address</th>
                                <th scope="col">Date</th>
                                <th scope="col">Address</th>
                                <th scope="col">
                                  <i className="ri-logout-box-r-line"></i>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <i className="bi bi-phone align-baseline me-1"></i>{" "}
                                  iPhone 12 Pro
                                </td>
                                <td>192.44.234.160</td>
                                <td>18 Dec, 2023</td>
                                <td>Los Angeles, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i className="bi bi-tablet align-baseline me-1"></i>{" "}
                                  Apple iPad Pro
                                </td>
                                <td>192.44.234.162</td>
                                <td>03 Jan, 2023</td>
                                <td>Phoenix, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i className="bi bi-phone align-baseline me-1"></i>{" "}
                                  Galaxy S21 Ultra 5G
                                </td>
                                <td>192.45.234.54</td>
                                <td>25 Feb, 2023</td>
                                <td>Washington, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i className="bi bi-laptop align-baseline me-1"></i>{" "}
                                  Dell Inspiron 14
                                </td>
                                <td>192.40.234.32</td>
                                <td>16 Oct, 2022</td>
                                <td>Phoenix, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i className="bi bi-phone align-baseline me-1"></i>{" "}
                                  iPhone 12 Pro
                                </td>
                                <td>192.44.326.42</td>
                                <td>22 May, 2022</td>
                                <td>Conneticut, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i className="bi bi-tablet align-baseline me-1"></i>{" "}
                                  Apple iPad Pro
                                </td>
                                <td>190.44.182.33</td>
                                <td>19 Nov, 2023</td>
                                <td>Los Angeles, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <i className="bi bi-phone align-baseline me-1"></i>{" "}
                                  Galaxy S21 Ultra 5G
                                </td>
                                <td>194.44.235.87</td>
                                <td>30 Aug, 2022</td>
                                <td>Conneticut, United States</td>
                                <td>
                                  <Link
                                    to="#"
                                    className="icon-link icon-link-hover"
                                  >
                                    Logout{" "}
                                    <i className="bi bi-box-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </div>
              </Card>
            </Tab.Pane>
          </Tab.Content> */}
        </Tab.Container>
      </Col>
      {/* Modal create video payment */}
      <Modal
        size="lg"
        show={modal_video_payment}
        onHide={() => {
          tog_modal_video_payment();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
        data-bs-focus="false"
      >
        <Modal.Body className="text-center p-5">
          <div className="mt-4">
            <h4 className="mb-2" style={{ textTransform: "capitalize" }}>
              Your free corporate video has exceeded the time duration limit of
              30 seconds.
            </h4>
            <img
              style={{ maxWidth: "300px" }}
              src="https://res.cloudinary.com/dvnxszfqa/image/upload/v1720090099/64612_eaxfp1.jpg"
              className="img-fluid"
            />
            <p className="mb-3">
              No worries. You can always increase the time duration limit of
              your Corporate Video by just paying a minimal amount of $33.99.
            </p>
            <p className="mb-3">
              Pay $33.99/monthly or $350/annually now and enjoy a time duration
              limit of 60 seconds.
            </p>
            <div className="hstack gap-4 justify-content-center">
              <Button
                className="btn btn-primary"
                onClick={() => handleVideoPackageFunc("Monthly")}
              >
                {" "}
                Monthly - $33.99
              </Button>
              <Button
                className="btn btn-success"
                onClick={() => handleVideoPackageFunc("Annually")}
              >
                Annually - $350
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Personal;
