import React, { useState } from 'react';
import { Accordion, Button, Card, Col, Container, Row } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';

const Credentials = () => {
    const [adminEmailPasswordShow, setAdminEmailPasswordShow] = useState<boolean>(false);
    const [adminPasswordShow, setAdminPasswordShow] = useState<boolean>(false);
    const [homeownerEmailPasswordShow, setHomeownerEmailPasswordShow] = useState<boolean>(false);
    const [homeownerPasswordShow, setHomeownerPasswordShow] = useState<boolean>(false);
    const [legalEmailPasswordShow, setLegalEmailPasswordShow] = useState<boolean>(false);
    const [legalPasswordShow, setLegalPasswordShow] = useState<boolean>(false);

    document.title = "Credentials";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Credentials" pageTitle="Pages" />
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col xl={4}>
                                    <h5 className="card-title mb-3">UAT Credentials</h5>
                                    <p className="text-muted">Here are the credentials for accessing the User Acceptance Testing (UAT)</p>
                                    <h6>UAT Credentials:</h6>
                                    <ul>
                                        <li>In order to log in into the Admin Portal, and OTP verification would be required.
This system requests you to kindly log in into your ReliBuild Email Address first prior to log in the Admin Portal.</li>
<li>Please do not share/type the OTP with/on any third party Website/Links.</li>
                                        <li>Note that passwords and usernames are case sensitive.</li>
                                        <li>For any issues encountered, please contact our support team at dev@relibuild.com.</li>
                                    </ul>
                                </Col>
                                <Col xl={8}>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header id="genques-headingOne">
                                                Admin
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <h5 className='mb-3'>Credentials For Email Login</h5>
                                                <p><b>UserName: </b>Jon</p>
                                                <p><b>Email: </b>jon@relibuild.com</p>
                                                <p><b>Password: </b>{adminEmailPasswordShow ? "Jon@relibuild" : "************"}
                                                    <Button
                                                        variant="link"
                                                        className="text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setAdminEmailPasswordShow(!adminEmailPasswordShow)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </Button>
                                                </p>
                                                <a target='_blank' href="https://relibuild.awsapps.com/auth/?client_id=6b9615ec01be1c8d&redirect_uri=https%3A%2F%2Fwebmail.mail.us-east-1.awsapps.com%2Fworkmail%2F">Email Login Link</a>

                                                <h5 className='mt-4 mb-3'>Credentials For Admin Portal Login</h5>
                                                <p><b>Email: </b>jon@relibuild.com</p>
                                                <p><b>Password: </b>{adminPasswordShow ? "Jon@relibuild" : "************"}
                                                    <Button
                                                        variant="link"
                                                        className="text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setAdminPasswordShow(!adminPasswordShow)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </Button>
                                                </p>
                                                <a target='_blank' href="https://admin.relibuild.com">Portal Login Link</a>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header id="genques-headingTwo">
                                                Homeowner
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <h5 className='mb-3'>Credentials For Email Login</h5>
                                                <p><b>UserName: </b>uat-homewner</p>
                                                <p><b>Email: </b>uat-homewner@relibuild.com</p>
                                                <p><b>Password: </b>{homeownerEmailPasswordShow ? "Uathomeowner@relibuild" : "*************"}
                                                    <Button
                                                        variant="link"
                                                        className="text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setHomeownerEmailPasswordShow(!homeownerEmailPasswordShow)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </Button>
                                                </p>
                                                <a target='_blank' href="https://relibuild.awsapps.com/auth/?client_id=6b9615ec01be1c8d&redirect_uri=https%3A%2F%2Fwebmail.mail.us-east-1.awsapps.com%2Fworkmail%2F">Email Login Link</a>

                                                <h5 className='mt-4 mb-3'>Credentials For Admin Portal Login</h5>
                                                <p><b>Email: </b>uat-homewner@relibuild.com</p>
                                                <p><b>Password: </b>{homeownerPasswordShow ? "Uathomeowner@relibuild" : "*************"}
                                                    <Button
                                                        variant="link"
                                                        className="text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setHomeownerPasswordShow(!homeownerPasswordShow)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </Button>
                                                </p>
                                                <a target='_blank' href="https://app.relibuild.com">Portal Login Link</a>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header id="genques-headingThree">
                                                Legal
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <h5 className='mb-3'>Credentials For Email Login</h5>
                                                <p><b>UserName: </b>uat-legal</p>
                                                <p><b>Email: </b>uat-legal@relibuild.com</p>
                                                <p><b>Password: </b> {legalEmailPasswordShow ? "Uatlegal@relibuild" : "***********"}
                                                    <Button
                                                        variant="link"
                                                        className="text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setLegalEmailPasswordShow(!legalEmailPasswordShow)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </Button>
                                                </p>
                                                <a target='_blank' href="https://relibuild.awsapps.com/auth/?client_id=6b9615ec01be1c8d&redirect_uri=https%3A%2F%2Fwebmail.mail.us-east-1.awsapps.com%2Fworkmail%2F">Email Login Link</a>

                                                <h5 className='mt-4 mb-3'>Credentials For Admin Portal Login</h5>
                                                <p><b>Email: </b>uat-legal@relibuild.com</p>
                                                <p><b>Password: </b>{legalPasswordShow ? "Uatlegal@relibuild" : "***********"}
                                                    <Button
                                                        variant="link"
                                                        className="text-decoration-none text-muted password-addon"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setLegalPasswordShow(!legalPasswordShow)}
                                                    >
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    </Button>
                                                </p>
                                                <a target='_blank' href="https://legal.relibuild.com">Portal Login Link</a>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header id="genques-headingFour">
                                                Contractor
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header id="genques-collapseFive">
                                                Sub Contractor
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Body>
                            <Row>
                                <Col xl={4}>
                                    <h5 className="card-title mb-3">Production Credentials</h5>
                                    <p className="text-muted">Here are the credentials for accessing Production environments:</p>
                                    <h6>Steps to Follow:</h6>
                                    <ul>
                                        <li>Similar to UAT, log into your email account before accessing the portal. An OTP will be sent to your email for portal login.</li>
                                        <li>Remember, both passwords and usernames are case sensitive.</li>
                                        <li>If you encounter any problems, reach out to our support team at dev@relibuild.com for assistance.</li>
                                    </ul>
                                </Col>
                                <Col xl={8}>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header id="genques-headingOne">
                                                Admin
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header id="genques-headingTwo">
                                                Homeowner
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header id="genques-headingThree">
                                                Legal
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header id="genques-headingFour">
                                                Contractor
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header id="genques-collapseFive">
                                                Sub Contractor
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Will Update Soon
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Credentials;
