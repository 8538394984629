import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  leads: [],
  appliedLeads: [],
  singleLead: {},
  getReqLead:[],
  leadStatus:"",
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    getLeadSuccess(state, action) {
      state.leads = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
      getLeadStatus(state, action) {
      state.leadStatus = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    getSingleLead(state, action) {
      state.singleLead = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    getAppliedLeads(state, action) {
      state.appliedLeads = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    getRequestedLead(state, action) { 
      //console.log(action.payload, "action.payload")
      state.getReqLead = action.payload
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  apiError,
  getLeadSuccess,
  getLeadStatus,
  getAppliedLeads,
  getSingleLead,
  getRequestedLead
} = leadsSlice.actions

export default leadsSlice.reducer;