import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "Common/BreadCrumb";
import Dropzone from "react-dropzone";
import Axios from "axios";
import {
  Card,
  Dropdown,
  Pagination,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  ProgressBar,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

//Import Images
import successImg from "../../../../assets/images/success-img.png";
import apiUrl from "slices/apiEndpoint";
const Questions = () => {
  const location = useLocation();
  const profileData = location.state.data;

  const [contData, setContractorsData] = useState([]);
  let newData = JSON.parse(profileData.uq_question);
  let contractors: (string | null | undefined)[] = JSON.parse(
    profileData.uq_contractors
  );

  async function fetchData() {
    const profileData = (contractors = cleanArray(contractors));
    const token = localStorage.getItem("authUser");
    if (!token) {
      console.error("Error: Authentication token not found");
      return;
    }
    const newStr = token.replace(/^"|"$/g, "");
    const formattedToken = "Bearer " + newStr;

    try {
      const formattedArray = contractors.map(
        (value) => value?.replace(/-/g, "") ?? ""
      ); // Use optional chaining and nullish coalescing
      const conEndPoint = `${apiUrl}/user/getContractorbyIds/${formattedArray.join(",")}`;
      const resp = await Axios.get(conEndPoint, {
        headers: {
          Authorization: formattedToken,
        },
      });

      if (resp && resp.data) {
        setContractorsData(resp.data.data);
        // Handle the response data as needed
      } else {
        console.error("Error fetching contractors: Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching contractors:", error);
      // Handle the error, e.g., set an error state
    }
  }

  function cleanArray(arr: (string | null | undefined)[]): string[] {
    // Remove empty strings, null values, and undefined values
    const cleanedArray = arr.filter(
      (value) => value !== "" && value !== null && value !== undefined
    );
    return cleanedArray as string[];
  }

  // Call the fetchData function
  fetchData();

  document.title = "Relibuild Admin";

  const [activeArrowTab, setactiveArrowTab] = useState<number>(0);

  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(0);

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //DropZone
  const editorRef = useRef<any>();
  const [editor, setEditor] = useState(false);
  const { CKEditor, ClassicEditor }: any = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditor(true);
  }, []);

  const [data, setData] = useState<any>("");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Create Project" pageTitle="Courses" />
          <Row>
            <Col xl={12}>
              <Card>
                <Card.Header>
                  <h5>Create Project</h5>
                </Card.Header>
                <Card.Body className="form-steps">
                  <Form>
                    <Tab.Container activeKey={activeArrowTab}>
                      <div
                        id="custom-progress-bar"
                        className="progress-nav mb-4"
                      >
                        <div>
                          <ProgressBar
                            now={activeArrowTab * 50}
                            style={{ height: "1px" }}
                          ></ProgressBar>
                        </div>

                        <Nav
                          as="ul"
                          variant="pills"
                          className="progress-bar-tab custom-nav"
                        >
                          {(newData || []).map((item: any, key: any) => (
                            <Nav.Item as="li" key={key}>
                              <div // Change from Nav.Link to div
                                className={`rounded-pill ${
                                  key === activeTab ? "active" : ""
                                }`}
                                onClick={() => setActiveTab(key)}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {item.tab}
                                    </Tooltip>
                                  }
                                >
                                  <div>{item.tab}</div>
                                </OverlayTrigger>
                              </div>
                            </Nav.Item>
                          ))}
                          <Nav.Item as="li">
                            <div // Change from Nav.Link to div
                              className={`rounded-pill ${
                                newData.length + 1 === activeTab ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(newData.length + 1)}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Contractors
                                  </Tooltip>
                                }
                              >
                                <div>{newData.length + 1}</div>
                              </OverlayTrigger>
                            </div>
                          </Nav.Item>
                        </Nav>
                      </div>

                      <Tab.Content>
                        {(newData || []).map((item: any, key: any) => (
                          <Tab.Pane
                            eventKey={item.tab}
                            id={item.tab}
                            className={`fade ${
                              key === activeTab ? "show active" : ""
                            }`}
                          >
                            <h5 className="card-title mb-3">Project details</h5>
                            <Row className="g-3 align-items-center">
                              <Col lg={12}>
                                <div>
                                  <Form.Label htmlFor="course-title-input">
                                    {item.question}
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="course-title-input"
                                    placeholder="Enter course title"
                                    value={item.answer}
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        ))}

                        <Tab.Pane
                          eventKey={newData.length + 1}
                          className={`fade ${
                            newData.length + 1 === activeTab
                              ? "show active"
                              : ""
                          }`}
                          id="pills-success"
                          role="tabpanel"
                          aria-labelledby="pills-success-tab"
                        >
                          <Row>
                            {(contData || []).map((item: any, key: any) => (
                              <Col xxl={4} sm={6} key={key}>
                                <Card>
                                  <Card.Img
                                    variant="top"
                                    src="https://res.cloudinary.com/dinwqfgid/image/upload/c_scale,h_382/v1699944582/istockphoto-1492434373-612x612_rfkg5l.jpg"
                                  />
                                  <Card.Body>
                                    <div className="d-flex align-items-center mb-3">
                                      <div className="flex-grow-1 me-3">
                                        <div
                                          className={`badge bg-warning-subtle text-warning`}
                                        >
                                          {"Pending Request"}
                                        </div>
                                      </div>
                                    </div>
                                    <h6 className="fs-md text-truncate mt-0">
                                      <Link to="#" className="text-reset">
                                        {`${item.firstName} ${item.lastName}`}
                                      </Link>
                                    </h6>
                                    <p className="text-muted fs-sm text-truncate">
                                      Last Seen :{" "}
                                      <span className="fw-semibold text-reset"></span>
                                    </p>
                                    <h5 className="fs-xs">
                                      Status : Not Accepted
                                    </h5>

                                    <div className="d-flex mt-3 align-items-center">
                                      <div className="flex-grow-1">
                                        <div className="d-flex align-items-center gap-2"></div>
                                      </div>
                                      <div className="flex-shrink-0 ms-2 hstack gap-3">
                                        <span className="text-muted">
                                          <i className="bi bi-paperclip align-baseline me-1"></i>
                                          4
                                        </span>
                                        <span className="text-muted">
                                          <i className="bi bi-chat-right-dots align-baseline me-1"></i>{" "}
                                          3
                                        </span>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Questions;
