import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
    userType: string;
}
const Footer = () => {
     const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode<JwtPayload>(token);
    
      } else {
        console.error('Token is null');
      }
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © {decoded.userType === 'homeowner' ? 'Relibuild' : 'Tradesmen' }
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default Footer;