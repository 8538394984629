import React, { useEffect } from "react";
//import Scss
import "assets/scss/themes.scss";

//imoprt Route
import Route from "Routes/Index";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useSelector, useDispatch } from "react-redux";
import { isTokenExpire } from "./slices/thunk";
// Activating fake backend
fakeBackend();

const App = (props: any) => {
  const dispatch: any = useDispatch();
  useEffect(() => {
    let token = window.localStorage.getItem("token");
    if (token) {
      dispatch(isTokenExpire());
    }
  }, [dispatch]);
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
};

export default App;
