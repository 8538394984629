import React from 'react';
import avatar1 from "../../../assets/images/users/avatar-1.jpg"
import { Link } from 'react-router-dom';
import { Card, Col } from 'react-bootstrap';
 interface PropertyOwnerProps { 
    data:any
  }

const PropertyOwner: React.FC<PropertyOwnerProps> = ({ data}) => {
  return (
    <React.Fragment>
     <Col lg={12}>
            <Card >
                <Card.Body>
                    <div className="text-center">
                        <img src={avatar1} alt="" className="avatar-md rounded mx-auto d-block" />
                        <h5 className="mt-3">{data?.po_name ?? 'N/A'}</h5>
                        <p className="text-muted">OWNER</p>
                        <p>{data?.po_email ?? 'N/A'}</p>
                        <p>{data?.po_phone ?? 'N/A'}</p>
                        <p className="text-muted mb-4">
                        {data?.po_address ?? 'N/A'}, {data?.po_state ?? 'N/A'}, {data?.po_zip ?? 'N/A'}, {data?.po_country ?? 'N/A'}
                        </p>
                    </div>
                    </Card.Body>
            </Card>
        </Col>
    </React.Fragment>
  )
}

export default PropertyOwner
