import React, { useState, useMemo, useEffect } from "react";
import {
  Badge,
  Card,
  Col,
  Row,
  Dropdown,
  Button,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteModal } from "Common/DeleteModal";
import PaginationFile from "Common/PaginationFile";
import {
  deleteRealEstateGridList as onDeleteRealEstateGridList,
  updateRealEstateGridList as onUpdateRealEstateGridList,
} from "slices/realestate/thunk";
import InformationProperties from "../AddProperty/InformationProperties";

const GridProperty = ({ data }: any) => {
  const dispatch = useDispatch<any>();

  const [show, setShow] = useState(false);
  const [realestategrid, setRealestategrid] = useState<any>(null);
  const [selectfeils, setSelectfeils] = useState<any>([]);
  const [editProperty, setEditProperty] = useState(false);

  // Pagination
  const pagination: boolean = true;
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [currentpages, setCurrentpages] = useState<any>([]);
  const perPageData = 8;
  const handleClick = (e: any) => {
    setCurrentPage(Number(e.target.id));
  };
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;
  const currentdata = useMemo(
    () => data.slice(indexOfFirst, indexOfLast),
    [data, indexOfFirst, indexOfLast]
  );
  useEffect(() => {
    setCurrentpages(currentdata);
  }, [currentPage, data, currentdata]);

  const pageNumbers: any = [];
  for (let i = 1; i <= Math.ceil(data.length / perPageData); i++) {
    pageNumbers.push(i);
  }
  const handleprevPage = () => {
    let prevPage = currentPage - 1;
    setCurrentPage(prevPage);
  };
  const handlenextPage = () => {
    let nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };
  useEffect(() => {
    if (pageNumbers.length && pageNumbers.length < currentPage) {
      setCurrentPage(pageNumbers.length);
    }
  }, [currentPage, pageNumbers.length]);

  //Edit Property modal
  const handleEditClose = () => {
    setEditProperty(false);
    setSelectfeils(null);
  };
  const handleShowEditProperty = (item: any) => {
    setEditProperty(true);
    setRealestategrid(item);
    //setSelectfeils([item?.img])
  };

  //start toogle
  const handleStarToogle = (ele: any) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  const handleDeleteShow = (ele: any) => {
    setShow(true);
    setRealestategrid(ele);
  };
  const handleDeleteClose = () => setShow(false);

  //delete modal
  const deleteModalFunction = () => {
    if (realestategrid.id) {
      dispatch(onDeleteRealEstateGridList(realestategrid.id));
    }
    setShow(false);
  };

  return (
    <React.Fragment>
      <div className="col-lg">
        <Row id="property-list">
          {(currentdata || [])?.map((item: any) => {
            return (
              <Col xxl={3} lg={4} md={6} key={item.id}>
                <Card className="real-estate-grid-widgets card-animate">
                  <Card.Body className="p-2">
                    {item.rep_images &&
                      JSON.parse(item.rep_images).length > 0 && (
                        <img
                          src={JSON.parse(item.rep_images)[0]}
                          alt="img-01.jpg"
                          className="rounded w-100 object-fit-cover"
                          style={{ height: "180px" }}
                        />
                      )}
                    <Button
                      type="button"
                      variant="subtle-warning"
                      size="sm"
                      className="custom-toggle btn-icon active"
                      onClick={(e: any) => handleStarToogle(e.target)}
                    >
                      <span className="icon-on">
                        <i className="bi bi-star"></i>
                      </span>
                      <span className="icon-off">
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </Button>
                    <Dropdown className="dropdown-real-estate" drop="start">
                      <Dropdown.Toggle bsPrefix="btn-light btn-icon btn-sm">
                        <i className="bi bi-three-dots-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-end">
                        <Dropdown.Item
                          href="#"
                          className="edit-list"
                          onClick={() => handleShowEditProperty(item)}
                        >
                          {" "}
                          <i className="bi bi-pencil-square me-1 align-baseline"></i>{" "}
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          className="remove-list"
                          onClick={() => handleDeleteShow(item)}
                        >
                          {" "}
                          <i className="bi bi-trash3 me-1 align-baseline"></i>{" "}
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Card.Body>
                  <Card.Body className="p-3">
                    <p className="text-muted float-end mb-0">
                      <i className="bi bi-star text-warning align-baseline me-1"></i>{" "}
                      {item.rating}
                    </p>
                    {item.type === "Residency" ? (
                      <Badge
                        bg="success-subtle"
                        text="success"
                        className="fs-xxs mb-3"
                      >
                        {" "}
                        <i className="bi bi-house-door align-baseline me-1"></i>
                        {item.rep_type}
                      </Badge>
                    ) : item.type === "Apartment" ? (
                      <Badge
                        bg="info-subtle"
                        text="info"
                        className="fs-xxs mb-3"
                      >
                        {" "}
                        <i className="bi bi-building align-baseline me-1"></i>
                        {item.rep_type}
                      </Badge>
                    ) : (
                      <Badge
                        bg="danger-subtle"
                        text="danger"
                        className="fs-xxs mb-3"
                      >
                        {" "}
                        <i className="bi bi-house-door align-baseline me-1"></i>
                        {item.rep_type}
                      </Badge>
                    )}
                    <Link
                      to={`/apps-real-estate-property-overview?prop=${item.rep_id}`}
                    >
                      <h6 className="fs-lg text-capitalize text-truncate">
                        {item.rep_title}
                      </h6>
                    </Link>
                    <p className="text-muted">
                      <i className="bi bi-geo-alt align-baseline me-1"></i>
                      {`${item.rep_street_address}, ${item.rep_state}, ${item.rep_zip}`}
                    </p>
                    <ul className="d-flex align-items-center gap-2 flex-wrap list-unstyled">
                      <li>
                        <p className="text-muted mb-0">
                          <i className="bi bi-house align-baseline text-primary me-1"></i>
                          {item.rep_bedroom} Bedroom
                        </p>
                      </li>
                      <li>
                        <p className="text-muted mb-0">
                          <i className="ph ph-bathtub align-middle text-primary me-1"></i>
                          {item.rep_bathroom} Bathroom
                        </p>
                      </li>
                      <li>
                        <p className="text-muted mb-0">
                          <i className="bi bi-columns align-baseline text-primary me-1"></i>{" "}
                          {item.rep_sqft} sqft
                        </p>
                      </li>
                    </ul>
                    <div className="border-top border-dashed mt-3 pt-3 d-flex align-items-center justify-content-between gap-3">
                      <h5 className="mb-0">${item.rep_price}</h5>
                      <Link
                        to={`/apps-real-estate-property-overview?prop=${item.rep_id}`}
                        className="link-effect"
                      >
                        Read More
                        <i className="bi bi-chevron-right align-baseline ms-1"></i>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <PaginationFile
          className="mb-4"
          currentpages={currentpages}
          pagination={pagination}
          perPageData={perPageData}
          currentPage={currentPage}
          pageNumbers={pageNumbers}
          handlenextPage={handlenextPage}
          handleClick={handleClick}
          handleprevPage={handleprevPage}
          estateList={data}
        />
      </div>
      <Modal show={editProperty} onHide={handleEditClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="addPropertyModalLabel">
            Edit Property details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InformationProperties
            editData={realestategrid}
            isEdit={editProperty}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="hstack gap-2 justify-content-end">
            <Button className="btn btn-ghost-danger" onClick={handleEditClose}>
              {" "}
              <i className="bi bi-x-lg align-baseline me-1"></i> Close{" "}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <DeleteModal
        show={show}
        handleClose={handleDeleteClose}
        deleteModalFunction={deleteModalFunction}
      />
    </React.Fragment>
  );
};

export default GridProperty;
