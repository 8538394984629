//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import axios, {AxiosError} from "axios";
import { postFakeLogin, postJwtLogin } from "../../helpers/fakebackend_helper";
import { jwtDecode } from "jwt-decode";
import {
  userProfile,
  apiError,
  userdataSuccess,
  userSuccess,
  userSubscribedSuccess,
  allCategories,
  recentWork
} from "./reducer";
import { isTokenExpire } from "../thunk";

import apiUrl from "slices/apiEndpoint";

export const getProfileStatus = () => async (dispatch: any) => {
  const token = localStorage.getItem("authUser");
  if (!token) {
    console.error("Error: Authentication token not found");
    return;
  }
  const newStr = token.replace(/^"|"$/g, "");
  const formattedToken = "Bearer " + newStr;
  try {
    const conEndPoint = `${apiUrl}/profile/check-user/${getDecodeId()}`;

    const resp = await axios.get(conEndPoint, {
      headers: {
        Authorization: formattedToken,
      },
    });

    if (resp) {
      ////console.log(resp.data.status, "sss")
      dispatch(userProfile(resp.data.status));
      return resp.data.status;
      // Handle the response data as needed
    } else {
      //console.log("dbddjjsj")
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const getProfileData = () => async (dispatch: any) => {
  const token = localStorage.getItem("authUser");
  if (!token) {
    console.error("Error: Authentication token not found");
    return;
  }
  const newStr = token.replace(/^"|"$/g, "");
  const formattedToken = "Bearer " + newStr;
  try {
    const conEndPoint = `${apiUrl}/profile/get-user/${getDecodeId()}`;

    const resp = await axios.get(conEndPoint, {
      headers: {
        Authorization: formattedToken,
      },
    });

    if (resp) {
      dispatch(userdataSuccess(resp.data[0]));
      return resp.data[0] 
    } else {
      //console.log("dbddjjsj")
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};


export const createUserProfile =
  (userProfile: any, coverImg: any, profileImg: any, socialItems: any) =>
  async (dispatch: any) => {
    const token = localStorage.getItem("authUser");
    if (!token) {
      console.error("Error: Authentication token not found");
      return;
    }
    const newStr = token.replace(/^"|"$/g, "");
    const formattedToken = "Bearer " + newStr;

    try {
      const conEndPoint = `${apiUrl}/profile/create-user/${getDecodeId()}`;

      const resp = await axios.post(
        conEndPoint,
        {
          description: userProfile.descpription,
          skills: JSON.stringify(userProfile.skills),
          birthDate: new Date(userProfile.birthDate).getTime(),
          joiningDate: new Date(userProfile.joiningDate).getTime(),
          workAvailbility: JSON.stringify(userProfile.workAvailability),
          experience: userProfile.experience,
          workHours: userProfile.workHours,
          address: userProfile.address,
          city: userProfile.cityInput,
          zipcode: userProfile.zipcodeInput,
          country: userProfile.countryInput,
          coverPic: coverImg,
          socialLink: socialItems,
          profilePic: profileImg,
          profile: JSON.stringify(userProfile.profile),
        },
        {
          headers: {
            Authorization: formattedToken,
          },
        }
      );

      if (resp) {
        //console.log(resp, "resp")
        dispatch(userdataSuccess(resp.data));
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(
          apiError("Error fetching contractors: Invalid response format")
        );
      }
    } catch (error) {
      dispatch(apiError(error));
      // Handle the error, e.g., set an error state
    }
  };

export const updateUserProfile =
  (userProfile: any, coverImg: any, profileImg: any, socialItems: any) =>
  async (dispatch: any) => {
    const token = localStorage.getItem("authUser");
    //console.log(userProfile.skills.join(', '), "userProfile.skills")
    if (!token) {
      console.error("Error: Authentication token not found");
      return;
    }
    const newStr = token.replace(/^"|"$/g, "");
    const formattedToken = "Bearer " + newStr;

    try {
      const conEndPoint = `${apiUrl}/profile/update-user/${getDecodeId()}`;

      const resp = await axios.put(
        conEndPoint,
        {
          description: userProfile.descpription,
          skills: JSON.stringify(userProfile.skills),
          birthDate: new Date(userProfile.birthDate).getTime(),
          joiningDate: new Date(userProfile.joiningDate).getTime(),
          workAvailbility: JSON.stringify(userProfile.workAvailability),
          experience: userProfile.experience,
          workHours: userProfile.workHours,
          address: userProfile.address,
          city: userProfile.cityInput,
          zipcode: userProfile.zipcodeInput,
          country: userProfile.countryInput,
          coverPic: coverImg,
          profilePic: profileImg,
          socialLink: socialItems,
          profile: JSON.stringify(userProfile.profile),
        },
        {
          headers: {
            Authorization: formattedToken,
          },
        }
      );

      if (resp) {
        //console.log(resp, "resp")
        dispatch(userdataSuccess(resp.data));
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(
          apiError("Error fetching contractors: Invalid response format")
        );
      }
    } catch (error) {
      dispatch(apiError(error));
      // Handle the error, e.g., set an error state
    }
  };

export const updateUserSocialProfile =
  (socialLink: any) => async (dispatch: any) => {
    try {
      const conEndPoint = `${apiUrl}/profile/update-user-social`;

      const resp = await axios.put(conEndPoint, {
        userId: getDecodeId(),
        socialLink: socialLink,
      });

      if (resp) {
        //console.log(resp, "resp")
        //dispatch(userdataSuccess(resp.data))
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(
          apiError("Error fetching contractors: Invalid response format")
        );
      }
    } catch (error) {
      dispatch(apiError(error));
      // Handle the error, e.g., set an error state
    }
  };

export const updateUserImageProfile =
  (userImg: any) => async (dispatch: any) => {
    try {
      const conEndPoint = `${apiUrl}/profile/update-user-image/${getDecodeId()}`;

      const resp = await axios.put(conEndPoint, {
        imageType: userImg.imgType,
        image: userImg.img,
      });

      if (resp) {
        //console.log(resp, "resp")
        dispatch(userdataSuccess(resp.data));
        // Handle the response data as needed
      } else {
        //console.log("dbddjjsj")
        dispatch(
          apiError("Error fetching contractors: Invalid response format")
        );
      }
    } catch (error) {
      dispatch(apiError(error));
      // Handle the error, e.g., set an error state
    }
  };

export const getUserData = () => async (dispatch: any) => {
  const token = localStorage.getItem("authUser");
  if (!token) {
    console.error("Error: Authentication token not found");
    return;
  }
  const newStr = token.replace(/^"|"$/g, "");
  const formattedToken = "Bearer " + newStr;
  try {
    const conEndPoint = `${apiUrl}/user/${getDecodeId()}`;

    const resp = await axios.get(conEndPoint, {
      headers: {
        Authorization: formattedToken,
      },
    });

    if (resp) {
      //console.log(resp, "resp")
      ////console.log(resp.data.status, "sss")
      dispatch(userSuccess(resp.data));
      return resp.data
      // Handle the response data as needed
    } else {
      //console.log("dbddjjsj")
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const handleStripePackage = (packages: any) => async (dispatch: any) => {
  try {
    const conEndPoint = `${apiUrl}/stripe-sub/create-stripe-session-subscription`;

    const resp = await axios.post(conEndPoint, {
      package: packages.package,
      email: packages.email,
      userId: getDecodeId(),
    });
    if (resp) {
      console.log("")
      window.location.href = resp.data.url;
    } else {
      //console.log("dbddjjsj")
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};


export const handleVideoPackage = (packages: any) => async (dispatch: any) => {
  try {
    const conEndPoint = `${apiUrl}/stripe-sub/create-stripe-video`;

    const resp = await axios.post(conEndPoint, {
      package: packages.package,
      email: packages.email,
      userId: getDecodeId(),
    });
    if (resp) {
      window.location.href = resp.data.url;
    } else {
      //console.log("dbddjjsj")
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const getSubscribedUser = () => async (dispatch: any) => {
  const userId = getDecodeId();
  const data = { userId };

  try {
    const conEndPoint = `${apiUrl}/stripe-sub/get-subscription-details`;
    
    const resp = await axios.post(conEndPoint, data);

    if (resp.data) {
      dispatch(userSubscribedSuccess(resp.data));
      return resp.data
    } else {
      dispatch(userSubscribedSuccess(null));
    }
  } catch (error) {
    console.error("Error fetching subscription:", error);
    dispatch(userSubscribedSuccess(null));
    // Handle the error, e.g., set an error state
  }
};

export const cancelSubscription = () => async (dispatch: any) => {
  const userId = getDecodeId();
  const data = { userId };

  try {
    const conEndPoint = `${apiUrl}/stripe-sub/cancel-subscription`;
    
    const resp = await axios.post(conEndPoint, data);

    if (resp) {
      return resp
    } else {
      dispatch(apiError("Error fetching subscription: Invalid response format"));
    }
  } catch (error) {
    console.error("Error fetching subscription:", error);
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};


export const getAllCategories = () => async (dispatch: any) => {
  try {
    const conEndPoint = `${apiUrl}/service/getServices`;

    const resp = await axios.get(conEndPoint);
    if (resp) {
      dispatch(allCategories(resp));
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const checkVideoPaymentStatus = () => async (dispatch: any) => {
  try {
    const conEndPoint = `${apiUrl}/profile/get-user-video/${getDecodeId()}`;

    const resp = await axios.get(conEndPoint);
    if (resp) {
      //dispatch(allCategories(resp));
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const getSubCategories = (id:any) => async (dispatch: any) => {
  try {
    const conEndPoint = `${apiUrl}/category/get-sub-categories/${id}`;

    const resp = await axios.get(conEndPoint);
    if (resp) {
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};




// function handleStrip(e) {
//     document.querySelector('.preloader-container').style.display = 'block';
//     let package = localStorage.getItem('package')
//     console.log(package)
//     fetch(`${apiUrl}/stripe-sub/create-stripe-session-subscription", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify({
//           package: package,
//           email: e
//         })
//     })
//         .then(res => res.json())
//         .then(({ url }) => {
//             document.querySelector('.preloader-container').style.display = 'none';
//             window.location = url;
//         })
//         .catch(error => {
//             document.querySelector('.preloader-container').style.display = 'none';
//             console.error(error);
//         });
// }

export const createRecentWork =(recentWorkData:any) => async (dispatch: any) => {
    const token = localStorage.getItem("authUser");
    if (!token) {
      console.error("Error: Authentication token not found");
      return;
    }
    const newStr = token.replace(/^"|"$/g, "");
    const formattedToken = "Bearer " + newStr;

    try {
      const recentWorkEndPoint = `${apiUrl}/recent-work/create`;

      const formData = new FormData();
      formData.append("recentWorkName", recentWorkData.recentWorkName);
      formData.append("recentWorkDescription", recentWorkData.recentWorkDescription);
      formData.append("recentWorkContId", recentWorkData.recentWorkContId);
      formData.append("recentWorkvideo", recentWorkData.recentWorkvideo);
      formData.append("startedDate", recentWorkData.startedAt);
      formData.append("completedDate", recentWorkData.completedAt);
      formData.append("userId", getDecodeId(),);

      // Append multiple images
      recentWorkData.images.forEach((image: File, index: number) => {
        formData.append(`images`, image);
      });

      const resp = await axios.post(recentWorkEndPoint, formData, {
        headers: {
          Authorization: formattedToken,
          "Content-Type": "multipart/form-data",
        },
      });

      if (resp) {
        dispatch(userdataSuccess(resp.data));
      } else {
        dispatch(
          apiError("Error creating recent work: Invalid response format")
        );
      }
    } catch (error) {
      dispatch(apiError(error));
    }
}
  
export const getRecentWork = () => async (dispatch: any) => {
  try {
    const recentWorkEndPoint = `${apiUrl}/recent-work/get-recent-work/${getDecodeId()}`;

    const resp = await axios.get(recentWorkEndPoint);
    if (resp) {
      dispatch(recentWork(resp))
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};

export const deleteRecentWork = (id: any) => async (dispatch: any) => {
  try {
    const deleteRecentWorkEndPoint = `${apiUrl}/recent-work/delete-recent-work/${id}`;

    const resp = await axios.delete(deleteRecentWorkEndPoint);
    if (resp) {
      return resp
    } else {
      dispatch(apiError("Error fetching contractors: Invalid response format"));
    }
  } catch (error) {
    dispatch(apiError(error));
    // Handle the error, e.g., set an error state
  }
};



export const createHomeowner = (sub: any) => (dispatch: any) => {
  const subcontractorEndpoint = `${apiUrl}/profile/create-homeowner`;

  const contractorId = getDecodeId(); // Ensure getDecodeId() returns a valid value
  if (!contractorId) {
    console.error("Error: Contractor ID not found");
    dispatch(apiError("Contractor ID not found")); // Dispatching an error action here
    return Promise.reject("Contractor ID not found"); // Reject the promise with an error message
  }

  const data = {
    homeownerFirstname: sub.firstName,
    homeownerLastName: sub.lastName,
    homeownerEmail: sub.email,
    homeownerPhone: sub.contact,
    contractorId: contractorId
  };

  // Return the axios promise directly
  return axios.post(subcontractorEndpoint, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    dispatch({ type: 'CREATE_HOMEOWNER_SUCCESS', payload: response.data }); // Dispatching a success action
    return response.data; // Returning the response data for further processing
  })
  .catch(error => {
    dispatch(apiError(`Failed to create homeowner: ${error.message}`)); // Dispatching a specific error action with the error message
    return Promise.reject(error); // Rejecting the promise with the error object
  });
};





function getDecodeId() {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode(token);
  } else {
    console.error("Token is null");
  }
  return decoded.userId;
}
