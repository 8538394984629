//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import { getAuthHeader } from "../../helpers/jwt-token-access/auth-token-header";

import {
  apiError,
  apiSuccess,
  contractSuccess,
  mileStoneSuccess,
  hicSuccess,
  subContractorSuccess,
  projectSuccess,
  changeOrderSuccess,
  subContractSuccess,
} from "./reducer";

import apiUrl from "slices/apiEndpoint";

export const createProject = (project: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.post(
      `${apiUrl}/contract/create`,
      {
        ...project,
      },
      { headers }
    );
    let data = response;
    if (data) {
      dispatch(apiSuccess(data));
      return data;
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const updateProject = (project: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.patch(
      `${apiUrl}/contract/update-project`,
      {
        ...project,
      },
      { headers }
    );
    let data = response;
    if (data) {
      dispatch(apiSuccess(data));
      return data;
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createMileStones = (milestones: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.post(
      `${apiUrl}/contract/create-milestone`,
      {
        projectId: milestones.projectId,
        userId: getDecodeId(),
        totalCost: milestones.totalCost,
        milestones: milestones.milestones,
      },
      { headers }
    );
    let data = response.data;
    //console.log(data, "ddd")
    if (data) {
      dispatch(mileStoneSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createSubContractor =
  (subcontractor: any) => async (dispatch: any) => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/contract/create-subcontractor`,
        {
          ...subcontractor,
        },
        { headers }
      );
      let data = response.data;
      //console.log(data, "ddd")
      if (data) {
        dispatch(subContractorSuccess(data));
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const createHicContract =
  (hicContract: any) => async (dispatch: any) => {
    try {
      const headers = getAuthHeader();
      if (!headers) {
        return;
      }
      const response = await axios.post(
        `${apiUrl}/contract/create-hic-project`,
        {
          projectId: hicContract.projectId,
          address: hicContract.projectAddress,
          city: hicContract.city,
          zip: hicContract.zip,
          state: hicContract.state,
          additionalHomeOwnerName: hicContract.additionalHomeownerName,
          startDate: hicContract.startDate,
          completionDate: hicContract.endDate,
          substantialCompletionDate: hicContract.substantialCompletionDate,
          projectCostModel: hicContract.projectCostModel,
          totalAllowance: hicContract.allowances,
          workDescription: hicContract.descriptionOfWork,
          paymentSchedule: null,
          contractorAuthorizedRepresentative:
            hicContract.contractorAuthorizedRepresentative,
          homeownerAuthorizedRepresentative:
            hicContract.authorizedRepresentative,
          contractorInsuranceOption: hicContract.contractorInsurance,
          contractorInsuranceProviderName: hicContract.insuranceProviderName,
          workerCompensationInsuranceOption:
            hicContract.workersCompensationInsurance,
          contractFileName: hicContract.documentName,
          homeownerId: hicContract.homeownerId,
          workerCompensationInsuranceLimit:
            hicContract.workersCompensationInsuranceLimit,
          totalSalesPrice: hicContract.totalSalesPrice,
          contractorSignatureUrl: null,
          homeownerSignatureUrl: null,
          contractorSentAt: null,
          homeownerSentAt: null,
          additionalHomeownerSignatureUrl: null,
          previousCorporation: null,
          extraData: hicContract.extraData,
          userId: getDecodeId(),
        },
        { headers }
      );
      let data = response.data;
      //console.log(data, "ddd")
      if (data) {
        dispatch(hicSuccess(data));
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const createChangeOrder = (co: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.post(
      `${apiUrl}/contract/create-change-order`,
      {
        projectId: co.projectId,
        address: co.projectAddress,
        city: co.city,
        zip: co.zip,
        state: co.state,
        additionalHomeOwnerName: co.additionalHomeownerName,
        workDescription: co.descriptionOfWork,
        priceOfWork: co.priceForWork,
        additionalTime: co.additionalTimeToComplete,
        grossContractTotalPrior: co.adjustedGrossContractPrior,
        grossContractTotal: co.adjustedGrossContractTotal,
        netChange: co.netChangePrior,
        halfPriceOfWork: null,
        contractFileName: co.documentName,
        homeownerId: co.homeownerId,
        totalSalesPrice: co.totalSalesPrice,
        contractorSignatureUrl: null,
        homeownerSignatureUrl: null,
        contractSentAt: null,
        homeownerSentAt: null,
        userId: getDecodeId(),
      },
      { headers }
    );
    let data = response.data;
    //console.log(data, "ddd")
    if (data) {
      dispatch(changeOrderSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const createSubContract =
  (subContract: any) => async (dispatch: any) => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/contract/create-sub-contract`,
        {
          projectSubId: subContract.subContractorId,
          projectId: subContract.projectId,
          state: subContract.state,
          additionalHomeOwnerName: subContract.additionalHomeownerName,
          contractorAuthorizedRepresentative:
            subContract.contractorAuthorizedRepresentative,
          contractorInsuranceOption: subContract.contractorInsurance,
          contractorInsuranceProviderName: subContract.insuranceProviderName,
          workerCompensationInsuranceOption:
            subContract.workersCompensationInsurance,
          workerCompensationInsuranceLimit:
            subContract.workersCompensationInsuranceLimit,
          descriptionOfServices: subContract.workersCompensationInsuranceLimit,
          subContratorResponsibilities:
            subContract.subcontractorResponsibilities,
          descriptionOfOtherResponsibilities: subContract.descriptionOfWork,
          commencementDate: subContract.subcontractorStartDate,
          completionDateOptions: subContract.completionDateOption,
          completionDateSpecific: subContract.completionDateSpecific,
          completionDateOther: subContract.completionDateOther,
          substantialCompletionDateOptions:
            subContract.substantialCompletionDateOption,
          substantialCompletionDateSpecific:
            subContract.substantialCompletionDateSpecific,
          substantialCompletionDateOther:
            subContract.substantialCompletionDateOther,
          paymentAmountOptions: subContract.paymentAmountOption,
          paymentAmountTotalAmount: subContract.totalAmount,
          paymentAmountHourlyRate: subContract.hourlyRate,
          paymentAmountOtherDescription: subContract.otherDescription,
          paymentMethodOptions: subContract.paymentMethodOption,
          paymentMethodOptionsOther: subContract.otherPaymentMethod,
          paymentFrequencyOptions: subContract.paymentFrequencyOption,
          paymentFrequencyOptionsOther: subContract.otherPaymentFrequency,
          rightToSubcontractingOptions: subContract.rightToSubcontract,
          assignmentRightOptions: subContract.assignmentRight,
          subcontractorInsuranceOption: subContract.subContractorInsurance,
          subContractorInsuranceProviderName:
            subContract.subInsuranceProviderName,
          subcontractorWorkerCompensationInsuranceOptions:
            subContract.subWorkersCompensationInsurance,
          subcontractorWorkerCompensationInsuranceLimit:
            subContract.subWorkersCompensationInsuranceLimit,
          subcontractorAuthorizedRepresentative:
            subContract.subcontractorAuthorizedRepresentative,
          additionalProvisions: subContract.additionalProvision,
          fileName: subContract.documentName,
          contractorSignatureUrl: null,
          subcontractorSignatureUrl: null,
          contractorSignedAt: null,
          subContractorSignedAt: null,
          contractorSentAt: null,
          subcontractorSentAt: null,
          userId: getDecodeId(), // Assuming getDecodeId returns the userId
        },
        { headers }
      );

      const data = response.data;
      if (data) {
        dispatch(subContractSuccess(data)); // Assuming subContractSuccess is an action creator
      }
    } catch (error) {
      dispatch(apiError(error)); // Assuming apiError is an action creator for handling API errors
    }
  };

export const getContractsById = () => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  
  try {
    const response = await axios.get(
      `${apiUrl}/contract/getContract/${getDecodeId()}`,
      { headers }
    );
    let data = response;
    //console.log(data, "ddd")
    if (data) {
      dispatch(contractSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getSubcontractorContractsById = () => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.get(
      `${apiUrl}/contract/get-subcontractor-contract/${getDecodeId()}`,
      { headers }
    );
    let data = response;
    //console.log(data, "ddd")
    if (data) {
      dispatch(contractSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getHomeownerContractsById = () => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.get(
      `${apiUrl}/contract/get-homeowner-contract/${getDecodeId()}`,
      { headers }
    );
    let data = response;
    //console.log(data, "ddd")
    if (data) {
      dispatch(contractSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getInspectorContractsById = () => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.get(
      `${apiUrl}/contract/get-inspector-contract/${getDecodeId()}`,
      { headers }
    );
    let data = response;
    //console.log(data, "ddd")
    if (data) {
      dispatch(contractSuccess(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const getSingleProjectById =
  (projectId: any) => async (dispatch: any) => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    try {
      const response = await axios.get(
        `${apiUrl}/contract/get-project/${projectId}`,
        { headers }
      );
      let data = response.data.project;
      if (data) {
        dispatch(projectSuccess(data));
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const getSignedContracts = () => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  let userType = getDecodeUserType();
  try {
    const response = await axios.get(`${apiUrl}/contract/get-signed-projects`, {
      headers,
    });
    let data = response;
    //console.log(data, "ddd")
    if (data && userType === "admin") {
      dispatch(contractSuccess(data.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const requestInspection = (ri: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.post(
      `${apiUrl}/contract/request-inspection`,
      {
        inspections: [
          {
            type: ri.type,
            documentUrl: ri.documentUrl,
            milestoneId: ri.milestoneId,
          },
        ],
        userId: getDecodeId(),
      },
      { headers }
    );
    let data = response.data;
    //console.log(data, "ddd")
    if (data) {
      //dispatch(subContractorSuccess(data));
      return data;
    }
  } catch (error) {
    dispatch(apiError(error));
    return error;
  }
};

export const assignContract =
  (projectid: any, inspectorId: any) => async (dispatch: any) => {
    const headers = getAuthHeader();
    if (!headers) {
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/contract/assign-inspector`,
        {
          projectId: projectid,
          inspectorId: inspectorId,
        },
        { headers }
      );
      let data = response;
      //console.log(data, "ddd")
      //dispatch(contractSuccess(data.data));
    } catch (error) {
      dispatch(apiError(error));
    }
  };

export const acceptContractTerms = (term: any) => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.post(
      `${apiUrl}/contract/accept-terms`,
      {
        isAccepted: term,
        contId: getDecodeId(),
      },
      { headers }
    );
    const data = response.data;
    // Uncomment and adjust the line below if you have a success action to dispatch
    // dispatch(contractSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(apiError(error));
  }
};

export const downloadCancelForm = () => async (dispatch: any) => {
  const headers = getAuthHeader();
  if (!headers) {
    return;
  }
  try {
    const response = await axios.post(
      `${apiUrl}/contract/download-cancel`,
      {
        userId: getDecodeId(),
      },
      { headers }
    );
    const data = response;
    return data;
  } catch (error) {
    console.error(error);
    dispatch(apiError(error));
  }
};

function getDecodeId() {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode(token);
  } else {
    console.error("Token is null");
  }
  return decoded.userId;
}

function getDecodeUserType() {
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode(token);
  } else {
    console.error("Token is null");
  }
  return decoded.userType;
}
