import React from "react";
import Breadcrumb from "Common/BreadCrumb";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

//Import Images
import logoLight from "../../../assets/images/logo-light.png";
import invoiceSignature from "../../../assets/images/invoice-signature.svg";

const OverviewContracts = () => {
  document.title = "Contracts Overview";

  const printInvoice = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Contracts Overview" pageTitle="Contracts" />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <div className="hstack gap-2 justify-content-end d-print-none mb-4">
                <Link to="#" className="btn btn-success" onClick={printInvoice}>
                  <i className="ri-printer-line align-bottom me-1"></i> Print
                </Link>
                <Button variant="primary">
                  <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                  Download
                </Button>
              </div>
              <Card className="overflow-hidden" id="invoice">
                <div className="invoice-effect-top position-absolute start-0">
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 764 182"
                    width="764"
                    height="182"
                  >
                    <title>&lt;Group&gt;</title>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          style={{ fill: "var(--tb-light)" }}
                          d="m-6.6 177.4c17.5 0.1 35.1 0 52.8-0.4 286.8-6.6 537.6-77.8 700.3-184.6h-753.1z"
                        />
                      </g>
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          style={{ fill: "var(--tb-secondary)" }}
                          d="m-6.6 132.8c43.5 2.1 87.9 2.7 132.9 1.7 246.9-5.6 467.1-59.2 627.4-142.1h-760.3z"
                        />
                      </g>
                      <g id="&lt;Group&gt;" style={{ opacity: ".5" }}>
                        <path
                          id="&lt;Path&gt;"
                          style={{ fill: "var(--tb-primary)" }}
                          d="m-6.6 87.2c73.2 7.4 149.3 10.6 227.3 8.8 206.2-4.7 393.8-42.8 543.5-103.6h-770.8z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <Card.Body className="z-1 position-relative">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <img
                        src={logoLight}
                        className="card-logo"
                        alt="logo light"
                        height="28"
                      />
                    </div>
                    <div className="flex-shrink-0 mt-sm-0 mt-3">
                      <h6>
                        <span className="text-muted fw-normal">
                          Legal Registration No:
                        </span>{" "}
                        <span id="legal-register-no">32654 9851</span>
                      </h6>
                      <h6>
                        <span className="text-muted fw-normal">Email:</span>{" "}
                        <span id="email">info@relibuild.com</span>
                      </h6>
                      <h6>
                        <span className="text-muted fw-normal">Website:</span>{" "}
                        <Link
                          to="https://relibuild.com/"
                          className="link-primary"
                          target="_blank"
                          id="website"
                        >
                          www.relibuild.com
                        </Link>
                      </h6>
                      <h6 className="mb-0">
                        <span className="text-muted fw-normal">
                          Contact No:{" "}
                        </span>
                        <span id="contact-no"> +(01) 234 6789</span>
                      </h6>
                    </div>
                  </div>
                  <div className="mt-5 pt-4">
                    <Row className="g-3">
                      <Col lg={true} className="col-6">
                        <p className="text-muted mb-2 text-uppercase">
                          Contract No
                        </p>
                        <h5 className="fs-md mb-0">
                          #TBS<span id="invoice-no">24301901</span>
                        </h5>
                      </Col>

                      <Col lg={true} className="col-6">
                        <p className="text-muted mb-2 text-uppercase">Date</p>
                        <h5 className="fs-md mb-0">
                          <span id="invoice-date">10 April, 2023</span>
                        </h5>
                      </Col>
                      <Col lg={true} className="col-6">
                        <p className="text-muted mb-2 text-uppercase">State</p>
                        <h5 className="fs-md mb-0">
                          <span id="invoice-due-date">Ohio</span>
                        </h5>
                      </Col>

                      <Col lg={true} className="col-6">
                        <p className="text-muted mb-2 text-uppercase">Type</p>
                        <span
                          className="badge bg-success-subtle text-success fs-xxs"
                          id="payment-status"
                        >
                          Escrow
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-4 pt-2">
                    <Row className="g-3">
                      <div className="col-12">
                        <h6 className="fs-md text-center" id="billing-name">
                          This draft agreement is subject to change/fine tuning
                          before final award of the contract
                        </h6>
                        <h4 className="fs-md text-center">
                          AGREEMENT FOR HALL OF RESIDENCE NO. –
                        </h4>
                      </div>
                    </Row>
                  </div>
                  <Row>
                    <p>
                      THIS AGREEMENT has been made on this __th day of October,
                      2012 at IIT Kanpur BETWEEN Indian Institute of Technology
                      Kanpur (hereinafter referred to as the Institute)
                      incorporated as a body of corporate under the Institute of
                      Technology Act, 1961, through its Director (represented by
                      Dean of Students’ Affairs, Chairman, Council of Wardens &
                      Warden-in-Charge/Warden of Hall of Residence No.-__.) AND
                      M/s __________ registered under ________________________
                      and having it’s office at
                      __________________________________________________
                      (hereinafter referred to as The Service Provider which
                      expression shall include his/their respective heirs,
                      executors, administrators and assignees), represented by
                      _____________________________ on the other part. AND
                      whereas the Institute has empanelled some agencies for
                      providing operational services to its mess of Halls of
                      Residence as per the terms and conditions, specifications
                      and general conditions of the contract, as attached along
                      with this agreement. AND whereas the said terms and
                      conditions, specifications as well as the scope of work to
                      be done, as set out in the General Conditions of the
                      contract, have been accepted and signed by the Service
                      Provider.
                    </p>

                    <p>
                      AND whereas the Service Provider has agreed to execute,
                      upon and subject to the condition set forth herein,
                      (hereinafter referred to as the said conditions) the work
                      shown in the General Conditions of the Contract. AND
                      whereas the Service Provider has deposited a sum of Rs.
                      __________ (___ lakhs only) as earnest money in the form
                      of a Demand Draft No. ________ dated _______ issued by
                      ___________________ in favour of Hall Management, IIT
                      Kanpur payable at UBI, I.I.T. Branch Kanpur as performance
                      guarantee for the said work and has agreed that this be
                      retained by the Institute as part of Security Deposit of
                      Rs. 5,00,000/- (Five Lakhs only) AND whereas the Service
                      Provider has promised to deposit Rs. __________ (_____
                      lakhs only) as remaining part of the security deposit in
                      the form of a Bank Draft latest by __________ AND whereas
                      in pursuance thereof the Service Provider has commenced
                      the work with effect from October 01, 2012.
                    </p>
                  </Row>
                  <div className="mt-4 mb-4">
                    <div className="alert alert-danger mb-0">
                      <span className="fw-semibold">NOTES:</span>
                      <span id="note">
                        {" "}
                        All accounts are to be paid within 7 days from receipt
                        of invoice. To be paid by cheque or credit card or
                        direct payment online. If account is not paid within 7
                        days the credits details supplied as confirmation of
                        work undertaken will be charged the agreed quoted fee
                        noted above.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="invoice-signature text-center">
                      {/* <img src={invoiceSignature} alt="" id="sign-img" height="30" /> */}
                      <h6 className="mb-0 mt-3">Authorized Sign</h6>
                    </div>
                  </div>
                </Card.Body>
                <div
                  className="invoice-effect-top position-absolute end-0"
                  style={{ transform: "rotate(180deg)", bottom: "-80px" }}
                >
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 764 182"
                    width="764"
                    height="182"
                  >
                    <title>&lt;Group&gt;</title>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          style={{ fill: "var(--tb-light)" }}
                          d="m-6.6 177.4c17.5 0.1 35.1 0 52.8-0.4 286.8-6.6 537.6-77.8 700.3-184.6h-753.1z"
                        />
                      </g>
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          style={{ fill: "var(--tb-secondary)" }}
                          d="m-6.6 132.8c43.5 2.1 87.9 2.7 132.9 1.7 246.9-5.6 467.1-59.2 627.4-142.1h-760.3z"
                        />
                      </g>
                      <g id="&lt;Group&gt;" style={{ opacity: "0.5" }}>
                        <path
                          id="&lt;Path&gt;"
                          style={{ fill: "var(--tb-primary)" }}
                          d="m-6.6 87.2c73.2 7.4 149.3 10.6 227.3 8.8 206.2-4.7 393.8-42.8 543.5-103.6h-770.8z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OverviewContracts;
