import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import Styles from "./styles";
import { Form, Field } from "react-final-form";
import { Spinner, Alert } from "react-bootstrap";
import Card from "./card";
import { useSelector, useDispatch } from "react-redux";
import { createPayment, getPaymentStatus } from "../../../../slices/thunk";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../../../helpers/stripe/cardUtils";
import axios from "axios";
import apiUrl from "slices/apiEndpoint";

axios.defaults.baseURL = "/api";

const sleep = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(resolve, ms));

declare global {
  interface Window {
    Stripe: any; // Define the Stripe property type
  }
}

interface StripeProps {
  userEmail: any;
}
const Stripe: React.FC<StripeProps> = ({ userEmail }) => {
  const [loading, setLoading] = useState(false); // State variable for loading
  const [notification, setNotification] = useState({
    show: false,
    variant: "",
    message: "",
  }); // Notification state
  const dispatch: any = useDispatch();
  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(
          "pk_live_51K6MBVAPzdvW0Lalca5t7QfATaFDZ3hPgoOc6E3A5eYC8Yyc7lNZutsjQGdv5Cxj2YCfjLju65W2gtyWeh5wdfFL00UFyNn7Xz"
          //"pk_test_51K6MBVAPzdvW0Lal5Vs4TvDmBT6nkDvKhvY7IOgRHisl5Kd2Y7p55xwodWqqmutDLvIafVxpWpMYoBCuDLJNTteP00bRgIYRAk"
        );
      };
      window.document.body.appendChild(s);
    }
  }, []);

  const onSubmit = async (values: any) => {
    setLoading(true); // Set loading to true when submitting
    await sleep(300);
    try {
      (window as any).Stripe.card.createToken(
        {
          number: values.number,
          exp_month: values.expiry.split("/")[0],
          exp_year: values.expiry.split("/")[1],
          cvc: values.cvc,
          name: values.name,
        },
        (status: any, response: any) => {
          if (status !== 200) {
            setLoading(false);
            setNotification({
              show: true,
              variant: "danger",
              message: response.error.message,
            });
          }
          if (status === 200) {
            axios
              .post(`${apiUrl}/stripe/api/stripe-payment`, {
                token: response,
                email: values.email,
                amount: values.amount,
              })
              .then(async (res: any) => {
                try {
                  const values = {
                    paymentMethodId: res.paymentMethodId,
                    customerId: res.customerId,
                  };
                  await dispatch(createPayment(values));
                  await dispatch(getPaymentStatus());
                } catch (error) {
                  console.error("Error dispatching actions:", error);
                } finally {
                  setLoading(false); // Set loading to false when request completes
                }
              })
              .catch((err) => console.log(err));
          }
        }
      );
    } catch (error: any) {}
  };

  return (
    <Styles>
      {loading ? ( // Render spinner if loading is true
        <Spinner />
      ) : (
        <>
          {notification.show && (
            <Alert
              variant={notification.variant}
              onClose={() =>
                setNotification({ show: false, variant: "", message: "" })
              }
              dismissible
            >
              {notification.message}
            </Alert>
          )}

          <Form
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              active,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    number={values.number || ""}
                    name={values.name || ""}
                    expiry={values.expiry || ""}
                    cvc={values.cvc || ""}
                    focused={active}
                  />
                  <div>
                    <Field
                      name="email"
                      component="input"
                      type="text"
                      defaultValue={userEmail}
                      placeholder="Your email"
                    />
                  </div>
                  <div>
                    <Field
                      name="number"
                      component="input"
                      type="text"
                      pattern="[\d| ]{16,22}"
                      placeholder="Card Number"
                      format={formatCreditCardNumber}
                    />
                  </div>
                  <div>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Name"
                    />
                  </div>
                  <div>
                    <Field
                      name="expiry"
                      component="input"
                      type="text"
                      pattern="\d\d/\d\d"
                      placeholder="Valid Thru"
                      format={formatExpirationDate}
                    />
                    <Field
                      name="cvc"
                      component="input"
                      type="text"
                      pattern="\d{3,4}"
                      placeholder="CVC"
                      format={formatCVC}
                    />
                  </div>
                  <div className="buttons">
                    <button type="submit" disabled={submitting}>
                      Add Card
                    </button>
                    <button
                      type="button"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              );
            }}
          />
        </>
      )}
    </Styles>
  );
};

export default Stripe;
