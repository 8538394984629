import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userCompanyStatus: null,
  companyData: null,
  company: null,
  deleteMsg:"",
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const contractorCompanySlice = createSlice({
  name: "contractorCompany",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    contractorCompanyStatus(state, action) {
      state.userCompanyStatus = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    contractorCompanySuccess(state, action) {
      state.company = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    createCompanySuccess(state, action) {
      state.companyData = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
      deleteDocumentSuccess(state, action) {
      state.deleteMsg = action.payload
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  apiError,
  contractorCompanyStatus,
  contractorCompanySuccess,
  createCompanySuccess,
  deleteDocumentSuccess
} = contractorCompanySlice.actions

export default contractorCompanySlice.reducer;