import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import HurryUp from "../../../../../assets/images/tutorial/hurry-up.png";

interface TermCondtionProps {
  handleTerm: (values: any) => void;
}

interface JwtPayload {
  userType: string;
}

const TermsCondition: React.FC<TermCondtionProps> = ({ handleTerm }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleTerm(event.target.checked);
  };
  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="container d-flex justify-content-center">
          <div className="col-10">
            <div className="form-check">
              <Form.Check
                type="checkbox"
                onChange={handleCheckboxChange}
                id="formCheck2"
              />
              <Form.Label
                className="form-check-label"
                style={{ fontSize: "18px" }}
                htmlFor="formCheck2"
              >
                Please accept all the{" "}
                <a
                  href="https://www.thetradesmens.com/policies/privacy-policy.html"
                  target="_blank"
                >
                  Terms & Conditions{" "}
                </a>{" "}
                by clicking the check box and Your contracts.
              </Form.Label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TermsCondition;
