import React, {
  useEffect, useState
} from 'react';
import Breadcrumb from 'Common/BreadCrumb';
import { Col, Container, Row } from 'react-bootstrap';
import Overview from './Overview';
import Saleprice from './Saleprice';
import PropertyOwner from './PropertyOwner';
import AgentDetails from './AgentDetails';
import OwnerDetails from './OwnerDetails';
import { getSingleProperties } from "../../../slices/thunk";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const PropertyOverview = () => {
  const dispatch = useDispatch<any>();
  const location: any = useLocation();
  document.title = "Property OverView";

  const [overviewData, setOverviewData] = useState<any>(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const propId = searchParams.get("prop");

    if (propId) {
      if (propId) {
        dispatch(getSingleProperties(propId)).then((resp: any) => {
          setOverviewData(resp.result[0]);
        });
      }
    }
  }, [location.search]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid >
          <Breadcrumb title="Property Overview" pageTitle="Real Estate" />
          <Row>
            <Col xl={9} lg={8}>
              <Overview data={overviewData} />
            </Col>
            <Col xl={3} lg={4}>
              <Row>
                <Saleprice data={overviewData} />
                <PropertyOwner data={overviewData} />
                {/* <AgentDetails /> */}
                {/* <OwnerDetails /> */}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PropertyOverview
