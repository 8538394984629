import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
} from "react-bootstrap";
import Breadcrumb from "Common/BreadCrumb";
import { Document, Page, pdfjs } from "react-pdf";

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface EditableField {
  id: number;
  label: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

function App(): JSX.Element {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [editableFields, setEditableFields] = useState<EditableField[]>([]);

  // Function to handle file upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Render uploaded PDF
    renderPdf(file);
  };
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const iframe: any = iframeRef.current;
    if (iframe) {
      const handleLoad = () => {
        setIsLoading(false);
      };
      iframe.addEventListener("load", handleLoad);
      return () => {
        iframe.removeEventListener("load", handleLoad);
      };
    }
  }, []);

  // Function to render uploaded PDF
  const renderPdf = (file: File): void => {
    const fileReader = new FileReader();
    fileReader.onload = function (event) {
      const buffer = event.target?.result;
      // Call backend API to process the PDF and identify editable fields
      // Here, we'll simulate the backend response by setting some dummy editable fields
      const dummyEditableFields: EditableField[] = [
        { id: 1, label: "Name", x: 100, y: 100, width: 200, height: 20 },
        { id: 2, label: "Email", x: 100, y: 150, width: 200, height: 20 },
        // Add more editable fields as needed
      ];
      setEditableFields(dummyEditableFields);
    };
    fileReader.readAsArrayBuffer(file);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Create Contracts" pageTitle="Contracts" />
          <Row className="justify-content-center">
            {isLoading && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  zIndex: 9999999,
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            <iframe
              ref={iframeRef}
              src="https://uat-tradesmen-fe.herokuapp.com/signin"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                width: "100%",
                height: "100vh",
                border: "none",
                display: isLoading ? "none" : "block",
              }}
            ></iframe>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default App;
