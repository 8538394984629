import React, { useEffect, useState } from "react";
import Breadcrumb from "Common/BreadCrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Button,
  Alert,
} from "react-bootstrap";
import ProjectDetails from "./projectDetails";
import Milestones from "./milestones";
import SubContractors from "./subContractor";
import { jwtDecode } from "jwt-decode";
import Contract from "./contract";
import { Link } from "react-router-dom";
import {
  getSingleProjectById,
  getHomeownerContractsById,
  getSubscribedUser,
} from "../../../../slices/thunk";
import { resetState } from "../../../../slices/contract/reducer";
import NonEscrowMilestones from "./non-escrow-milestones";
import Tutorial from "./tutorial";

interface CreateProjectProps {}
interface JwtPayload {
  userType: string;
}

const CreateProject: React.FC<CreateProjectProps> = () => {
  const contracts = useSelector((state: any) => state.Contract.contracts);
  const dispatch: any = useDispatch();
  const token = localStorage.getItem("authUser");
  let decoded: any = "";
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error("Token is null");
  }

  const navigate = useNavigate();
  const location = useLocation();
  const [contData, setContData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [newUserType, setNewUserType] = useState<any>(false);
  const [projectId, setProjectId] = useState<any>("");
  const [activeArrowTab, setActiveArrowTab] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  let project: any = null;
  project = useSelector((state: any) => state.Contract.project);

  useEffect(() => {
    if (notification && notification.status) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 5000);

      // Cleanup the timeout if the component unmounts or notification changes
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSubscribedUser()).then((resp: any) => {
      if (resp && resp.subscription.cpp_is_active === "ACTIVE") {
        setTermsAccepted(true);
      } else {
        setTermsAccepted(false);
      }
      setLoading(false);
    });
  }, [dispatch]);

  const userSubscribed = useSelector(
    (state: any) => state.UserProfile.subscribedData
  );

  const handleAlertClick = () => {
    setNotification(null);
  };

  useEffect(() => {
    document.title = "Tradesmen Admin";
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authUser");
    let decoded: any = "";

    if (token !== null) {
      try {
        decoded = jwtDecode<JwtPayload>(token);
        if (
          decoded &&
          decoded.userType !== "contractor" &&
          decoded.userType !== "testContractor"
        ) {
          setNewUserType(true);
        } else {
          setNewUserType(false);
        }
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    } else {
      console.error("Token is null");
    }

    if (project && project.p_status === "LIVE") {
      setNewUserType(true);
    }
  }, [project]);

  useEffect(() => {
    if (!location.search) {
      console.log("No search parameters found");
      return;
    }
    const queryString = location.search.substring(1);
    const [mainQueryString, nestedQueryString] = queryString.split("?");
    const mainParams: any = new URLSearchParams(mainQueryString);
    let nestedParams: any = null;
    if (nestedQueryString) {
      nestedParams = new URLSearchParams(nestedQueryString);
    }
    const id = nestedParams ? nestedParams.get("id") : null;
    setProjectId(id);
  }, [location.search]);

  useEffect(() => {
    if (projectId !== "") {
      setLoading(true);
      dispatch(getSingleProjectById(projectId)).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      });
      setNotification({
        status: true,
        message: "Project Fetched Successfully",
        variant: "success",
      });
    } else {
      setLoading(true);
      dispatch(resetState());
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [dispatch, projectId]);

  const handleCreateAndSetTab = () => {
    setActiveArrowTab(2);
  };

  const handleTabChange = (newTab: number, projectId: any) => {
    setActiveArrowTab(newTab);
    const tabName = getTabName(newTab);
    navigate(`${location.pathname}?tab=${tabName}?id=${projectId}`);
  };

  const getTabName = (tabId: number) => {
    switch (tabId) {
      case 1:
        return "Project-Details";
      case 2:
        return "Project-Milestones";
      case 3:
        return "Homeowner-Contract";
      case 4:
        return "Subcontractor";
      case 5:
        return "Finish";
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}

      <div className="page-content">
        <Container fluid>
          {termsAccepted &&
            (decoded.userType === "contractor" ||
              decoded.userType === "testContractor") &&
            (userSubscribed && userSubscribed.remainingDays === 0 ? (
              <Alert variant="success">
                <i className="bx bx-spreadsheet align-baseline me-1"></i>
                Your Subscription is live. Thank you for choosing The Tradesmens
                Collective.{" "}
                <Link to="/contract-tutorial-and-plans">
                  Know More or Cancel Plan
                </Link>
              </Alert>
            ) : (
              <Alert variant="danger">
                <i className="bx bx-spreadsheet align-baseline me-1"></i>
                You have a complimentary <b>30-day trial period</b> to
                experience this contract service at no cost. Only{" "}
                <b>
                  {userSubscribed && userSubscribed.remainingDays} days left
                </b>
                .{" "}
                <Link to="/contract-tutorial-and-plans">
                  Know More or Cancel Plan
                </Link>
              </Alert>
            ))}
          <Breadcrumb title="Create Contracts" pageTitle="Contact" />
          <Row>
            <Col xl={12}>
              <Form>
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Card>
                    <Card.Body className="form-steps">
                      <Form action="#">
                        <Tab.Container activeKey={activeArrowTab}>
                          <div className="step-arrow-nav mb-4">
                            <Nav
                              as="ul"
                              variant="pills"
                              className="custom-nav nav-justified"
                            >
                              <Nav.Item as="li">
                                <Nav.Link
                                  as="button"
                                  type="button"
                                  eventKey="1"
                                  onClick={() => setActiveArrowTab(1)}
                                >
                                  Project details
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <Nav.Link
                                  as="button"
                                  type="button"
                                  eventKey="2"
                                  onClick={() => setActiveArrowTab(2)}
                                >
                                  Project Milestones
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item as="li">
                                <Nav.Link
                                  as="button"
                                  type="button"
                                  eventKey="3"
                                  onClick={() => setActiveArrowTab(3)}
                                >
                                  Homeowner Contract
                                </Nav.Link>
                              </Nav.Item>

                              <Nav.Item as="li">
                                <Nav.Link
                                  as="button"
                                  type="button"
                                  eventKey="4"
                                  onClick={() => setActiveArrowTab(4)}
                                >
                                  Subcontractor
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <Nav.Link
                                  as="button"
                                  type="button"
                                  eventKey="5"
                                  onClick={() => setActiveArrowTab(5)}
                                >
                                  Finish
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="1">
                              <ProjectDetails
                                projectDetails={contData}
                                onCreateProject={handleTabChange}
                                disable={newUserType}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                              {project &&
                              project.p_escrow != null &&
                              project.p_escrow == 0 ? (
                                <NonEscrowMilestones
                                  milestonesData={contData.milestones}
                                  onCreateProject={handleTabChange}
                                  disable={newUserType}
                                />
                              ) : (
                                <Milestones
                                  milestonesData={contData.milestones}
                                  onCreateProject={handleTabChange}
                                  disable={newUserType}
                                />
                              )}
                            </Tab.Pane>

                            <Tab.Pane eventKey="3">
                              <Contract
                                projectDetails={contData}
                                disable={newUserType}
                              />
                              <div className="d-flex align-items-start gap-3 mt-4">
                                <Button
                                  variant="light"
                                  className="btn-label previestab"
                                  onClick={() => setActiveArrowTab(2)}
                                >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-lg me-2"></i>{" "}
                                  Back to Milestones
                                </Button>
                                <Button
                                  variant="success"
                                  className="btn-label right ms-auto nexttab"
                                  onClick={() => setActiveArrowTab(4)}
                                >
                                  <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>{" "}
                                  Create Subcontractor
                                </Button>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="4">
                              <SubContractors
                                projectDetails={contData}
                                disable={newUserType}
                              />
                              <div className="d-flex align-items-start gap-3 mt-4">
                                <Button
                                  variant="light"
                                  className="btn-label previestab"
                                  onClick={() => setActiveArrowTab(3)}
                                >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-lg me-2"></i>{" "}
                                  Back to Homeowner Contract
                                </Button>
                                <Button
                                  variant="success"
                                  className="btn-label right ms-auto nexttab"
                                  onClick={() => setActiveArrowTab(5)}
                                >
                                  <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>{" "}
                                  Submit
                                </Button>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="5">
                              <div className="text-center">
                                <div className="avatar-md mt-5 mb-4 mx-auto">
                                  <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                    <i className="ri-checkbox-circle-fill"></i>
                                  </div>
                                </div>
                                <h5>Status</h5>
                                <p className="text-muted">
                                  {project &&
                                  project.contract &&
                                  project.contract.pc_contractor_signature_url
                                    ? "Contractor Successfully signed contract"
                                    : "Sign Pending"}
                                </p>
                                <p>
                                  {project &&
                                  project.contract &&
                                  project.contract.pc_homeowner_signature_url
                                    ? "Homeowner Successfully signed contract"
                                    : "Homeowner Sign Pending"}
                                </p>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Form>
                    </Card.Body>
                  </Card>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProject;
