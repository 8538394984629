import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Button,
  Modal,
  Spinner,
  Alert,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { DeleteModal } from "Common/DeleteModal";
import AgentInfo from "./AgentInfo";
import { PatternFormat } from "react-number-format";
import {
  getAgentGridList as onGetAgentGridList,
  addAgentGridList as addAgentGridListApi,
  deleteAgentGridList as onDeleteAgentGridList,
} from "slices/realestate/thunk";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createContSubcontractors,
  getSubcontractors,
} from "../../slices/thunk";

const SubContractorGrid = () => {
  document.title = "Subcontractors";

  const dispatch = useDispatch<any>();

  const selectSubcontractorgridList = createSelector(
    (state: any) => state.Subcontractor.subcontractors,
    (subcontractors) => subcontractors
  );

  const { subcontractors } = useSelector((state: any) => ({
    subcontractors: selectSubcontractorgridList(state),
  }));

  useEffect(() => {
    dispatch(getSubcontractors());
  }, [dispatch]);

  const [gridView, setGridView] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [agentGrid, setAgentGrid] = useState<any>(null);
  const [addagent, setAddagent] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [editagent, setEditagent] = useState<boolean>(false);
  const [selectfeils, setSelectfeils] = useState<any>([]);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  //add agent modal
  const handleShowAgent = () => setAddagent(true);
  const handleCloseAgent = () => {
    setAddagent(false);
    setSelectfeils(null);
  };

  //search
  const handlesearch = (ele: any) => {
    let search = ele.target.value;
    if (search) {
      setGridView(
        subcontractors?.filter((data: any) =>
          data.user_first_name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setGridView(subcontractors);
    }
  };

  const handleselect = (ele: any) => {
    setGridView(
      subcontractors?.filter(
        (select: any) => select.sc_is_active === ele || ele === "All"
      )
    );
  };

  const handleDeleteShow = (ele: any) => {
    setShow(true);
    setAgentGrid(ele);
  };
  const handleDeleteClose = () => setShow(false);

  //delete modal
  const deleteModalFunction = () => {
    if (agentGrid.id) {
      dispatch(onDeleteAgentGridList(agentGrid.id));
    }
    setShow(false);
  };

  useEffect(() => {
    setGridView(subcontractors);
  }, [subcontractors]);

  const handleAcceptfiles = (files: any) => {
    const newImages = files?.map((file: any) => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      });
    });
    setSelectfeils([...selectfeils, ...newImages]);
  };

  const formik: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (agentGrid && agentGrid.po_id) || "",
      firstName: (agentGrid && agentGrid.user_first_name) || "",
      lastName: (agentGrid && agentGrid.user_last_name) || "",
      email: (agentGrid && agentGrid.po_user_email) || "",
      contact: (agentGrid && agentGrid.po_user_phone) || "",
      status: (agentGrid && agentGrid.sc_is_active) || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your Subcontractor Name"),
      lastName: Yup.string().required(
        "Please Enter Your Subcontractor Last Name"
      ),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Subcontractor Email"),
      contact: Yup.string().required("Please Enter Your Subcontractor contact"),
      status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: (values: any) => {
      setLoading(true);
      if (editagent) {
        dispatch(createContSubcontractors(values));
        dispatch(getSubcontractors());
        setLoading(false);
        setNotification({
          status: true,
          message: "Subcontractor Updated Successfully",
          variant: "success",
        });
        formik.resetForm();
      } else {
        setLoading(true);
        dispatch(createContSubcontractors(values));
        dispatch(getSubcontractors());
        setLoading(false);
        setNotification({
          status: true,
          message: "Subcontractor Created Successfully",
          variant: "success",
        });
        dispatch(getSubcontractors());
        formik.resetForm();
      }

      if (values === null) {
        handleShowAgent();
      } else {
        handleCloseAgent();
      }
    },
  });

  const handleAlertClick = () => {
    setNotification(null);
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Subcontractor" pageTitle="Subcontractor" />
            <Row>
              <Col lg={12}>
                <Card>
                  <Card.Body>
                    <Row className="align-items-center gy-3">
                      <Col
                        lg={3}
                        md={6}
                        className="order-last order-md-first me-auto"
                      >
                        <div className="search-box">
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            id="searchResultList"
                            placeholder="Search for property owner ..."
                            onChange={(e: any) => handlesearch(e)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col xl={2} lg={3} sx={12}>
                        <div>
                          <Form.Select
                            id="status-input"
                            onClick={(e: any) => handleselect(e.target.value)}
                          >
                            <option value="All">Select status</option>
                            <option value="Active">Active</option>
                            <option value="Unactive">Unactive</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col className="col-auto text-end">
                        <Button variant="secondary" onClick={handleShowAgent}>
                          <i className="bi bi-person-plus align-baseline me-1"></i>{" "}
                          Add Subcontractor
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <AgentInfo
              data={gridView}
              rowclassname="row-cols-xxl-5 row-cols-md-2 row-cols-1"
              perPageData={10}
              handleDeleteShow={handleDeleteShow}
            />
          </Container>
        </div>
      )}
      <Modal show={addagent} onHide={handleCloseAgent}>
        <Modal.Header closeButton>
          <Modal.Title as="h5" id="addAgentModalLabel">
            {editagent ? "Edit Property Owner" : "Add Property Owner"}
          </Modal.Title>
        </Modal.Header>
        <Form
          className="tablelist-form"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <div className="mb-3">
              <Form.Label htmlFor="agent-name-input">
                Subcontractor First Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Enter Subcontractor First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="agent-name-input">
                Subcontractor Last Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Enter Subcontractor Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="email-input">
                Email<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.email}
              />
              {formik.errors.email && formik.touched.email ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="contact-input">
                Contact Number<span className="text-danger">*</span>
              </Form.Label>
              <PatternFormat
                className="form-control"
                displayType="input"
                id="contact"
                placeholder="Enter contact no"
                name="contact"
                format="##########"
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {formik.errors.contact && formik.touched.contact ? (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {formik.errors.contact}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="status-type-input">
                Status<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                className="form-control"
                id="status-type-input"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.status}
              >
                <option value="">select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
              {formik.errors.status && formik.touched.status ? (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.status}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btn btn-ghost-danger"
              onClick={handleCloseAgent}
            >
              <i className="bi bi-x-lg align-baseline me-1"></i> Close{" "}
            </Button>
            <Button type="submit" variant="primary" id="add-btn">
              {editagent ? "Edit" : "Add"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <DeleteModal
        show={show}
        handleClose={handleDeleteClose}
        deleteModalFunction={deleteModalFunction}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default SubContractorGrid;
