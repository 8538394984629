import React, { useState } from "react";
import { Col, Row, Accordion, Image, Modal, Button } from "react-bootstrap";

interface recentWorkListProps {
  data: any;
  handleDelete: (id: any) => void;
}

const GridRecentWork: React.FC<recentWorkListProps> = ({
  data,
  handleDelete,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>("");

  const openModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };
  return (
    <div className="col-lg">
      <Row>
        <Accordion defaultActiveKey="0">
          {data &&
            data.length > 0 &&
            data.map((item: any, index: number) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header>{item.rw_name}</Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-3">
                    {item.rw_images &&
                      JSON.parse(item.rw_images).map(
                        (image: string, imgIndex: number) => (
                          <Col key={imgIndex} md={3} className="mb-3">
                            <div className="thumbnail-container">
                              <Image
                                className="thumbnail-image"
                                src={image}
                                alt={`Thumbnail ${imgIndex}`}
                                onClick={() => openModal(image)}
                              />
                            </div>
                          </Col>
                        )
                      )}
                  </Row>
                  <Row className="mb-3">
                    <Col md={12}>
                      <p className="text-muted mb-2">{item.rw_description}</p>
                      <div className="text-muted hstack gap-2 flex-wrap list-unstyled mb-3">
                        <div>
                          <i className="bi bi-geo-alt align-baseline me-1"></i>{" "}
                          <b>Started At:</b> {item.rw_started_at}
                        </div>
                        <div className="vr"></div>
                        <div>
                          <i className="bi bi-calendar-event align-baseline me-1"></i>{" "}
                          <b>Completed At:</b> {item.rw_completed_at}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(item.rw_id)}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </Row>

      {/* Modal for displaying larger images */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body className="text-center">
          <Image
            src={selectedImage}
            alt="Selected Image"
            className="img-fluid"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GridRecentWork;
