import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

interface FloridaProps {
  onExtraData: (data: object) => void;
}

const Florida: React.FC<FloridaProps> = ({ onExtraData }) => {
  const [extraData, setExtraData] = useState({});

  const onchangeExtraData = (label: string, value: string) => {
    const newData = { ...extraData, [label]: value };
    setExtraData(newData);
    onExtraData(newData); // Call the parent function with the updated data
  };

  return (
    <Container>
      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Does this contract include work on the principal residence of the
            owner? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="principal-residence"
            className="mb-2"
          />
          <Form.Check type="radio" aria-label="radio 1" label="No" name="principal-residence" />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Does the Owner give up the 3-day right to rescind because there is a
            true emergency? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            value="yes"
            label="Yes. This contract will include a form to be completed in handwriting by Owner."
            name="3Day-right"
            className="mb-2"
            onChange={(e) => onchangeExtraData("3Day-right", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            value="no"
            label="No. Contractor will delay the start of work until three business days have passed."
            name="3Day-right"
            onChange={(e) => onchangeExtraData("3Day-right", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>Will work be done in Miami-Dade County? *</h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="miami-dade-county"
            className="mb-2"
            onChange={(e) => onchangeExtraData("miami-dade-county", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="miami-dade-county"
            onChange={(e) => onchangeExtraData("miami-dade-county", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>Please confirm if the value of the job exceeds $5000? *</h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="job-exceeds-5000"
            className="mb-2"
            onChange={(e) => onchangeExtraData("job-exceeds-5000", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="job-exceeds-5000"
            onChange={(e) => onchangeExtraData("job-exceeds-5000", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <h5 className="mb-4">
          Florida Home Solicitation Sale – Right of Owner to Cancel
        </h5>
        <h5 className="mb-4">
          Answer the following questions to determine if the Florida Home
          Solicitation Sale Act applies:
        </h5>

        <Row>
          <Col>
            <h5>Was this sale made at the place of business of contractor? *</h5>
            <p className="mb-2">Choose one option.</p>
            <Form.Check
              type="radio"
              aria-label="radio 1"
              label="Yes"
              name="place-of-business"
              className="mb-2"
              onChange={(e) => onchangeExtraData("place-of-business", e.target.value)}
            />
            <Form.Check
              type="radio"
              aria-label="radio 1"
              label="No"
              name="place-of-business"
              onChange={(e) => onchangeExtraData("place-of-business", e.target.value)}
            />
          </Col>
        </Row>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>Is the contract amount greater than $2500? *</h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="contract-amount-greater"
            className="mb-2"
            onChange={(e) => onchangeExtraData("contract-amount-greater", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="contract-amount-greater"
            onChange={(e) => onchangeExtraData("contract-amount-greater", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Is this a contract for new construction, repair or improvement of a
            home with four living units or less? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="contract-for-new-construction"
            className="mb-2"
            onChange={(e) => onchangeExtraData("contract-for-new-construction", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="contract-for-new-construction"
            onChange={(e) => onchangeExtraData("contract-for-new-construction", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Is this a contract for repair, restoration or improvement of a
            residence? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="contract-for-repair"
            className="mb-2"
            onChange={(e) => onchangeExtraData("contract-for-repair", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="contract-for-repair"
            onChange={(e) => onchangeExtraData("contract-for-repair", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Should this contract waive Florida’s Notice and Right to Cure Act? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="contract-waive-florida-notice"
            className="mb-2"
            onChange={(e) => onchangeExtraData("contract-waive-florida-notice", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="contract-waive-florida-notice"
            onChange={(e) => onchangeExtraData("contract-waive-florida-notice", e.target.value)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Florida;
