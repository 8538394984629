import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Button,
  Table,
  Modal,
  Alert
} from "react-bootstrap";

interface BannerTutorialProps {
    banner: any,
    title: String,
    subTitle: String,
    titleColor: any,
    subTitleColor:any
    
}

interface JwtPayload {
  userType: string;
}

const BannerTutorial: React.FC<BannerTutorialProps> = ({ banner, title, subTitle, titleColor, subTitleColor }) => {
  return (
      <React.Fragment>
          <div className="row mb-3">
              <div className="container-fluid">
                  <div className="img-bg" style={{ backgroundImage: `url(${banner})`, height: '220px', borderBottomLeftRadius: '80px', borderBottomRightRadius: '80px' }}>
                      <div className="" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '220px'}}>
                          <h4 className="h4" style={{color:titleColor}}>{title}</h4>
                           <h1 className="h1" style={{color:subTitleColor}}>{subTitle}</h1>
                      </div>

                  </div>
                  
              </div>
          </div>
       
    </React.Fragment>
  );
};

export default BannerTutorial;
