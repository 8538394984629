import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userProfileStatus: null,
  userData: null,
  subscribedData: null,
  categories:[],
  user: [],
  recentWorks:[],
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    userProfile(state, action) {
      state.userProfileStatus = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    userdataSuccess(state, action) {
      state.userData = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    userSuccess(state, action) {
      state.user = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    userSubscribedSuccess(state, action) {
      state.subscribedData = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
     allCategories(state, action) {
      state.categories = action.payload.result
      state.loading = false;
      state.errorMsg = false;
    },
    recentWork(state, action) {
      state.recentWorks = action.payload.result
      state.loading = false;
      state.errorMsg = false;
    }, 
  },
});

export const {
  apiError,
  userProfile,
  userdataSuccess,
  userSuccess,
  userSubscribedSuccess,
  allCategories,
  recentWork
} = userProfileSlice.actions

export default userProfileSlice.reducer;