import React, { useEffect, useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import HurryUp from "../../../assets/images/tutorial/hurry-up.png";

interface FeaturesProps {
  banners: any;
}

interface JwtPayload {
  userType: string;
}

const OfferSlider: React.FC<FeaturesProps> = ({ banners }) => {
  const hurryUpStyle: React.CSSProperties = {
    position: "absolute",
    right: "-52px", // Adjust as needed to move it further right
    top: "12%", // Adjust as needed to move it up/down
    zIndex: 10,
  };

  const carouselItemStyle: React.CSSProperties = {
    position: "relative",
    overflow: "visible", // Allow overflow to show the HurryUp image outside the banner
  };

  const carouselContainerStyle: React.CSSProperties = {
    position: "relative", // Ensure the parent container allows positioning of children
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="container d-flex justify-content-center">
          <div className="col-10">
            <div style={carouselContainerStyle}>
              <Carousel fade={true} controls={false}>
                {banners.length &&
                  banners.map((item: any, index: number) => (
                    <Carousel.Item key={index} style={carouselItemStyle}>
                      <img style={hurryUpStyle} src={HurryUp} alt="Hurry Up" />
                      <Image
                        className="d-block w-100"
                        src={item.banner}
                        alt={item.alt}
                      />
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OfferSlider;
