import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  projectId: "",
  contracts: [],
  project: null,
  milstoneSuccess: [],
  hicSuccess: [],
  subContractSuccess: [],
  subContractorSuccess:[],
  changeOrder:[],
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload; // Assign error message directly
      state.loading = false; // Set loading to false
      state.errorMsg = true; // Set error message flag to true
    },
    apiSuccess(state, action) {
      state.projectId = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    contractSuccess(state, action) {
      state.contracts = action.payload.result ? action.payload.result : action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    mileStoneSuccess(state, action) {
      state.milstoneSuccess = action.payload.result;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    hicSuccess(state, action) {
      state.hicSuccess = action.payload.result;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
     subContractSuccess(state, action) {
      state.subContractSuccess = action.payload.result;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
     subContractorSuccess(state, action) {
      state.subContractorSuccess = action.payload.result;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    
    changeOrderSuccess(state, action) {
      state.changeOrder = action.payload.result;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    projectSuccess(state, action) {
      state.project = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    resetState(state) {
      console.log("ResetState reducer called");
      state.project = null
    }
  },
});

export const {
  apiError,
  apiSuccess,
  contractSuccess,
  mileStoneSuccess,
  hicSuccess,
  subContractorSuccess,
  projectSuccess,
  resetState,
  changeOrderSuccess,
  subContractSuccess
} = projectSlice.actions;

export default projectSlice.reducer;
