import React, { useState} from 'react'
import { Card } from 'react-bootstrap'
import { jwtDecode } from "jwt-decode";


interface JwtPayload {
    userType: string;
}
const CourseIncludes = () => {
    const token = localStorage.getItem('authUser');
  const [loading, setLoading] = useState(false);
  let decoded: any = "";

  
  if (token !== null) {
    decoded = jwtDecode<JwtPayload>(token);
  } else {
    console.error('Token is null');
  }
    return (
        <React.Fragment>
            <Card>
                <Card.Header>
                    <h6 className="card-title mb-0">{ decoded.userType === 'contractor' ? 'Homeowner' : 'Contractor'}</h6>
                </Card.Header>
                <Card.Body>
                    <ul className="list-unstyled vstack gap-2 mb-0">
                        {/* <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <i className="ph ph-book-open align-middle me-1 text-primary-emphasis"></i> Contacted
                                </div>
                                <div className="flex-shrink-0 text-muted">
                                    5
                                </div>
                            </div>
                        </li> */}
                        <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <i className="ph ph-timer align-middle me-1 text-primary-emphasis"></i> { decoded.userType === 'contractor' ? 'Jobs' : 'Completed Jobs'}
                                </div>
                                <div className="flex-shrink-0 text-muted">
                                    { decoded.userType === 'contractor' ? '10' : '5'}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <i className="ph ph-chart-bar align-middle me-1 text-primary-emphasis"></i> { decoded.userType === 'contractor' ? 'Paid' : 'Earned'}
                                </div>
                                <div className="flex-shrink-0 text-muted">
                                    { decoded.userType === 'contractor' ? '$20000' : '$20000'}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <i className="ph ph-book-open align-middle me-1 text-primary-emphasis"></i> Language
                                </div>
                                <div className="flex-shrink-0 text-muted">
                                    English
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <i className="bi bi-card-heading align-baseline me-1 text-primary-emphasis"></i> { decoded.userType === 'contractor' ? 'Ontime Paymets' : 'Ontime Completion'}
                                </div>
                                <div className="flex-shrink-0 text-muted">
                                    95%
                                </div>
                            </div>
                        </li>
                    </ul>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

export default CourseIncludes
