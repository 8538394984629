import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Badge,
  Card,
  Button,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GridRecentWork from "./recentWorkList";
import BreadCrumb from "Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import AddRecentWork from "./AddRecentWork";
import { getRecentWork, deleteRecentWork } from "../../../../../slices/thunk";

interface RecentWorkListProps {
  recentWork: any;
}
const RecentWorkList: React.FC<RecentWorkListProps> = ({ recentWork }) => {
  const dispatch = useDispatch<any>();
  const [listGrid, setListGrid] = useState<any>([]);
  const [addRecentWork, setAddRecentWork] = useState<boolean>(false);
  const [selectfeils, setSelectfeils] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [notification, setNotification] = useState<{
    status: boolean;
    message: string;
    variant: "success" | "danger" | "warning" | "info";
  } | null>(null);

  //Add Recent Work modal
  const handleShowRecentWork = () => setAddRecentWork(true);
  const handleCloseRecentWork = () => setAddRecentWork(false);

  useEffect(() => {
    setListGrid(recentWork.recent_works);
  }, [dispatch]);

  const handleAcceptfiles = (files: any) => {
    const newImages = files?.map((file: any) => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      });
    });
    setSelectfeils([...selectfeils, ...newImages]);
  };

  const handleRecentSuccess = (successStatus: any) => {
    if (successStatus) {
      dispatch(getRecentWork()).then((resp: any) => {
        setListGrid(resp.result);
        setAddRecentWork(false);
      });
    }
  };

  const handleDeleteRecentWork = async (id: any) => {
    setLoading(true);
    try {
      await dispatch(deleteRecentWork(id));
      const resp = await dispatch(getRecentWork());
      setListGrid(resp.result);
      setLoading(false);
      setNotification({
        status: true,
        message: "Recent Work Deleted Successfully",
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting recent work:", error);
      // Handle error state or logging as needed
    }
  };
  const handleAlertClick = () => {
    setNotification(null);
  };

  return (
    <React.Fragment>
      {notification && notification.status && (
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "24px",
            zIndex: 99999,
          }}
        >
          <Alert variant={notification.variant} onClick={handleAlertClick}>
            {notification.message}
          </Alert>
        </div>
      )}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Row>
          <div className="col-lg">
            <Card>
              <Card.Body>
                <Row className="align-items-center gy-3">
                  <Col lg={3}>
                    <Card.Title as="h6" className="mb-0">
                      Recent Work{" "}
                      {/* <Badge bg="secondary-subtle" text="secondary" className="align-baseline ms-1">3</Badge> */}
                    </Card.Title>
                  </Col>
                  <div className="col-sm-auto ms-auto d-flex gap-1">
                    <Button variant="secondary" onClick={handleShowRecentWork}>
                      <i className="bi bi-house align-baseline me-1"></i> Add
                      Recent Work
                    </Button>
                  </div>
                </Row>
              </Card.Body>
            </Card>
            <GridRecentWork
              data={listGrid}
              handleDelete={handleDeleteRecentWork}
            />
          </div>
        </Row>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default RecentWorkList;
