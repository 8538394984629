import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  contractors: [],
  subscribersData: [],
    formData:[],
  contractorsCount: "",
  singleContractor: {},
  contracorApproveMessage: "",
   error: "", 
  loading: false,
  isUserLogout: false,
  errorMsg: false, 
};

const adminSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload; // Assign error message directly
      state.loading = false; // Set loading to false
      state.errorMsg = true; // Set error message flag to true
    },
    apiSuccess(state, action) {
      state.contractors = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    contractorCountSuccess(state, action) {
      state.contractorsCount = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    singleContractSuccess(state, action) {
      state.singleContractor = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    contractApproveSuccess(state, action) { 
       state.contracorApproveMessage = action.payload.result;
      state.loading = false; // Set loading to false
      state.errorMsg = false; 
    },
     subscribersSuccess(state, action) { 
       state.subscribersData = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; 
     },
      FormSuccess(state, action) { 
       state.formData = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; 
    }
    
  },
});

export const {
  apiError,
  apiSuccess,
  singleContractSuccess,
  contractApproveSuccess,
  contractorCountSuccess,
  subscribersSuccess,
  FormSuccess
} = adminSlice.actions;

export default adminSlice.reducer;