import React from 'react'
import { Container, Row } from 'react-bootstrap'
import SideProfile from './side'
import BreadCrumb from 'Common/BreadCrumb'
import Overview from './overview'
import { Link, useLocation } from "react-router-dom";

interface Profile {
  data: any,
}


const Profile = () => {
  const location = useLocation();
  const profileData = location.state.data;
  //console.log(profileData, "profileData")

  document.title=" Profile | Steex - Admin & Dashboard Template"

  return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title='Profile' pageTitle='Pages' />
                <Row>
                    <SideProfile data={profileData} />
                    <Overview data={profileData} />
                </Row>
            </Container>
        </div>
    </React.Fragment>
  );
};

export default Profile