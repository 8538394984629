import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  payment: "",
  error: "", // for error message
  paymentStatus: null,
  transactions:null,
  package: "",
   card:[],
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload; // Assign error message directly
      state.loading = false; // Set loading to false
      state.errorMsg = true; // Set error message flag to true
    },
    apiSuccess(state, action) {
      state.payment = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    paymentStatus(state, action) {
      state.paymentStatus = action.payload;
      state.loading = false; // Set loading to false
      state.errorMsg = false; // Set error message flag to false
    },
    userPackage(state, action) { 
      state.package = action.payload;
      state.loading = false;
      state.errorMsg = false
    },
    userCards(state, action) { 
      state.card = action.payload;
      state.loading = false;
      state.errorMsg = false
    },
    userTransactions(state, action) { 
      state.transactions = action.payload;
      state.loading = false;
      state.errorMsg = false
    }


  },
});

export const {
  apiError,
  apiSuccess,
  paymentStatus,
  userPackage,
  userCards,
  userTransactions
} = paymentSlice.actions;

export default paymentSlice.reducer;