import { Navigate } from "react-router-dom";

// Authentication
import Signin from "pages/AuthenticationInner/Signin";
import SignUp from "pages/AuthenticationInner/Signup";
import PasswordReset from "pages/AuthenticationInner/PasswordReset";
import PasswordCreate from "pages/AuthenticationInner/PasswordCreate";
import LockScreen from "pages/AuthenticationInner/LockScreen";
import LogOut from "pages/AuthenticationInner/LogOut";
import SuccessMsg from "pages/AuthenticationInner/SuccessMsg";
import Twostep from "pages/AuthenticationInner/Twostep";
import Fourzerofour from "pages/AuthenticationInner/Errors/404Error";
import Fivezerozero from "pages/AuthenticationInner/Errors/500Error";
import Fivezerothree from "pages/AuthenticationInner/Errors/503Error";
import OfflinePage from "pages/AuthenticationInner/Errors/Offline";
import Credentials from "pages/Credentials/index";

// Pages
import Starterpages from "pages/Pages/Starter/Starterpage";

// pages

import Maintenance from "pages/Pages/Maintenance/Maintenance";
import ComingSoon from "pages/Pages/Comingsoon/ComingSoon";
import TermsContions from "pages/Pages/Terms&Condition/TermsContions";
import PrivacyPolicy from "pages/Pages/Privacypolicy/PrivacyPolicy";
import TimeLine from "pages/Pages/TimeLine/TimeLine";
import Faqs from "pages/Pages/FAQs/Faqs";
import Contacts from "pages/Pages/Contact/Contacts";
import Contractors from "pages/Pages/Contact/contractors";
import Pricing from "pages/Pages/Pricing/index";
import Profile from "pages/Pages/Profile/index";
import ContracotrProfile from "pages/Pages/ContractorProfile/index";

import ProfileSetting from "pages/Pages/Profilesetting/index";
import Projects from "pages/Projects";
import Questions from "pages/Projects/questions";
//import ProjectOverview from "pages/Projects/ProjectOverview/PropertyOverview";
import ProjectOverview from "pages/Projects/LeadsOverview/Overview";
import RequestedLeads from "pages/Requested Leads";
//import ProposalOverview from "pages/Projects/ProposalDetails/ProposalOverview";
// Tables

//Invoice
import OverviewInvoice from "pages/Invoices/OverviewInvoice/OverviewInvoice";
import CreateInvoice from "pages/Invoices/CreateInvoice/CreateInvoice";

//Contracts
import OverviewContracts from "pages/Contracts/OverviewInvoice/OverviewInvoice";
import CreateContracts from "pages/Contracts/CreateInvoice/CreateInvoice";

//Support Tickets
import TicketsOverview from "pages/SupportTickets/Overview/Index";

//Real Estate
import PropertyOverview from "pages/RealEstate/PropertyOverview/PropertyOverview";
import AddEarning from "pages/RealEstate/AddProperty/AddEarning";

// import Login from "pages/Authentication/Login";

// import Login from "pages/Authentication/Login";

import Analytics from "pages/Dashboard/Analytics";
import Crm from "pages/Dashboard/Crm";
import Ecommerce from "pages/Dashboard/Ecommerce";
import Learning from "pages/Dashboard/Learning";
import RealEstate from "pages/Dashboard/RealEstate";
import Calendar from "pages/Calendar";
import ListingGrid from "pages/RealEstate/PropertyListingGrid/listingGrid";
import AgentGrid from "pages/RealEstate/PropertyOwners/AgentGrid";
import ContractTutorial from "pages/Tutorial";
import MarketingComponent from "pages/Marketing";
import SubcontractorsGrid from "pages/Subcontractors/SubcontractorGrid";
import OverviewUser from "pages/Projects/user-overview/OverviewUser";
import ListViewInvoice from "pages/Invoices/ListView/ListViewInvoice";
import ListViewContracts from "pages/Contracts/ListView/ListViewInvoice";
import ListViewTickets from "pages/SupportTickets/ListView/ListViewTickets";
import TransactionData from "pages/Transactions/index";

// Authentication
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import UserProfile from "pages/Authentication/user-profile";
import GetProject from "pages/Contracts/CreateProject/index";
import CreateContract from "pages/Contracts/CreateProject/tabs";
import CreateSwornContractor from "pages/Contracts/SwornContract/contractor";
import CreateSwornHomeowner from "pages/Contracts/SwornContract/homeowner";
import { components } from "react-select";

//admin
import AdminContractorProfile from "pages/Admin/contractors/Profilesetting";
import AdminHomeownerProfile from "pages/Admin/Homeowners/Profilesetting";
import AdminContratorsData from "pages/Admin/contractors";
import AdminHomeownersData from "pages/Admin/Homeowners";
import AdminRealEstateAgentsData from "pages/Admin/Real-estate-agents";
import AdminsData from "pages/Admin/Admins";

import AdminFormsData from "pages/Admin/Form Leads";
import AdminSubscribersData from "pages/Admin/subscribers";

const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: <RealEstate /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  {
    path: "/invoices-list",
    name: "ListViewInvoice",
    component: <ListViewInvoice />,
  },
  {
    path: "/apps-invoices-overview",
    name: "OverviewInvoice",
    component: <OverviewInvoice />,
  },
  {
    path: "/apps-invoices-create",
    name: "CreateInvoice",
    component: <CreateInvoice />,
  },

  {
    path: "/contracts-list",
    name: "ListViewContracts",
    component: <ListViewContracts />,
  },
  {
    path: "/apps-contracts-overview",
    name: "OverviewContracts",
    component: <OverviewContracts />,
  },
  {
    path: "/apps-contracts-create",
    name: "CreateContracts",
    component: <CreateContracts />,
  },

  {
    path: "/apps-tickets-overview",
    name: "TicketsOverview",
    component: <TicketsOverview />,
  },
  {
    path: "/apps-tickets-list",
    name: "ListViewTickets",
    component: <ListViewTickets />,
  },

  {
    path: "/apps-real-estate-grid",
    name: "ListingGrid",
    component: <ListingGrid />,
  },

  {
    path: "/apps-real-estate-agent-grid-view",
    name: "AgentGrid",
    component: <AgentGrid />,
  },
  {
    path: "/contract-tutorial-and-plans",
    name: "Contract Tutorial",
    component: <ContractTutorial />,
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: <MarketingComponent />,
  },
  {
    path: "/create-your-team",
    name: "SubcontractorsGrid",
    component: <SubcontractorsGrid />,
  },

  {
    path: "/apps-real-estate-property-overview",
    name: "PropertyOverview",
    component: <PropertyOverview />,
  },
  {
    path: "/apps-real-estate-add-properties",
    name: "AddEarning",
    component: <AddEarning />,
  },

  //user prpfile
  { path: "/user-profile", name: "UserProfile", component: <UserProfile /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    name: "Navigate",
    component: <Navigate to="/dashboard" />,
  },

  // Pages
  // Starter
  { path: "/pages-starter", name: "Starterpages", component: <Starterpages /> },

  // Terms & Condition
  {
    path: "/pages-privacy-policy",
    name: "PrivacyPolicy",
    component: <PrivacyPolicy />,
  },
  // Terms & Condition
  {
    path: "/pages-term-conditions",
    name: "TermsContions",
    component: <TermsContions />,
  },

  // Timeline
  { path: "/pages-timeline", name: "TimeLine", component: <TimeLine /> },

  // FAQs
  { path: "/pages-faqs", name: "Faqs", component: <Faqs /> },

  // Contacts
  { path: "/pages-contacts", name: "Contacts", component: <Contacts /> },
  {
    path: "/pages-contractors",
    name: "Contractors",
    component: <Contractors />,
  },

  { path: "/pages-crm", name: "Crm", component: <Crm /> },
  {
    path: "/pages-credentials",
    name: "Credentials",
    component: <Credentials />,
  },

  // Pricing
  { path: "/pages-pricing", name: "Pricing", component: <Pricing /> },

  // Profile
  { path: "/pages-profile", name: "Profile", component: <Profile /> },
  {
    path: "/pages-contracor-profile",
    name: "Contracotr Profile",
    component: <ContracotrProfile />,
  },

  //Projects
  { path: "/pages-projects", name: "Projects", component: <Projects /> },
  {
    path: "/pages-user-overview",
    name: "Overview User",
    component: <OverviewUser />,
  },
  { path: "/get-contracts", name: "Projects", component: <GetProject /> },
  {
    path: "/create-contract",
    name: "Create Contract",
    component: <CreateContract />,
  },

  {
    path: "/create-sworn-contracts",
    name: "Create Sworn Contracts",
    component: <CreateSwornContractor />,
  },
  {
    path: "/create-sworn-homeowner",
    name: "Create Sworn Homeowner",
    component: <CreateSwornHomeowner />,
  },
  { path: "/project-questions", name: "Questions", component: <Questions /> },
  {
    path: "/project-overview",
    name: "Project Overview",
    component: <ProjectOverview />,
  },
  {
    path: "/project-requested",
    name: "Requested Leads",
    component: <RequestedLeads />,
  },

  //{ path: "/proposal-overview", name : "Proposal Overview" ,component: <ProposalOverview /> },

  //admin
  {
    path: "/pages-all-contractors",
    name: "All Contractors",
    component: <AdminContratorsData />,
  },
  {
    path: "/pages-all-homeowners",
    name: "All Homeowners",
    component: <AdminHomeownersData />,
  },
  {
    path: "/pages-single-homeowner",
    name: "Homeowner",
    component: <AdminHomeownerProfile />,
  },
  {
    path: "/pages-all-real-estate-agents",
    name: "All Real Estate Agents",
    component: <AdminRealEstateAgentsData />,
  },
  {
    path: "/pages-all-admins",
    name: "All Admins",
    component: <AdminsData />,
  },
  {
    path: "/pages-single-contractors",
    name: "Contractor",
    component: <AdminContractorProfile />,
  },
  {
    path: "/pages-all-forms",
    name: "Forms Data",
    component: <AdminFormsData />,
  },
  {
    path: "/pages-all-subscribers",
    name: "Subscribers Data",
    component: <AdminSubscribersData />,
  },

  // Profile Setting
  {
    path: "/pages-profile-settings",
    name: "ProfileSetting",
    component: <ProfileSetting />,
  },

  //Transcations
  {
    path: "/pages-all-transactions",
    name: "Transactions Data",
    component: <TransactionData />,
  },
];

const publicRoutes = [
  // Authentication
  { path: "/login", name: "Login", component: <Login /> },
  { path: "/logout", name: "Logout", component: <Logout /> },
  { path: "/register", name: "Register", component: <Register /> },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: <ForgotPassword />,
  },

  // Authentication Inner
  // Sign in
  { path: "/auth-signin", name: "Signin", component: <Signin /> },

  // Sign up
  { path: "/auth-signup", name: "SignUp", component: <SignUp /> },

  // Password Reset
  {
    path: "/auth-pass-reset",
    name: "PasswordReset",
    component: <PasswordReset />,
  },

  // Password Create
  {
    path: "/auth-pass-change",
    name: "PasswordCreate",
    component: <PasswordCreate />,
  },

  // Lock Screen
  { path: "/auth-lockscreen", name: "LockScreen", component: <LockScreen /> },

  // Logout
  { path: "/auth-logout", name: "LogOut", component: <LogOut /> },

  // Success Message
  { path: "/auth-success-msg", name: "SuccessMsg", component: <SuccessMsg /> },

  // Two Step Verification
  { path: "/auth-twostep", name: "Twostep", component: <Twostep /> },

  // Error
  // Error 404
  { path: "/auth-404", name: "Fourzerofour", component: <Fourzerofour /> },

  // Error 500
  { path: "/auth-500", name: "Fivezerozero", component: <Fivezerozero /> },

  // Error 503
  { path: "/auth-503", name: "Fivezerothree", component: <Fivezerothree /> },

  // Offline Page
  { path: "/auth-offline", name: "OfflinePage", component: <OfflinePage /> },

  // Pages
  // Maintenance
  {
    path: "/pages-maintenance",
    name: "Maintenance",
    component: <Maintenance />,
  },

  // Coming Soon
  { path: "/pages-coming-soon", name: "ComingSoon", component: <ComingSoon /> },

  // { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
