import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, Alert } from "react-bootstrap";
import Right from "./right";
import BreadCrumb from "Common/BreadCrumb";
import Personal from "./personal";
import { useSelector, useDispatch } from "react-redux";
import { getAdminContractorById } from "../../../../slices/thunk";

const AdminContractorProfile = () => {
  document.title = "Contractor Profile";
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({
    show: false,
    variant: "",
    message: "",
  }); // Notification state

  const userData = useSelector((state: any) => state.Admin.singleContractor);

  const dispatch: any = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  useEffect(() => {
    dispatch(getAdminContractorById(id));
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        {notification.show && (
          <Alert
            variant={notification.variant}
            onClose={() =>
              setNotification({ show: false, variant: "", message: "" })
            }
            dismissible
          >
            {notification.message}
          </Alert>
        )}
        <Container fluid>
          <BreadCrumb title="Profile Settings" pageTitle="Pages" />
          <Row>
            <Right data={userData} />
            <Personal data={userData} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminContractorProfile;
