import React from "react";
import { Card, Image, Button, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper";
import overview1 from "../../../assets/images/real-estate/overview-01.jpg";

interface OverviewProps {
  data: any;
}

const Overview: React.FC<OverviewProps> = ({ data }) => {
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <Swiper
            pagination={{ el: ".swiper-pagination", clickable: true }}
            modules={[Pagination]}
            loop={true}
            className="property-slider mb-3"
          >
            <div className="swiper-wrapper">
              {data?.rep_images
                ? JSON.parse(data.rep_images).map((item: any, index: any) => (
                    <SwiperSlide key={index}>
                      <div className="position-relative ribbon-box">
                        <div className="ribbon ribbon-danger fw-medium rounded-end mt-2">
                          For {data?.rep_sale_type ?? ""}
                        </div>
                        <Image src={item} alt="" className="img-fluid" />
                      </div>
                    </SwiperSlide>
                  ))
                : null}
            </div>
            <div className="swiper-pagination swiper-pagination-bullets"></div>
          </Swiper>

          <div className="pt-1">
            <Button
              variant="subtle-warning"
              className="custom-toggle float-end btn-icon btn-sm"
            >
              <span className="icon-on">
                <i className="bi bi-star"></i>
              </span>
              <span className="icon-off">
                <i className="bi bi-star-fill"></i>
              </span>
            </Button>
            <h6 className="card-title">{data?.rep_title ?? ""}</h6>
            <div className="text-muted hstack gap-2 flex-wrap list-unstyled mb-3">
              <div>
                <i className="bi bi-geo-alt align-baseline me-1"></i>
                {data
                  ? `${data.rep_street_address}, ${data.rep_state}, ${data.rep_zip}, ${data.rep_country}`
                  : ""}
              </div>
              <div className="vr"></div>
              <div>
                <i className="bi bi-calendar-event align-baseline me-1"></i>
                {data
                  ? new Date(data.rep_created_at).toLocaleDateString("en-US")
                  : null}
              </div>
            </div>
            <p className="text-muted mb-2">{data?.rep_description ?? ""}</p>
          </div>

          <div className="mb-3">
            <h6 className="card-title mb-3">Property Overview</h6>
            <Row className="g-3">
              <Col xl={3} sm={6}>
                <div className="p-3 border border-dashed rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0">
                      <div className="avatar-title bg-dark-subtle text-dark fs-lg rounded">
                        <i className="bi bi-tag"></i>
                      </div>
                    </div>
                    <p className="fs-md mb-0">${data?.rep_price ?? ""}</p>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="p-3 border border-dashed rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0">
                      <div className="avatar-title bg-warning-subtle text-warning fs-lg rounded">
                        <i className="bi bi-house"></i>
                      </div>
                    </div>
                    <p className="fs-md mb-0">
                      {data?.rep_bedroom ?? ""} Bedroom
                    </p>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="p-3 border border-dashed rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0">
                      <div className="avatar-title bg-danger-subtle text-danger fs-lg rounded">
                        <i className="ph ph-bathtub"></i>
                      </div>
                    </div>
                    <p className="fs-md mb-0">
                      {data?.rep_bathroom ?? ""} Bathroom
                    </p>
                  </div>
                </div>
              </Col>
              {data?.rep_is_garage && (
                <Col xl={3} sm={6}>
                  <div className="p-3 border border-dashed rounded">
                    <div className="d-flex align-items-center gap-2">
                      <div className="avatar-xs flex-shrink-0">
                        <div className="avatar-title bg-info-subtle text-info fs-lg rounded">
                          <i className="bi bi-p-circle"></i>
                        </div>
                      </div>
                      <p className="fs-md mb-0">
                        Garage Available Size - {data?.rep_garage_size ?? ""}{" "}
                        SQFT
                      </p>
                    </div>
                  </div>
                </Col>
              )}
              <Col xl={3} sm={6}>
                <div className="p-3 border border-dashed rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0">
                      <div className="avatar-title bg-success-subtle text-success fs-lg rounded">
                        <i className="bi bi-columns"></i>
                      </div>
                    </div>
                    <p className="fs-md mb-0">{data?.rep_sqft ?? ""} SQFT</p>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="p-3 border border-dashed rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0">
                      <div className="avatar-title bg-secondary-subtle text-secondary fs-lg rounded">
                        <i className="bi bi-map"></i>
                      </div>
                    </div>
                    <p className="fs-md mb-0">{data?.rep_country ?? ""}</p>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="p-3 border border-dashed rounded">
                  <div className="d-flex align-items-center gap-2">
                    <div className="avatar-xs flex-shrink-0">
                      <div className="avatar-title bg-primary-subtle text-primary fs-lg rounded">
                        <i className="bi bi-buildings"></i>
                      </div>
                    </div>
                    <p className="fs-md mb-0">{data?.rep_type ?? ""}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <h6 className="card-title mb-3">Property Features</h6>
            <ul className="list-unstyled hstack flex-wrap gap-3">
              {data?.rep_additional_features
                ? JSON.parse(JSON.parse(data.rep_additional_features)).map(
                    (item: string, index: number) => (
                      <li className="w-lg" key={index}>
                        {item}
                      </li>
                    )
                  )
                : ""}
            </ul>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title as="h6" className="mb-0">
            Property Video
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Swiper
            pagination={{ el: ".swiper-pagination", clickable: true }}
            modules={[Pagination]}
            loop={true}
            className="property-slider mb-3"
          >
            <div className="swiper-wrapper">
              {data?.rep_video
                ? JSON.parse(data.rep_video).map((item: any, index: any) => (
                    <SwiperSlide key={index}>
                      <div className="position-relative ribbon-box">
                        <div className="ribbon ribbon-danger fw-medium rounded-end mt-2">
                          For {data?.rep_sale_type ?? ""}
                        </div>
                        <video controls className="img-fluid">
                          <source src={item} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </SwiperSlide>
                  ))
                : null}
            </div>
            <div className="swiper-pagination swiper-pagination-bullets"></div>
          </Swiper>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title as="h6" className="mb-0">
            Property Location
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div
            id="leaflet-map-group-control"
            className="leaflet-map leaflet-gray "
            style={{ minHeight: "100%" }}
          >
           
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default Overview;
