import React, { useEffect, useState } from 'react';
import { Container, Row, Spinner, Alert, Col, Table, Form, Button } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { useSelector, useDispatch } from 'react-redux';
import { getFormData} from '../../../slices/thunk';
import { Link } from 'react-router-dom';

const AdminFormsData = () => {
  document.title = 'TTC Forms Data';
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ show: false, variant: '', message: '' }); // Notification state

  const formData = useSelector((state: any) => state.Admin.formData);
  
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getFormData())
  },[])


  return (
    <React.Fragment>
      
      <div className="page-content">
        {notification.show && (
        <Alert variant={notification.variant} onClose={() => setNotification({ show: false, variant: '', message: '' })} dismissible>
          {notification.message}
        </Alert>
      )}
        <Container fluid>
          <BreadCrumb title="TTC Forms Data" pageTitle="Pages" />
          <Row>
           <Col xl={12}>
    <div className="table-responsive mt-4 mt-xl-0">
        <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                  <thead>
                    
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope='col'>Package</th>
                      <th scope='col'>Page</th>
                      <th scope='col'>Subject</th>
                      <th scope='col'>Message</th>
                      <th scope="col">Date</th>
                      </tr>
                      
            </thead>
                  <tbody>
                  {formData.length && formData.map((item: any) => (
            
                <tr key={item.tlf_id}>
                        <td className="fw-medium">{ item.tlf_id}</td>
                    <td>{item.tlf_name}</td>
                        <td>{ item.tlf_email}</td>
                      <td>{item.tlf_phone}</td>
                      <td>{ item.tlf_package}</td>
                      <td>{item.tlf_page}</td>
                      <td>{item.tlf_subject}</td>
                      <td>{item.tlf_message}</td>
                      <td>{item.tlf_created_at}</td>
                      </tr>
                      ))}
              
            </tbody>
        </Table>
    </div>
</Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminFormsData;
