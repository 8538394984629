import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Breadcrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import invoiceSignatureImg from "../../../assets/images/invoice-signature.svg";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import logoLight from "../../../assets/images/logo-light.png";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";

interface Contractor {
  name: string;
  address: string;
  cityState: string;
  phone: string;
  contractFor: string;
  contractAmount: string;
  amountPaid: string;
}

const SwornContractor = () => {
  document.title = "Create Invoice List | Steex - Admin & Dashboard Template";

  const [homeOwnerName, setHomeOwnerName] = useState("");
  const [homeOwnerAddress, setHomeOwnerAddress] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);

  const handleNameClick = () => {
    setIsEditingName(!isEditingName);
  };

  const handleAddressClick = () => {
    setIsEditingAddress(!isEditingAddress);
  };

  const [contractors, setContractors] = useState<Contractor[]>([
    {
      name: "",
      address: "",
      cityState: "",
      phone: "",
      contractFor: "",
      contractAmount: "",
      amountPaid: "",
    },
  ]);

  const handleInputChange = (
    index: number,
    field: keyof Contractor,
    value: string
  ) => {
    const updatedContractors = [...contractors];
    updatedContractors[index][field] = value;
    setContractors(updatedContractors);
  };

  const handleAddRow = () => {
    setContractors([
      ...contractors,
      {
        name: "",
        address: "",
        cityState: "",
        phone: "",
        contractFor: "",
        contractAmount: "",
        amountPaid: "",
      },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    const updatedContractors = [...contractors];
    updatedContractors.splice(index, 1);
    setContractors(updatedContractors);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      postalcode: "",
      registration: "",
      email: "",
      website: "",
      contact: "",
      invoiceId: "",
      date: "",
      name: "",
      status: "",
      country: "",
      amount: "",
      billing_address: "",
      billing_phone: "",
      billing_taxno: "",
      shipping_name: "",
      shipping_address: "",
      shipping_phone: "",
      shipping_taxno: "",
      product_name: "",
    },
    validationSchema: Yup.object({
      postalcode: Yup.string().required("This field is required"),
      registration: Yup.string().required("Please Enter a registration no"),
      email: Yup.string().required("Please Enter a Email"),
      website: Yup.string().required("Please Enter a website"),
      contact: Yup.string().required("Please Enter a contact number"),
      invoiceId: Yup.string().required("This field is required"),
      name: Yup.string().required("Please Enter a Full name"),
      // country: Yup.string().required("Please Enter a Country"),
      billing_address: Yup.string().required("Please Enter a Address"),
      billing_phone: Yup.string().required("Please Enter a Phone Number"),
      billing_taxno: Yup.string().required("Please Enter a tax Number"),
      shipping_name: Yup.string().required("Please Enter a Full name"),
      shipping_address: Yup.string().required("Please Enter a Address"),
      shipping_phone: Yup.string().required("Please Enter a Phone Number"),
      shipping_taxno: Yup.string().required("Please enter a tax Number"),
      product_name: Yup.string().required("Please Enter a product Name"),
    }),
    onSubmit: (values) => {
      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Create Contracts" pageTitle="Contracts" />
          <Row className="justify-content-center">
            <Col xxl={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                className="needs-validation"
                id="invoice_form"
              >
                <Row className="justify-content-center">
                  <Col xxl={12}>
                    <Card>
                      <Card.Body>
                        <h2 style={{ textAlign: "center" }}>
                          GENERALCONTRACTOR'S STATEMENT
                        </h2>
                        <p>
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                          >
                            {isEditingName ? (
                              <Form.Control
                                style={{
                                  maxWidth: "200px",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="homeOwnerName"
                                value={homeOwnerName}
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                                onBlur={() => setIsEditingName(false)}
                                autoFocus
                              />
                            ) : (
                              <span
                                onClick={handleNameClick}
                                style={{ fontWeight: "bold" }}
                              >
                                {homeOwnerName || "Click here to edit name"}
                              </span>
                            )}
                          </span>
                          being duly sworn on oath desposes and says that he/she
                          is General Contractor for work on the building of{" "}
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                          >
                            {isEditingName ? (
                              <Form.Control
                                style={{
                                  maxWidth: "200px",
                                  display: "inline-block",
                                }}
                                type="text"
                                name="homeOwnerName"
                                value={homeOwnerName}
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                                onBlur={() => setIsEditingName(false)}
                              />
                            ) : (
                              <span
                                onClick={handleNameClick}
                                style={{ fontWeight: "bold" }}
                              >
                                {homeOwnerName || "Click here to edit name"}
                              </span>
                            )}
                          </span>
                          , the owner, situated at,{" "}
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                          >
                            {isEditingAddress ? (
                              <textarea
                                style={{
                                  maxWidth: "200px",
                                  display: "inline-block",
                                }}
                                className="form-control"
                                id="homeOwnerAddress"
                                name="homeOwnerAddress"
                                value={homeOwnerAddress}
                                onChange={(e) =>
                                  setHomeOwnerAddress(e.target.value)
                                }
                                onBlur={() => setIsEditingAddress(false)}
                                rows={3}
                              />
                            ) : (
                              <span
                                onClick={handleAddressClick}
                                style={{ fontWeight: "bold" }}
                              >
                                {homeOwnerAddress ||
                                  "Click here to edit address"}
                              </span>
                            )}
                          </span>
                          and that the following is a true statement of the
                          names of sub-contractors and material men, the amounts
                          of all contracts made and all money due or to become
                          due and unpaid to material dealers, sub-contractors or
                          workmen in the employ of the undersigned on or about
                          above described building; also respective amounts of
                          bids or proposals for material or contracts, where
                          same have not yet been let or accepted; that all bills
                          for labor, the date are fully paid, that these
                          representations are made for the purpose of obtaining
                          payments for labor and material from the Owner thereof
                          and The Tradesmen's Collective Escrow Services, LC as
                          Escrow Agent for this Project.
                        </p>

                        <Table striped bordered>
                          <thead>
                            <tr>
                              <th>
                                CONTRACTOR INFORMATION (name, address, phone
                                number REQUIRED)
                              </th>
                              <th>CONTRACT FOR</th>
                              <th>AMOUNT OF CONTRACT</th>
                              <th>AMOUNT ALREADY PAID</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contractors.map((contractor, index) => (
                              <tr key={index}>
                                <td>
                                  <div>
                                    <Form.Group>
                                      <Form.Control
                                        className="mb-2"
                                        placeholder="Name:"
                                        type="text"
                                        value={contractor.name}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "name",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div>
                                    <Form.Group>
                                      <Form.Control
                                        className="mb-2"
                                        placeholder="Address"
                                        type="text"
                                        value={contractor.address}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "address",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div>
                                    <Form.Group>
                                      <Form.Control
                                        className="mb-2"
                                        placeholder="City, State"
                                        type="text"
                                        value={contractor.cityState}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "cityState",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <div>
                                    <Form.Group>
                                      <Form.Control
                                        className="mb-2"
                                        placeholder="City, State"
                                        type="text"
                                        value={contractor.phone}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "phone",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </td>
                                <td>
                                  <Form.Control
                                    className="mb-2"
                                    as="select"
                                    value={contractor.contractFor}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractFor",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option>Demolition/Hauling</option>
                                    <option>Excavating & Grade</option>
                                    <option>Dumpsters</option>
                                    <option>Concrete</option>
                                  </Form.Control>
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.amountPaid}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "amountPaid",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Control
                                    className="mb-2"
                                    type="text"
                                    value={contractor.contractAmount}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "contractAmount",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={4}>
                                <button type="button" onClick={handleAddRow}>
                                  Add More
                                </button>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xxl={12}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xxl={6}>
                            <h4>General Contractor</h4>
                          </Col>
                          <Col xxl={6}>
                            <p>
                              Fil ni your contact information, sign and have
                              notarized.
                            </p>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col xxl={6}>
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xxl={6}>
                            <Form.Group>
                              <Form.Label>Address</Form.Label>
                              <textarea
                                className="form-control"
                                id="homeOwnerAddress"
                                name="homeOwnerAddress"
                                onChange={(e) =>
                                  setHomeOwnerAddress(e.target.value)
                                }
                                rows={1}
                              ></textarea>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>City, State, Zpi:</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xxl={8}>
                            <Form.Group>
                              <Form.Label>X</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xxl={4}>
                            <Form.Group>
                              <Form.Label>Date:</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col xxl={8}>
                            <Row className="mb-2">
                              <Col xxl={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Subscribed and sworn to before me this
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="homeOwnerName"
                                    id="homeOwnerName"
                                    onChange={(e) =>
                                      setHomeOwnerName(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xxl={3}>
                                <Form.Group>
                                  <Form.Label>Day Of</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="homeOwnerName"
                                    id="homeOwnerName"
                                    onChange={(e) =>
                                      setHomeOwnerName(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xxl={3}>
                                <Form.Group>
                                  <Form.Label>Year Of</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="homeOwnerName"
                                    id="homeOwnerName"
                                    onChange={(e) =>
                                      setHomeOwnerName(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col xxl={8}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                              <Form.Label>NOTARY PUBLIC</Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xxl={12}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xxl={6}>
                            <h4>Owner</h4>
                          </Col>
                          <Col xxl={6}>
                            <p>
                              Fil ni your contact information, sign and have
                              notarized.
                            </p>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col xxl={6}>
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xxl={6}>
                            <Form.Group>
                              <Form.Label>Address</Form.Label>
                              <textarea
                                className="form-control"
                                id="homeOwnerAddress"
                                name="homeOwnerAddress"
                                onChange={(e) =>
                                  setHomeOwnerAddress(e.target.value)
                                }
                                rows={1}
                              ></textarea>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>City, State, Zpi:</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xxl={8}>
                            <Form.Group>
                              <Form.Label>X</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xxl={4}>
                            <Form.Group>
                              <Form.Label>Date:</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col xxl={8}>
                            <Row className="mb-2">
                              <Col xxl={6}>
                                <Form.Group>
                                  <Form.Label>
                                    Subscribed and sworn to before me this
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="homeOwnerName"
                                    id="homeOwnerName"
                                    onChange={(e) =>
                                      setHomeOwnerName(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xxl={3}>
                                <Form.Group>
                                  <Form.Label>Day Of</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="homeOwnerName"
                                    id="homeOwnerName"
                                    onChange={(e) =>
                                      setHomeOwnerName(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col xxl={3}>
                                <Form.Group>
                                  <Form.Label>Year Of</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="homeOwnerName"
                                    id="homeOwnerName"
                                    onChange={(e) =>
                                      setHomeOwnerName(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col xxl={8}>
                            <Form.Group>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                              <Form.Label>NOTARY PUBLIC</Form.Label>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col xxl={12}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xxl={6}>
                            <h4>Escrow Agent</h4>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col xxl={12}>
                            <Form.Group>
                              <Form.Label>Comments:</Form.Label>
                              <textarea
                                className="form-control"
                                id="businessCorporation"
                                name="businessCorporation"
                                rows={3}
                              ></textarea>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xxl={8}>
                            <Form.Group>
                              <Form.Label>
                                Reviewed and Approved by Escrow Agent: x
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col xxl={4}>
                            <Form.Group>
                              <Form.Label>Date:</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="mb-2">
                          <Col xxl={8}>
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="homeOwnerName"
                                id="homeOwnerName"
                                onChange={(e) =>
                                  setHomeOwnerName(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Card className="overflow-hidden">
                  <Card.Body className="z-1 position-relative">
                    <div>
                      <div className="invoice-signature text-center">
                        <div className="mb-3 mt-4">
                          <input
                            id="sign-img-file-input"
                            type="file"
                            className="sign-img-file-input d-none"
                          />
                          <label
                            htmlFor="sign-img-file-input"
                            className="d-block"
                            tabIndex={0}
                          >
                            <span
                              className="overflow-hidden mx-auto border border-dashed d-flex align-items-center justify-content-center rounded"
                              style={{ height: "40px", width: "150px" }}
                            ></span>
                          </label>
                        </div>
                        <h6 className="mb-0 mt-3">Authorized Sign</h6>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className="hstack gap-2 flex-wrap justify-content-end d-print-none my-4">
                  <button type="submit" className="btn btn-success">
                    <i className="ri-printer-line align-bottom me-1"></i> Save
                  </button>
                  <Link to="#" className="btn btn-primary">
                    <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                    Download Contract
                  </Link>
                  <Link to="#" className="btn btn-danger">
                    <i className="ri-send-plane-fill align-bottom me-1"></i>{" "}
                    Send Contract
                  </Link>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SwornContractor;
