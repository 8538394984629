import React, { useEffect,useMemo, useState }  from 'react'
import decodeJwt, { JwtPayload } from '../../../helpers/jwt-token-access/jwtdecode'
import SimpleBar from 'simplebar-react';

// Import Images
import { Card, Col, Row, Form, Button, Badge, Modal } from 'react-bootstrap';
import CountUp from 'react-countup'
import { invoice } from "Common/data/invoiceListView";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import {
    getInvoiceList as onGetInvoiceList,
    addInvoiceList as onAddInvoiceList,
    updateInvoiceList as onUpdateInvoiceList,
    deleteInvoiceList as onDeleteInvoiceList
} from "slices/invoices/thunk"
import { createSelector } from "reselect";


const Feedback = () => {

    const dispatch = useDispatch<any>();

    const selectInvoicesList = createSelector(
        (state: any) => state.Invoice,
        (invoice) => ({
            invoicesList: invoice.invoiceList
        })
    );

    const { invoicesList } = useSelector(selectInvoicesList);

    useEffect(() => {
        dispatch(onGetInvoiceList());
    }, [dispatch]);


    const [decodedJwt, setDecodedJwt] = useState({})
    const [listView, setListView] = useState<any>();
    const [addInvoice, setAddInvoice] = useState<boolean>(false);
    const [editInvoice, setEditInvoice] = useState<boolean>(false);
    const [edit, setEdit] = useState<any>();
    const [invoiceslists, setInvoiceslists] = useState<any>(null);
    const [show, setShow] = useState<boolean>(false);

    const handleCloseInvoice = () => setAddInvoice(false);
    const handleShowInvoice = () => setAddInvoice(true);


    useEffect(() => {
        // Call the decodeJwt function to get the decoded values
        const decodedToken: JwtPayload | null = decodeJwt();
    
        if (decodedToken) {
          // Access the decoded values
          setDecodedJwt(decodedToken.userType)
        } else {
          console.error('Decoded token is null');
        }
      }, []);
      const handleChange = (ele: any) => {
        let search = ele.target.value;
        if (search) {
            setListView(invoicesList?.filter((item: any) => item.customer.toLowerCase().includes(search)))
        } else {
            setListView(invoicesList)
        }
    }

    const handleEdit = (item: any) => {
        setAddInvoice(true);
        setEditInvoice(true);
        setEdit({
            id: item.id,
            customer: item.customer,
            email: item.email,
            createDate: item.createDate,
            dueDate: item.dueDate,
            invoice_amount: item.invoice_amount,
            status: item.status
        })
    }

    const handleDeleteShow = (ele: any) => { setShow(true); setInvoiceslists(ele) };
    const handleDeleteClose = () => setShow(false);

    //delete modal
    const deleteModalFunction = () => {
        if (invoiceslists.id) {
            dispatch(onDeleteInvoiceList(invoiceslists.id));
        }
        setShow(false)
    }

    useEffect(() => {
        setListView(invoicesList)
    }, [invoicesList])

      //columns
      const columns = useMemo(
        () => [
            {
                Header: (
                    <Form.Check >
                        <Form.Check.Input type="checkbox" value="option" id="checkAll" />
                        <Form.Check.Label htmlFor="checkAll"></Form.Check.Label>
                    </Form.Check>
                ),
                Cell: (cell: any) => {
                    return (
                        <Form.Check className="checkbox-product-list">
                            <Form.Check.Input type="checkbox" value="2" id="checkbox-2" />
                            <Form.Check.Label htmlFor="checkbox-2"></Form.Check.Label>
                        </Form.Check>
                    )
                },
                id: "#",
                Filter: false,
                isSortable: false,
            },
            {
                Header: "id",
                accessor: "invoice_no",
                Cell: (cell: any) => {
                    return (
                        <Link to="#" >#TBS{cell.row.original.invoice_no}</Link>
                    )
                },
                Filter: false,
                isSortable: true,
            },
            {
                Header: "Customer Name",
                accessor: "customer",
                Filter: false,
                isSortable: true,
                disableFilters: true,
            },
            {
                Header: "Email",
                accessor: "email",
                Filter: false,
                isSortable: true,
                disableFilters: true,
            },
            {
                Header: "Create Date",
                accessor: "createDate",
                Filter: false,
                isSortable: true,
                disableFilters: true,
            },
            {
                Header: "Due Date",
                accessor: "dueDate",
                Filter: false,
                isSortable: true,
                disableFilters: true,
            },
            {
                Header: "Status",
                disableFilters: true,
                Filter: false,
                isSortable: true,
                accessor: (cellProps: any) => {
                    switch (cellProps.status) {
                        case "Paid":
                            return (<Badge text="success" bg="success-subtle" className="status"> Fixed</Badge>)
                        case "Unpaid":
                            return (<Badge text="danger" bg="danger-subtle" className="status"> Dispute</Badge>)
                        default:
                            return (<Badge text="warning" bg="warning-subtle" className="status"> Pending</Badge>)
                    }
                },
            },

        ], []
    )
    
  return (
    <React.Fragment>
        <Col xxl={decodedJwt === 'homeowner' ? 6 : 12} lg={decodedJwt === 'homeowner' ? 6 : 12}>
            <Card>
                <Card.Header className="d-flex align-items-center">
                    <h4 className="card-title mb-0 flex-grow-1">{decodedJwt === 'homeowner' ? 'Feedback': 'Support Tickets Raised from customers'}</h4>
                    <div className="flex-shrink-0">
                        <a href="#!" className="text-muted">View All <i className="bi bi-chevron-right align-baseline"></i></a>
                    </div>
                </Card.Header>
                <div className="card-body px-0">
                <Row id="invoiceList">
                        <Col lg={12}>
                            <Card>
                                <Card.Body className="mt-3">
                                    {
                                        listView && listView.length > 0 ?
                                            <TableContainer
                                                isPagination={true}
                                                columns={columns}
                                                data={listView || []}
                                                customPageSize={10}
                                                divClassName="table-responsive table-card"
                                                tableClass="table table-centered align-middle table-custom-effect table-nowrap mb-0"
                                                theadClass="table-light"
                                                PaginationClassName="align-items-center mt-4 pt-3"
                                                isBordered={false}
                                                SearchPlaceholder=""
                                            />
                                            :
                                            <div id="noresult">
                                                <div className="text-center py-4">
                                                    <div className="avatar-md mx-auto mb-4">
                                                        <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-5xl">
                                                            <i className="bi bi-search"></i>
                                                        </div>
                                                    </div>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                </div>
                                            </div>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </Card>
        </Col>
    </React.Fragment>
  );
};

export default Feedback;
