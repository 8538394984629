// authHeader.ts
import { jwtDecode } from "jwt-decode";

export const getAuthHeader = () => {
  const token = localStorage.getItem('authUser');
  if (!token) {
    console.error('Error: Authentication token not found');
    return null;
  }

  try {
    const decodedToken: any = jwtDecode(token.replace(/^"|"$/g, ''));
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      // Token is expired
      localStorage.removeItem('authUser');
      localStorage.removeItem('token');
      window.location.href = '/logout'; // Adjust the logout URL as necessary
      return null;
    }

    const formattedToken = 'Bearer ' + token.replace(/^"|"$/g, '');
    return { Authorization: formattedToken };
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};



// export default function authHeader() {
//   const obj = JSON.parse(localStorage.getItem("authUser") || "")

//   if (obj && obj.accessToken) {
//     return { Authorization: obj.accessToken }
//   } else {
//     return {}
//   }
// }

