import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  Spinner,
  Button,
  Table,
  Modal,
  Alert,
} from "react-bootstrap";

interface IntroContentProps {
  content: any;
}

interface JwtPayload {
  userType: string;
}

const IntroContent: React.FC<IntroContentProps> = ({ content }) => {
  return (
    <React.Fragment>
      <div className="row mb-3 ">
        <div className="container d-flex justify-content-center">
          <div className="col-10">
                      <Card style={ {borderRadius:'12px'}}>
              <Card.Body>
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IntroContent;
