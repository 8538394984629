import React from "react";
import { Card } from "react-bootstrap";

interface VideoSectionProps {
  title: string;
  videoTitle: string;
  videoContent: string;
  videoSrc: string;
  overlayImageSrc: string;
}

const VideoSection: React.FC<VideoSectionProps> = ({
  title,
  videoTitle,
  videoContent,
  videoSrc,
  overlayImageSrc,
}) => {
  const videoContainerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    maxWidth: "640px", // Adjust as needed
    margin: "auto",
  };

  const videoElementStyle: React.CSSProperties = {
    width: "100%",
    height: "auto",
    borderRadius: "30px",
  };

  const overlayImageStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none", // Ensures the video controls remain clickable
  };

  return (
    <div className="row">
      <div className="container d-flex justify-content-center">
        <div className="col-10">
          <h2 className="text-center mb-4">{title}</h2>
          <Card style={{ borderRadius: "12px" }}>
            <Card.Body>
              <div className="row d-flex align-items-center">
                <div className="col-md-6">
                  <div style={videoContainerStyle}>
                    <video style={videoElementStyle} controls>
                      <source src={videoSrc} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <img className="img-fluid" style={overlayImageStyle} src={overlayImageSrc} alt="Overlay" /> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <h2>{videoTitle}</h2>
                  <p>{videoContent}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
