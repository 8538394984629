import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  contractor: {},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const contractorSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    contractorSuccess(state, action) {
      state.contractor = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
  },
});

export const {
  apiError,
  contractorSuccess,
} = contractorSlice.actions

export default contractorSlice.reducer;