//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { getLeadSuccess, apiError, getLeadStatus, getAppliedLeads, getSingleLead, getRequestedLead } from './reducer';

import apiUrl from "slices/apiEndpoint";

export const getLeads = () => async (dispatch: any) => {
  try { 
    const response = await axios.get(`${apiUrl}/lead/get/${getDecodeId()}`)
   let data = response;
    if (data) {
      dispatch(getLeadSuccess(data.data));
      return data.data;
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};

export const getLeadById = (leadId: any) => async (dispatch: any) => {
  //console.log(leadId, "leadId")
  try { 
    const response = await axios.get(`${apiUrl}/lead/get-single-lead/${leadId}`)
    let data = response;
    //console.log(data, "data")
    if (data) {
      dispatch(getSingleLead(data.data));
      return data.data
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};

export const getReqLeadById = () => async (dispatch: any) => {
  try { 
    const response = await axios.get(`${apiUrl}/lead/get-lead/${getDecodeId()}`)
    let data = response;
    //console.log(data.data, "data")
    if (data) {
      dispatch(getRequestedLead(data.data));
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};



export const applyLead = (lead: any, status:any, leadSubCat:any) => async (dispatch: any) => {

  let data = {
    projectStatus: status,
    userId: getDecodeId(),
    leadId: lead,
    category:leadSubCat
  };

  try {
    const response = await axios.post(`${apiUrl}/lead/accept-lead`, data);
    if (response.data) {
      dispatch(getLeadStatus(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const declineLead = (lead: any, status:any) => async (dispatch: any) => {

  let data = {
    projectStatus: status,
    userId: getDecodeId(),
    leadId: lead
  };

  try {
    const response = await axios.post(`${apiUrl}/lead/decline-lead`, data);
    if (response.data) {
      dispatch(getLeadStatus(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const declineOpenLead = (lead: any) => async (dispatch: any) => {

  let data = {
    status: 'DECLINED',
    leadId: lead
  };

  try {
    const response = await axios.post(`${apiUrl}/lead/decline-open-lead/${getDecodeId()}`, data);
    if (response.data) {
      dispatch(getLeadStatus(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};



export const getAplliedLeads = () => async (dispatch: any) => {
  try {
    const response = await axios.get(`${apiUrl}/lead/get-lead/${getDecodeId()}/${getDecodeUserType()}`);
    if (response.data) {
      dispatch(getAppliedLeads(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};




function getDecodeId() {
   const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode(token);
      } else {
        console.error('Token is null');
    }
  return decoded.userId
}



function getDecodeUserType() {
   const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode(token);
      } else {
        console.error('Token is null');
    }
  return decoded.userType
}
 

