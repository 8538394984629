import React from 'react'
import { Card, Col } from 'react-bootstrap'

 interface SalepriceProps { 
    data:any
  }
const Saleprice: React.FC<SalepriceProps> = ({ data}) => {
 
  return (
    <React.Fragment>
        <Col lg={12} className="col-lg-12">
            <Card className="card-primary text-center">
                <Card.Body>
            <h4 className="text-reset fw-normal">${ data?.rep_price ?? 'NA'}</h4>
            <p className="text-white-75 fs-md mb-0">{ data?.rep_sale_type === 'sale' ? 'Sale Price' :'Monthly Rent'}</p>
                </Card.Body>
            </Card>
        </Col>
    </React.Fragment>
  )
}

export default Saleprice;
