import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

interface MassachusettsProps {
  onExtraData: (data: object) => void;
}

const Massachusetts: React.FC<MassachusettsProps> = ({ onExtraData }) => {
  const [extraData, setExtraData] = useState<{
    [key: string]: any;
    "multipleChoice"?: { label: string; value: string }[];
  }>({});

  const handleCheckboxChange = (label: string, value: string, checked: boolean) => {
    const currentValues = extraData["multipleChoice"] || [];
    let newData;
    if (checked) {
      // Add the item if it's checked
      newData = {
        ...extraData,
        multipleChoice: [...currentValues, { label, value }]
      };
    } else {
      // Remove the item if it's unchecked
      newData = {
        ...extraData,
        multipleChoice: currentValues.filter((item: { label: string; value: string }) => item.value !== value)
      };
    }
    setExtraData(newData);
    onExtraData(newData);
  };

  const onchangeExtraData = (category: string, label: string, value: string) => {
    const newData = {
      ...extraData,
      [category]:  value 
    };
    setExtraData(newData);
    onExtraData(newData);
  };

  return (
    <Container>
      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>Check the statements that are true about this job/project *</h5>
          <p className="mb-2">You can choose multiple options.</p>
          <Form.Check
            label="The value of the work exceeds $1,000"
            name='work-exceeds-$1000'
            type="checkbox"
            className="mb-2"
            onChange={(e) => handleCheckboxChange("The value of the work exceeds $1,000", e.target.value, e.target.checked)}
          />
          <Form.Check
            label="Work will be done on an existing owner-occupied residence with from 1 to 4 units"
            type="checkbox"
            name='owner-occupied-residence'
            className="mb-2"
            onChange={(e) => handleCheckboxChange("Work will be done on an existing owner-occupied residence with from 1 to 4 units", e.target.value, e.target.checked)}
          />
          <Form.Check
            label="The work under this agreement is primarily for one of the following:"
            name="primarily-for-one"
            type="checkbox"
            className="mb-2"
            onChange={(e) => handleCheckboxChange("The work under this agreement is primarily for one of the following", e.target.value, e.target.checked)}
          />
          <ol className="fs-5">
            <li>Installation of HVAC systems and energy-conservation devices</li>
            <li>Landscaping</li>
            <li>Interior painting and wall covering</li>
            <li>Finished floor covering (carpet, vinyl or tile)</li>
            <li>Fencing</li>
            <li>Freestanding masonry walls</li>
            <li>Above-ground swimming pools</li>
            <li>Shutters or awnings</li>
            <li>Ground level patios</li>
            <li>Asphalt and driveway installation or maintenance</li>
            <li>Conservation services conducted by or on behalf of a public utility under a program approved by the Department of Telecommunications and Energy</li>
            <li>The practice of a trade such as plumbing or wiring for which a license is required</li>
          </ol>
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>Will Contractor have the right to initiate arbitration? *</h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes, Contractor has the right to initiate arbitration."
            name="right-to-initiate"
            value="yes"
            className="mb-2"
            onChange={(e) => onchangeExtraData("right-to-initiate", "Yes, Contractor has the right to initiate arbitration.", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No, Contractor will not have the right to arbitrate."
            name="right-to-initiate"
            value="no"
            onChange={(e) => onchangeExtraData("right-to-initiate", "No, Contractor will not have the right to arbitrate.", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Does this contract include work on the principal residence of the
            owner? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes"
            name="principal-residence"
            value='yes'
            className="mb-2"
            onChange={(e) => onchangeExtraData("principal-residence", "Yes", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No"
            name="principal-residence"
            value='no'
            onChange={(e) => onchangeExtraData("principal-residence", "No", e.target.value)}
          />
        </Col>
      </Row>

      <Row className="bg-white p-5 mb-5 rounded">
        <Col>
          <h5>
            Does the Owner give up the 3-day right to rescind because there is a
            true emergency? *
          </h5>
          <p className="mb-2">Choose one option.</p>
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="Yes. This contract will include a form to be completed in handwriting by Owner."
            name="3-day-right-to-rescind"
            value="yes"
            className="mb-2"
            onChange={(e) => onchangeExtraData("3-day-right-to-rescind", "Yes. This contract will include a form to be completed in handwriting by Owner.", e.target.value)}
          />
          <Form.Check
            type="radio"
            aria-label="radio 1"
            label="No. Contractor will delay the start of work until three business days have passed."
            name="3-day-right-to-rescind"
            value="no"
            onChange={(e) => onchangeExtraData("3-day-right-to-rescind", "No. Contractor will delay the start of work until three business days have passed.", e.target.value)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Massachusetts;